/**
 * Created by Administrator on 2017/7/12.
 */

'use strict';
angular.module('controllers').controller('upgradeConfigAdTimeCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state', '$stateParams','uploadFileService',
  function ($scope, Upload, $http, $window, $timeout, $state, $stateParams,uploadFileService) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");
    $scope.upgrade={
      projectCode:$stateParams.belongProject,
      belongConfigId:$stateParams.belongConfigId,
      remark:'',
      validTime:'0',
      forbidden:'0',
      repeatAttr:{
        monday:true,
        tuesday:true,
        wednesday:true,
        thursday:true,
        friday:true,
        saturday:true,
        sunday:true,
      },
      timeInDays:[{
        start:0,
        end:24
      }],
    }
    
    //粗略检测是否非法进入此页面
    $scope.checkState = function () {
      $scope.belongConfigId = $stateParams.belongConfigId;
      if (!$stateParams.belongConfigId||!$stateParams.belongProject) {
        $scope.$emit('notification', {type: 'danger', message: '非法请求页面'});
        $scope.errorRequest = true;
        return;
      }
      
      $http({
        method: 'Post',
        url: '/web/configAd/findOne',
        data:{data:{_id:$stateParams.belongConfigId}},
      }).then(function (response) {
          if (response.data.code === 0) {
            $scope.upgrade.type = response.data.data.type;
          }
      }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })
      if($stateParams.upgradeId){
        $http({
          method: 'Post',
          url: '/web/configAdTime/findOne',
          data:{data:{_id:$stateParams.upgradeId}},
        }).then(function (response) {
            if (response.data.code === 0) {
              $scope.upgrade = response.data.data;
              if($scope.upgrade.validTime==1){
                $scope.upgrade.validTimeAttr.start = new Date($scope.upgrade.validTimeAttr.start);  
                $scope.upgrade.validTimeAttr.end = new Date($scope.upgrade.validTimeAttr.end);  
              }
            }
        }, function (err) {
            $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
        })
      }
      $scope.myTimes=[];
      for (let index = 0; index < 25; index++) {
        let element = {
          name:"",
          value:index,
        };
        if(index>=10){
          element.name=index+":00"
        }else{
          element.name="0"+index+":00"
        }
        $scope.myTimes.push(element);
      }
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if(element.nameEn==$stateParams.belongProject){
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
          $http({
            method: 'GET',
            url: '/config/getWebConfig',
          }).then(function (response) {
              if (response.data.code === 0) {
                  $scope.domain = response.data.domain+"/"+$scope.curProjectNameEn;
                  $scope.uploadToken = response.data.uploadToken;
                  $scope.fileSaveWay = response.data.fileSaveWay;
              }
          }, function (err) {
              $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
          })
        }
      });
    };
    
    $scope.uploadFile = function () {
      let nickName;
      if (/[']/.test($scope.upgrade.remark)
      ) {
        $scope.$emit('notification', {type: 'danger', message: '内容包含英文的单引号，不允许提交!'});
        return;
      }
      if(!$scope.upgrade.file){
        if(!$scope.upgrade.fileName){
          $scope.$emit('notification', {type: 'danger', message: '请选择上传的文件'});
          return;
        }
      }else{
        if(isChinese($scope.upgrade.file.name)){
          $scope.$emit('notification', {type: 'danger', message: '文件名不能包含中文'});
          return;
        }
        if($scope.upgrade.type==2||$scope.upgrade.type==6){
          var check = new RegExp(".zip$", 'i').test($scope.upgrade.file.name);
          if (!check) {
            $scope.$emit('notification', {type: 'danger', message: '上传文件不是zip格式'});
            return
          }
        }
        if($scope.upgrade.type==1){
          var check = new RegExp(".bin$", 'i').test($scope.upgrade.file.name);
          if (!check) {
            $scope.$emit('notification', {type: 'danger', message: '上传文件不是bin格式'});
            return
          }
        }
        if($scope.upgrade.type==4||$scope.upgrade.type==5){
          var check = new RegExp(".mp4$", 'i').test($scope.upgrade.file.name.toLowerCase());
          var check2 = new RegExp(".zip$", 'i').test($scope.upgrade.file.name.toLowerCase());
          if (!check&&!check2) {
            $scope.$emit('notification', {type: 'danger', message: '上传文件不是mp4或zip格式'});
            return
          }
        }
        if($scope.upgrade.type==3){
          var check = new RegExp(".jpg$", 'i').test($scope.upgrade.file.name.toLowerCase());
          var check2 = new RegExp(".bmp$", 'i').test($scope.upgrade.file.name.toLowerCase());
          if (!check&&!check2) {
            $scope.$emit('notification', {type: 'danger', message: '上传文件不是jpg或bmp格式'});
            return
          }
        }
        nickName = "upgradefile"+$scope.getTime()+"_"+$scope.upgrade.file.name;
        $scope.upgrade.fileName = nickName;
        $scope.upgrade.url = $scope.domain+"/"+nickName;
        $scope.upgrade.file.nickName = nickName;
      }

      var data = {
        _id:$scope.upgrade._id,
        projectCode: $scope.upgrade.projectCode,
        belongConfigId: $scope.upgrade.belongConfigId,
        type: $scope.upgrade.type,
        md5: $scope.upgrade.md5,
        fileName: $scope.upgrade.fileName,
        url: $scope.upgrade.url,
        remark:$scope.upgrade.remark,
        validTime:$scope.upgrade.validTime,
        validTimeAttr:$scope.upgrade.validTimeAttr,
        repeatAttr:$scope.upgrade.repeatAttr,
        timeInDays:$scope.upgrade.timeInDays,
        forbidden:$scope.upgrade.forbidden
      };
      uploadFileService.cancelUpload($scope);
      uploadFileService.uploadFile($scope,$scope.upgrade.projectCode,[$scope.upgrade.file],data,finishUploadCallBack);
    };
    
    function finishUploadCallBack(data,uploadResData){
      console.log(data);
      console.log(uploadResData);
      if(uploadResData&&uploadResData.length==1&&uploadResData[0].md5){
        data.md5 = uploadResData[0].md5;
      }
      $http({
        method: 'POST',
        url: '/web/configAdTime/newUpgradeFile',
        data: data
      }).then(function (response) {
        if (response.data.code === 0) {
            $scope.$emit('notification', {type: 'success', message: '成功，2秒后返回列表'});
            $timeout(function () {
              $scope.backUrl()
            }, 2000)
        } else {
          $('#progress')[0].style.width = '0%';
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })
    }

    $scope.cancelUpload = function () {
      uploadFileService.cancelUpload($scope);
    }

    $scope.backUrl = function(){
      $state.go('main.configAdTime',{configId:$stateParams.belongConfigId,belongProject:$stateParams.belongProject})
    }
    var isChinese = function(temp){
      if(/.*[\u4e00-\u9fa5]+.*$/.test(temp)) {
            return true;
          } 
          return false;
    }
    $scope.getTime = function(){
      return Date.now();
    }
    $scope.addTimeInDay = function(){
      $scope.upgrade.timeInDays.push({
        start:0,
        end:24
      });
    }
    $scope.delTimeInDay = function(index){
      $scope.upgrade.timeInDays.splice(index,1);
    }
    
  }
]);