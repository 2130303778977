angular.module('services').factory('uploadFileService',['$http','$q','Upload',function($http,$q,Upload){
  var realUploadFile = function(scope,filesSavePath,files,myData,finishUploadCallBack){
    console.log("realUploadFile");
    
    if(!files||files.length==0){
      finishUploadCallBack(myData);
      return;
    }
    //checkFiles
    let isEmpty = true;
    for (let index = 0; index < files.length; index++) {
      if(files[index]){
        isEmpty = false;
        break;
      }
    }
    if(isEmpty){
      finishUploadCallBack(myData);
      return;
    }
    if(scope.fileSaveWay=="qiniu"){
      //qiniuUploadFile(scope,files,nickName,myData,finishUploadCallBack);
    }else if(scope.fileSaveWay=="local"){
      let url = '/web/upgrade/uploadFileToServer';
      uploadFile(scope,filesSavePath,files,myData,url,finishUploadCallBack);
    }else if(scope.fileSaveWay=="resource"){
      let reg = new RegExp("^((https?:\/\/)([0-9a-z.]+)(:[0-9]+)?)", "g");
      let serverUrl = reg.exec(scope.domain)[1];
      let url = serverUrl+'/web/upgrade/uploadFileToServer';
      uploadFile(scope,filesSavePath,files,myData,url,finishUploadCallBack);
    }
  }

  var uploadFile = function(scope,filesSavePath,files,myData,url,callback){
    let filesDesc = [];
    for (let index = 0; index < files.length; index++) {
      const element_files = files[index];
      filesDesc.push({
        oldName:element_files.name,
        newName:element_files.nickName
      })
    }
    scope.upload = Upload.upload({
      method: 'POST',
      url: url,
      data: {filesDesc:filesDesc,filesSavePath:filesSavePath},
      file: files
    }).progress(function (evt) {
      var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
      if($('#progress')&&$('#progress')[0]){
        $('#progress')[0].style.width = progressPercentage + '%';
      }
    }).success(function (data, status, headers, config) {
      if(data.code==0){
        if(callback){
          callback(myData,data.data);
        }
      }else{
        if(data.msg){
          console.log(data.msg);
          scope.$emit('notification', {type: 'danger', message: data.msg});
        }else{
          scope.$emit('notification', {type: 'danger', message: "上传失败"});
        }
        if($('#progress')&&$('#progress')[0]){
          $('#progress')[0].style.width = 0 + '%';
        }
      }
    }).error(function (data, status, headers, config) {
      scope.$emit('notification', {type: 'danger', message:"上传中断"});
    })
  }

  var qiniuUploadFile = function(scope,file,fileName,myData,callback){
    console.log("qiniuUploadFile");
    if(file!=undefined){
      var config = {
        useCdnDomain: true,
        disableStatisticsReport: false,
        retryCount: 3,
        region: qiniu.region.z2
      };
      var putExtra = {
        fname: "",
        params: {},
        mimeType: []
      };
      putExtra.params["x:name"] = fileName;
      var observable = qiniu.upload(file, fileName, scope.uploadToken, putExtra, config);
      var observer = {
        next(res){
          if($('#progress')&&$('#progress')[0]){
            $('#progress')[0].style.width = res.total.percent + '%';
          }
          console.log("res");
          console.log(res);
        },
        error(err){
          console.log("error");
          console.log(err);
        }, 
        complete(res){
          console.log("complete");
          console.log(res);
          if(callback){
            callback(myData);
          }
        }
      }
      scope.subscription = observable.subscribe(observer);
    }
  }
  var realCancelUpload = function(scope){
    if(scope.fileSaveWay=="qiniu"){
      if(scope.subscription&&scope.subscription!=null){
        scope.subscription.unsubscribe()
        if($('#progress')&&$('#progress')[0]){
          $('#progress')[0].style.width = '0%';
        }
      }
    }else if(scope.fileSaveWay=="local"||scope.fileSaveWay=="resource"){
      if(scope.upload){
        scope.upload.abort();
        //$window.location.reload();
        if($('#progress')&&$('#progress')[0]){
          $('#progress')[0].style.width = '0%';
        }
      }
    }
  }
  return{
    cancelUpload:function(scope){
      realCancelUpload(scope);
    },
    uploadFile:function(scope,filesSavePath,files,data,finishUploadCallBack){
      realUploadFile(scope,filesSavePath,files,data,finishUploadCallBack);
    },
    uploadFileLocal:function(scope,filesSavePath,files,data,finishUploadCallBack){
      let url = '/web/upgrade/uploadFileToServer';
      uploadFile(scope,filesSavePath,files,data,url,finishUploadCallBack);
    },
  }
}]);