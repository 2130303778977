/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';

angular.module('controllers').controller('configAdCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state','$location','$stateParams',
  function ($scope, Upload, $http, $window, $timeout, $state,$location,$stateParams) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");

    $scope.upgrade = {showName: '', id: '', version: '', newVersion: ''};
    var loadTable = function (data, reload) {
      if (reload) {
        $('#table2').bootstrapTable('load', data);
      } else {
        $('#table2').bootstrapTable({
            pagination: false,
            pageSize: 10, //每页显示10条
            cache: false,
            search: false,
            showColumns: false,
            showExport: false,//显示导出按钮
            exportDataType: false,//导出类型
            columns: [{
              title: '广告名称',
              align: 'center',
              field: 'type',
              width:'180px',
              formatter: function (value, row, index) {
                let msg = '-';
                switch (Number(value)) {
                  case 3:
                    msg = "广告1-图片JPG";
                    break;
                  case 4:
                    msg = "广告2-视频MP4";
                    break;
                  case 5:
                    msg = "广告3-视频MP4";
                    break;
                  default:
                    break;
                }
                return msg;
              }
            }, {
              title: '广告内容',
              align: 'center',
              field: 'url',
              formatter: function (value, row, index) {
                return value==''?"无":value;
              }
            }, {
              title: '备注',
              align: 'center',
              field: 'remark',
              width:'80px',
            }, {
              title: '是否禁用',
              align: 'center',
              field: 'forbidden',
              formatter: function (value, row, index) {
                return value=='1'?'<span style="color:red">禁用</span>':'<span style="color:green">启用</span>';
              }
            }, {
              title: '更新日期',
              align: 'center',
              field: 'updateTime',
              width:'140px',
              formatter: function (value, row, index) {
                return moment(value, 'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm')
              }
            },{
              title: '操作',
              align: 'center',
              width:'120px',
              formatter: function (value, row, index) {
                row = angular.toJson(row);
                return '<button style="margin-top:5px" class=\'btn btn-success btn-xs\' onclick=\'angular.element(this).scope().toConfigTime(' + row + ')\'>时间设置</button>'
                +'<br><button style="margin-top:5px" class=\'btn btn-warning btn-xs\' onclick=\'angular.element(this).scope().showForbiddenModal(' + row + ')\'>禁用/启用</button>'
                // return '<button  class=\'btn btn-success btn-xs\' onclick=\'angular.element(this).scope().toUploadPage(' + row + ')\'>更新配置</button>'
                // +'<br><button style="margin-top:5px" class=\'btn btn-warning btn-xs\' onclick=\'angular.element(this).scope().toConfigTime(' + row + ')\'>时间设置</button>'
                // +'<br><button style="margin-top:5px" class=\'btn btn-warning btn-xs\' onclick=\'angular.element(this).scope().showForbiddenModal(' + row + ')\'>禁用/启用</button>'
              }
            }],
            data: data
          }
        );
      }
    };

    var loadTableNotice = function (data, reload) {
      if (reload) {
        $('#table4').bootstrapTable('load', data);
      } else {
        $('#table4').bootstrapTable({
            pagination: false,
            pageSize: 10, //每页显示10条
            cache: false,
            search: false,
            showColumns: false,
            showExport: false,//显示导出按钮
            exportDataType: false,//导出类型
            columns: [{
              title: '广告名称',
              align: 'center',
              field: 'type',
              width:'180px',
              formatter: function (value, row, index) {
                let msg = '-';
                switch (Number(value)) {
                  case 1:
                    msg = "通知-图片JPG";
                    break;
                  default:
                    break;
                }
                return msg;
              }
            }, {
              title: '广告内容',
              align: 'center',
              field: 'url',
              formatter: function (value, row, index) {
                return value==''?"无":value;
              }
            }, {
              title: '备注',
              align: 'center',
              field: 'remark',
              width:'80px',
            }, {
              title: '是否禁用',
              align: 'center',
              field: 'forbidden',
              formatter: function (value, row, index) {
                return value=='1'?'<span style="color:red">禁用</span>':'<span style="color:green">启用</span>';
              }
            }, {
              title: '更新日期',
              align: 'center',
              field: 'updateTime',
              width:'140px',
              formatter: function (value, row, index) {
                return moment(value, 'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm')
              }
            },{
              title: '操作',
              align: 'center',
              width:'120px',
              formatter: function (value, row, index) {
                row = angular.toJson(row);
                return '<button style="margin-top:5px" class=\'btn btn-success btn-xs\' onclick=\'angular.element(this).scope().toUpgradeConfigAdNotice(' + row + ')\'>查看/更新</button>'
                //+'<br><button style="margin-top:5px" class=\'btn btn-warning btn-xs\' onclick=\'angular.element(this).scope().showForbiddenModal(' + row + ')\'>禁用/启用</button>'
              }
            }],
            data: data
          }
        );
      }
    };

    var loadTablePageAd = function (data, reload) {
      if (reload) {
        $('#table3').bootstrapTable('load', data);
      } else {
        $('#table3').bootstrapTable({
            pagination: false,
            pageSize: 10, //每页显示10条
            cache: false,
            search: false,
            showColumns: false,
            showExport: false,//显示导出按钮
            columns: [{
              title: '广告名称',
              align: 'center',
              field: 'name',
              width:'180px',
              sortable: true,
              formatter: function (value, row, index) {
                return value==''?'无':value;
              }
            },{
              title: '广告内容',
              align: 'center',
              formatter: function (value, row, index) {
                let result='';
                if(row.cardType=="card"&&row.isShowBgPic&&row.bgPic){
                  result = row.bgPic.picUrl;
                }else if(row.cardType=="card"&&row.isShowTitle&&row.title){
                  result = row.title.name;
                }else if(row.cardType=="videoView"&&row.videoAttr&&row.videoAttr.files&&row.videoAttr.files.length>0){
                  result = row.videoAttr.files[0].url;
                }else if(row.cardType=="bannerView"&&row.bannerAttr&&row.bannerAttr.files&&row.bannerAttr.files.length>0){
                  for (let index = 0; index < row.bannerAttr.files.length; index++) {
                    let element = row.bannerAttr.files[index];
                    result += element.fileName+'<br>'
                  }
                }
                return result==''?"无":result;
              }
            }, {
              title: '备注',
              align: 'center',
              field: 'remark',
              width:'80px',
            }, {
              title: '更新日期',
              align: 'center',
              sortable: true,
              field: 'updatedAt',
              width:'140px',
              formatter: function (value, row, index) {
                return moment(value).format('YYYY-MM-DD HH:mm')
              }
            },{
              title: '操作',
              align: 'center',
              width:'120px',
              formatter: function (value, row, index) {
                row = angular.toJson(row);
                return '<button style="margin-top:5px" class=\'btn btn-success btn-xs\' onclick=\'angular.element(this).scope().toConfigTimeCard(' + row + ')\'>时间设置</button>'
              }
            }],
            data: data
          }
        );
      }
    };

    var loadTableShadow = function (data, reload) {
      if (reload) {
        $('#table5').bootstrapTable('load', data);
      } else {
        $('#table5').bootstrapTable({
            pagination: false,
            pageSize: 10, //每页显示10条
            cache: false,
            search: false,
            showColumns: false,
            showExport: false,//显示导出按钮
            exportDataType: false,//导出类型
            columns: [{
              title: '广告名称',
              align: 'center',
              field: 'type',
              width:'180px',
              formatter: function (value, row, index) {
                let msg = '-';
                switch (Number(value)) {
                  case 0:
                    msg = "鉴权遮罩广告-视频MP4";
                    break;
                  case 1:
                    msg = "鉴权遮罩广告-图片JPG";
                    break;
                  default:
                    break;
                }
                return msg;
              }
            }, {
              title: '广告内容',
              align: 'center',
              field: 'url',
              formatter: function (value, row, index) {
                return value==''?"无":value;
              }
            }, {
              title: '备注',
              align: 'center',
              field: 'remark',
              width:'80px',
            }, {
              title: '是否禁用',
              align: 'center',
              field: 'forbidden',
              formatter: function (value, row, index) {
                return value=='1'?'<span style="color:red">禁用</span>':'<span style="color:green">启用</span>';
              }
            }, {
              title: '更新日期',
              align: 'center',
              field: 'updateTime',
              width:'140px',
              formatter: function (value, row, index) {
                return moment(value, 'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm')
              }
            },{
              title: '操作',
              align: 'center',
              width:'120px',
              formatter: function (value, row, index) {
                row = angular.toJson(row);
                return '<button style="margin-top:5px" class=\'btn btn-success btn-xs\' onclick=\'angular.element(this).scope().toUpgradeConfigAdShadow(' + row + ')\'>查看/更新</button>'
                //+'<br><button style="margin-top:5px" class=\'btn btn-warning btn-xs\' onclick=\'angular.element(this).scope().showForbiddenModal(' + row + ')\'>禁用/启用</button>'
              }
            }],
            data: data
          }
        );
      }
    };

    $scope.upgradeInit = function (reload) {
      if (!$stateParams.belongProject) {
        $scope.$emit('notification', {type: 'danger', message: '非法请求页面'});
        $scope.errorRequest = true;
        return;
      }
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if(element.nameEn==$stateParams.belongProject){
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
        }
      });
      $http({
        method: 'GET',
        url: '/web/configAd/getAdConfig?projectCode='+$stateParams.belongProject,
      }).then(function (response) {
        if (response.data.code == 0) {
          loadTable(response.data.data, reload)
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
      $http({
        method: 'GET',
        url: '/layoutPage/getAllLayoutPageCard?cardTag=ad&&belongProject='+$stateParams.belongProject,
      }).then(function (response) {
        if (response.data.code == 0) {
          loadTablePageAd(response.data.data, reload)
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
      $http({
        method: 'GET',
        url: '/web/configAd/getAdNotice?projectCode='+$stateParams.belongProject,
      }).then(function (response) {
        if (response.data.code == 0) {
          loadTableNotice(response.data.data, reload)
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
      $http({
        method: 'GET',
        url: '/web/configAd/getAdShadow?projectCode='+$stateParams.belongProject,
      }).then(function (response) {
        if (response.data.code == 0) {
          loadTableShadow(response.data.data, reload)
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

    $scope.toUploadPage = function (data) {
      $state.go('main.upgradeConfigAd', {upgradeId: data._id, type: data.type,belongProject:$stateParams.belongProject})
    };
    $scope.toConfigTime = function (data) {
      $state.go('main.configAdTime', {configId: data._id,belongProject:$stateParams.belongProject})
    }
    $scope.toConfigTimeCard = function(data) {
      $state.go('main.configAdTimeCard', {configId: data._id,belongProject:$stateParams.belongProject})
    }
    $scope.toUpgradeConfigAdNotice = function(data) {
      $state.go('main.upgradeConfigAdNotice', {upgradeId: data._id,belongProject:$stateParams.belongProject})
    }
    $scope.toUpgradeConfigAdShadow = function(data) {
      $state.go('main.upgradeConfigAdShadow', {upgradeId: data._id,belongProject:$stateParams.belongProject})
    }
    $scope.showForbiddenModal = function (data) {
      $scope.upgrade.id = data._id;
      switch (Number(data.type)) {
        case 3:
          $scope.upgrade.showName = "广告1-图片JPG";
          break;
        case 4:
          $scope.upgrade.showName = "广告2-视频MP4";
          break;
        case 5:
          $scope.upgrade.showName = "广告3-视频MP4";
          break;
        default:
          $scope.upgrade.showName = '未知类型';
          break;
      }
      $scope.upgrade.newForbidden = '0';
      $scope.upgrade.forbidden = data.forbidden=='1'?'1':'0';
      $timeout(function () {
        $('#forbiddenModal').modal('show')
      }, 10);
    };

    $scope.doUpdateForbidden = function () {
      $http({
        method: 'PUT',
        url: '/web/configAd/updateUpgrade',
        data: {
          id: $scope.upgrade.id,
          forbidden:$scope.upgrade.newForbidden,
          projectCode:$stateParams.belongProject,
        }
      }).then(function (response) {
        $timeout(function () {
          $('#forbiddenModal').modal('hide')
        }, 10);

        if (response.data.code == 0) {
          $scope.upgradeInit(true);
          $scope.$emit('notification', {type: 'success', message: '修改成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

  }
])
;