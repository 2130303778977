/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('approvalProcessCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state','$stateParams',
  function ($scope, Upload, $http, $window, $timeout, $state,$stateParams) {
    
    $scope.initData = function (reload) {
      $http({
        method: 'GET',
        url: '/web/user/getUser',
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.user = response.data.user;
          showProjectName();
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
     
    };

    $scope.toUpgradeApprovalProcess = function (reload) {
      $state.go('main.upgradeApprovalProcess', {belongProject:$stateParams.belongProject});
      
    }

    var loadTable = function (reload) {
      if(reload){
        $('#table2').bootstrapTable('refresh');
        return;
      }
      $('#table2').bootstrapTable({
          method : 'get',
          url : "/web/approvalProcess/getAll?userPromoter="+$scope.user.name,//请求路径
          striped : true, //是否显示行间隔色
          pagination : true,//是否分页
          sidePagination : 'server',//server:服务器端分页|client：前端分页
          pageSize : 10,//单页记录数
          pageList : [ 5, 10, 20, 30 ],//可选择单页记录数
          showRefresh : true,//刷新按钮
          cache: false,
          search: true,
          showColumns: 'true',
          showExport: true,//显示导出按钮
          exportDataType: 'all',//导出类型
          queryParams : function(params) {//上传服务器的参数
            var temp = {//如果是在服务器端实现分页，limit、offset这两个参数是必须的
              limit : params.limit, // 每页显示数量
              //offset : params.offset, // SQL语句起始索引
              page : (params.offset / params.limit) + 1, //当前页码 
              searchName : $('.search input').val(),
              order : params.order, 
              sort : params.sort,
            };
            return temp;
          },
          columns: [{
            title: '审核Id',
            align: 'center',
            field: '_id',
            
          },{
            title: '项目名',
            align: 'center',
            field: 'projectCode',
            formatter:function(value,row,index){
              for (let index = 0; index < $scope.belongs.length; index++) {
                const element = $scope.belongs[index];
                if(element.nameEn==value){
                    return element.nameCn
                }
              }
              return "";
            }
          },{
            title: '项目Code',
            align: 'center',
            sortable: true,
            field: 'projectCode',
            
          },{
            title: '提交人',
            align: 'center',
            sortable: true,
            field: 'userPromoter',
          }, {
            title: '审核人列表',
            align: 'center',
            field: 'reviewer',
            formatter: function (reviewer, row, index) {
              var result = "";
              for (let index = 0; index < reviewer.length; index++) {
                const element = reviewer[index];
                result = result+(index==0?"":",")+ element.userName;
              }
              
              return result==""?"无":result+",超级管理员"
            }
          }, {
            title: '审核内容',
            align: 'left',
            field: 'sum',
          }, {
            title: '审核反馈',
            align: 'left',
            field: 'reply',
          }, {
            title: '审核状态',
            align: 'center',
            sortable: true,
            field: 'state',
            formatter: function (value, row, index) {
              var result;
              if(value=="0"){
                result = '<span style="color:orange">审核中</span>'
              }else if(value=="1"){
                result = '<span style="color:green">通过</span>'
              }else if(value=="-1"){
                result = '<span style="color:red">未通过</span>'
              }else if(value=="-2"){
                result = "已撤销"
              }
              return result;
              ;
            }
          }, {
            title: '提交时间',
            align: 'center',
            sortable: true,
            field: 'startTime',
            formatter: function (value, row, index) {
              return moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')
            }
          }, {
            title: '结束时间',
            align: 'center',
            sortable: true,
            field: 'endTime',
            formatter: function (value, row, index) {
              if(!value)return "进行中"
              return moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')
            }
          }, {
            title: '操作',
            align: 'center',
            width:'150px',
            formatter: function (value, row, index) {
              console.log("row.userPromoter"+row.state)
              console.log("$scope.user.name"+$scope.user.name)
              var rowStr = angular.toJson(row);
              // return '<button style="" class=\'btn btn-success btn-xs\' onclick=\'angular.element(this).scope().toApprovalProcessDetail(' + rowStr + ')\'>查看</button>'
              return '<button style="visibility: '+((row.userPromoter==$scope.user.name&&row.state=="0")?'visible':'hidden')+';" class=\'btn btn-warning btn-xs\' onclick=\'angular.element(this).scope().showRevokeModal(' + rowStr + ')\'>撤销</button>'
            }
          }]
        });
    }

    var showProjectName = function() {
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            loadTable();
          }
        }
      });
    }

    $scope.toApprovalProcessDetail = function(data){
      $state.go('main.approvalProcessDetail', {dataId: data._id,belongProject:$stateParams.belongProject})
    }
   

    $scope.showRevokeModal = function (data) {
      $scope.toRevokeProjectData =data;
      $timeout(function () {
        $('#closePageWin').modal('show')
      }, 10);
    };

    $scope.toRevoke = function(){
      console.log($scope.toRevokeProjectData._id)
      $http({
        method: 'POST',
        url: '/web/approvalProcess/revoke',
        data:{_id : $scope.toRevokeProjectData._id}
      }).then(function (response) {
        $timeout(function () {
          $('#closePageWin').modal('hide')
        }, 10);
        if (response.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '撤销成功'});
          loadTable(true);
          $scope.init(true);
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    }


  }
])
;