/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('layoutPageCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state','$stateParams',
  function ($scope, Upload, $http, $window, $timeout, $state,$stateParams) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");
    
    var loadTable = function (data, reload) {
        if (reload) {
          $('#table2').bootstrapTable('load', data);
        } else {
          $('#table2').bootstrapTable({
              pagination: true,
              pageSize: 10, //每页显示10条
              cache: false,
              search: true,
              showColumns: 'true',
              showExport: true,//显示导出按钮
              exportDataType: 'all',//导出类型
              columns: [{
                title: '子页面标题',
                align: 'center',
                field: 'name',
              }, {
                title: '更新日期',
                align: 'center',
                field: 'updateTime',
                formatter: function (value, row, index) {
                  return moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')
                }
              },{
                title: '操作',
                align: 'center',
                width:'220px',
                formatter: function (value, row, index) {
                  row = angular.toJson(row);
                  return '<button  class=\'btn btn-success btn-xs\' onclick=\'angular.element(this).scope().toUpdate(' + row + ')\'>更新</button>'
                  +'&nbsp;&nbsp;<button  class=\'btn btn-danger btn-xs\' onclick=\'angular.element(this).scope().showDeleteModal(' + row + ')\'>删除</button>'
                  +'&nbsp;&nbsp;<button  class=\'btn btn-warning btn-xs\' onclick=\'angular.element(this).scope().changePagePos(' + row + ',"up")\'>上移</button>'
                        +'&nbsp;&nbsp;<button  class=\'btn btn-warning btn-xs\' onclick=\'angular.element(this).scope().changePagePos(' + row + ',"down")\'>下移</button>';
                }
              }
            ],
              data: data
            }
          );
        }
      }
    ;
    $scope.changePagePos = function(data,posOption){
      $http({
        method: 'POST',
        url: '/layoutPage/changePagePos',
        data:{belongPage:$stateParams.itemId,pageId:data._id,posOption:posOption}
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '操作成功'});
          $scope.init(true);
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    }
    $scope.doUpdate = function(){
      $http({
        method: 'POST',
        url: '/layout/uploadLayout',
        data: $scope.upgrade
      }).then(function (result) {
        $timeout(function () {
          $('#changeModal').modal('hide')
        }, 10);

        if (result.data.code == 0) {
          $scope.init(true);
          $scope.$emit('notification', {type: 'success', message: '修改成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: result.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
      });
    }
    $scope.toUpdate = function(data){
   
      window.location.href=$state.href('main.upgradeLayoutPage',{itemId:data._id});
    }

    $scope.createPage = function(){
      var pageParam = {
        dimension_h:3,
        dimension_w:3,
        margin_h:3,
        margin_w:3,
        max_cols:60,
      }
      if($scope.newLayout.belongProject=="haiNanProject"){
        pageParam = {
          dimension_h:71,
          dimension_w:71,
          margin_h:3,
          margin_w:3,
          max_cols:7,
        }
      }
      $http({
        method: 'POST',
        url: '/layoutPage/uploadLayoutPage',
        data: {
          data:{
            name:'子页面标题',
            belongPage:$stateParams.itemId,
            belongProject:$scope.newLayout.belongProject,
            layoutType:'vertical',
            pageParam : pageParam,
            nameAttr:$scope.newLayout.belongProject=="haiNanProject"?'{"size":"40px","color":"#ffffffff","selectColor":"#ff26b5ff"}':'{"size":"40px","color":"#ffffffff","selectColor":"#ff26b5ff","style":"oval2","frameColor":"#ffffffff"}',
            pageAttr:'{}'
          }
        }
      }).then(function (result) {
        if (result.data.code == 0) {
          $scope.init(true);
        } else {
          $scope.$emit('notification', {type: 'danger', message: result.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
      });
     
     
    }
    $scope.showDeleteModal = function (data) {
      $scope.toDelPageData =data;
      console.log(data);
      $timeout(function () {
        $('#closePageWin').modal('show')
      }, 10);
    };
    $scope.toDelete = function(){
      $http({
        method: 'POST',
        url: '/layoutPage/delLayoutPage',
        data:{data : $scope.toDelPageData}
      }).then(function (response) {
        $timeout(function () {
          $('#closePageWin').modal('hide')
        }, 10);
        if (response.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '删除成功'});
          $scope.init(true);
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    }
    $scope.init = function (reload) {
      if (!$stateParams.belongProject) {
        $scope.$emit('notification', {type: 'danger', message: '非法请求页面'});
        $scope.errorRequest = true;
        return;
      }
      $http({
        method: 'Post',
        url: '/layoutPage/findLayoutPage',
        data:{data:{_id:$stateParams.itemId}},
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.newLayout = response.data.data;
        }
      }, function (err) {
      })
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
          if (response.data.code === 0) {
              $scope.belongs = response.data.layoutProject;
              for (let index = 0; index < $scope.belongs.length; index++) {
                const element = $scope.belongs[index];
                if(element.nameEn==$stateParams.belongProject){
                  $scope.curProjectNameEn = element.nameEn;
                  $scope.curProjectName = element.nameCn;
                  break;
                }
              }
              $scope.activitys = response.data.layoutActivity;
              $http({
                method: 'GET',
                url: '/layoutPage/getAllLayoutPage?belongPage='+$stateParams.itemId
              }).then(function (response) {
                if (response.data.code == 0) {
                  loadTable(response.data.data, reload)
                } else {
                  $scope.$emit('notification', {type: 'danger', message: response.data.msg});
                }
              }, function (err) {
                $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
              });
          }
      }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })
    };
    $scope.showReference = function (mData) {
      console.log(mData);
      $http({
        method: 'GET',
        url: '/weixin/getUser?openId='+mData.reference
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.preferenceUser = response.data.data;
          $timeout(function () {
            $('#referenceWin').modal('show')
          }, 10);
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
     
    };
  
  }
])
;