'use strict';


angular.module('controllers').controller('upgradeAllProgramCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state', '$stateParams', 'uploadFileService',
  function ($scope, Upload, $http, $window, $timeout, $state, $stateParams) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");
    $scope.checkState = function () {
      if (!$stateParams.belongProject) {
        $scope.$emit('notification', { type: 'danger', message: '非法请求页面' });
        $scope.errorRequest = true;
        return;
      }

      program = {
        type: '1',
        projectCode: $stateParams.belongProject,
        defaultChnlOnPower: {
          chnl_num: "",
          chnl_name: "",
        },
        delDvbList: [{
          chnl_num: "",
          chnl_name: "",
        }],
        newDvbList: [{
          chnl_num:"",
          chnl_name:"",
          url:"",
        }],
        onlyShowDvbList: [{
          chnl_num: "",
          chnl_name: "",
        }],
        lanmeiVideoServerList:[{
          uuid:"",
        }],
        customizeGroupList: [{
          channelList: [{
            chnl_num: "",
            chnl_name: "",
            isChnlDataFromLocalServer: "",
            url: "",
          }],
          groupName: "",
        }],
      }
      let data = {
        programID: $stateParams.programId,
      }
      if ($stateParams.programId) {
        $http({
          method: 'Post',
          url: '/web/program/findProgram',
          data: data,
        }).then(function (response) {
          if (response.data.code === 0) {
            program = response.data.data;
            // for (var i = 0; i < program.customizeGroupList.length; i++) {
            //   for (var j = 0; j < program.customizeGroupList[i].channelList.length; j++) {
            //     let isChnlDataFromLocalServer = program.customizeGroupList[i].channelList[j].isChnlDataFromLocalServer + "";
            //     program.customizeGroupList[i].channelList[j].isChnlDataFromLocalServer = isChnlDataFromLocalServer;
            //   }
            // }
            $scope.program = program;
            if(!isExist($scope.program.lanmeiVideoServerList)){
              $scope.program.lanmeiVideoServerList = []
            }
          }
        }, function (err) {
          $scope.$emit('notification', { type: 'danger', message: '网络错误，与服务器通信错误' });
        })
      }
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if (element.nameEn == $stateParams.belongProject) {
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
        }
      });
      loadTable();
    };

    var loadTable = function (reload) {
      console.log("loadTable");
      if(reload){
       
        $('#table2').bootstrapTable('refresh');
        return;
      }
      console.log("loadTable2");
      $('#table2').bootstrapTable({
        method : 'get',
        url : "/web/program/getAllVideo",//请求路径
        striped : true, //是否显示行间隔色
        pagination : true,//是否分页
        sidePagination : 'server',//server:服务器端分页|client：前端分页
        pageSize : 30,//单页记录数
        pageList : [ 5, 10, 20, 30 ],//可选择单页记录数
        showRefresh : true,//刷新按钮
        cache: false,
        search: true,
        showColumns: 'true',
        showExport: true,//显示导出按钮
        exportDataType: 'all',//导出类型
        queryParams : function(params) {//上传服务器的参数
          var temp = {//如果是在服务器端实现分页，limit、offset这两个参数是必须的
          limit : params.limit, // 每页显示数量
          //offset : params.offset, // SQL语句起始索引
          page : (params.offset / params.limit) + 1, //当前页码 
          searchName : $('.search input').val(),
          order : params.order, 
          sort : params.sort,
          projectCode: $stateParams.belongProject
          };
          return temp;
        },
        columns: [{
          title: '节目号',
          align: 'center',
          sortable: true,
          field: 'num',
        },{
          title: '节目名称',
          align: 'center',
          field: 'name',
        }, {
          title: '播放地址',
          align: 'left',
          field: 'sUrl',
          formatter: function (value, row, index) {
            return '<span style="display:block;white-space: normal !important;word-wrap: break-word;width:100px">'+(value?value:'-')+'</span>';
          }
        }, {
          title: '源地址',
          align: 'left',
          field: 'oUrl',
          formatter: function (value, row, index) {
            return '<span style="display:block;white-space: normal !important;word-wrap: break-word;width:100px">'+(value?value:'-')+'</span>';
          }
        }, {
          title: '是否加密',
          align: 'left',
          field: 'encrypted',
          formatter: function (value, row, index) {
            return !value?'<span style="color:red">不加密</span>':'<span style="color:green">加密</span>';
          }
        }, {
          title: '加密算法',
          align: 'left',
          field: 'encryption',
          formatter: function (value, row, index) {
            return value==0?'csa算法':"未知算法";
          }
        }, {
          title: '当前秘钥',
          align: 'center',
          field: 'curKey',
          formatter: function (value, row, index) {
            if(value==0){
              return '偶秘钥';
            }else if(value==1){
              return '奇秘钥';
            }else if(value==2){
              return '带内秘钥';
            }
            return '-'
          }
        }, {
          title: '奇秘钥',
          align: 'left',
          field: 'keyOdd',
          formatter: function (value, row, index) {
            return '<span style="display:block;white-space: normal !important;word-wrap: break-word;width:50px">'+(value?value:'-')+'</span>';
          }
        }, {
          title: '偶秘钥',
          align: 'left',
          field: 'keyEven',
          formatter: function (value, row, index) {
            return '<span style="display:block;white-space: normal !important;word-wrap: break-word;width:50px">'+(value?value:'-')+'</span>';
          }
        }, {
          title: '最大内存(MB)',
          align: 'left',
          field: 'maxRam',
          formatter: function (value, row, index) {
            return value?value:"不限制";
          }
        },{
          title: '授权服务器',
          align: 'center',
          field: 'authFrom',
          formatter: function (value, row, index) {
            if(value==0){
              return '当前服务器';
            }else if(value==1){
              return 'boss系统';
            }
            return '-'
          }
        },{
          title: '授权开始时间',
          align: 'center',
          field: 'authStartTime',
          formatter: function (value, row, index) {
          return value
          }
        },{
          title: '授权结束时间',
          align: 'center',
          field: 'authEndTime',
          formatter: function (value, row, index) {
          return value
          }
        },{
          title: '节目类型',
          align: 'center',
          sortable: true,
          field: 'programType',
          formatter: function (value, row, index) {
            return value==0?'黑名单节目':'新增节目';
          }
        },{
          title: '操作时间',
          align: 'center',
          sortable: true,
          field: 'updateTime',
          formatter: function (value, row, index) {
          return moment(value, 'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm')
          }
        },{
          title: '操作',
          align: 'center',
          width:'180px',
          formatter: function (value, row, index) {
            data = angular.toJson(row);
            return '<br><button style="width:85px;height:30px;margin-top:4px" class=\'btn btn-warning btn-xs\' onclick=\'angular.element(this).scope().editVideo(' + data + ')\'>编辑推流</button>'
              +'<br><button style="width:85px;height:30px;margin-top:4px" class=\'btn btn-danger btn-xs\' onclick=\'angular.element(this).scope().showDeleteModal(' + data + ')\'>删除</button>';
          }
        }]
        });
    }

    //正则判断是否为数字
    var isNumber = function (temp) {
      if ((/^\d{1,10}$/.test(temp))) {
        return true;
      }
      return false;
    }
    $scope.createVideo = function(){
      $state.go('main.upgradeVideo', {belongProject: $stateParams.belongProject,
        programId: $stateParams.programId})
    }
    $scope.editVideo = function(row){
      $state.go('main.upgradeVideo', {belongProject: $stateParams.belongProject,
        programId: $stateParams.programId,upgradeId:row._id})
    }
   
    $scope.showAuthModal = function () {
      $scope.programAuth = {
        authFrom:0,
        authStartTime:'',
        authEndTime:'',
        projectCode:$stateParams.belongProject,
      }
      $timeout(function () {
        $('#setAuthWin').modal('show')
      }, 10);
    };
    $scope.setAllAuthProgramTime = function(){
      if($scope.programAuth.authFrom==0){
        if (!$scope.programAuth.authStartTime) {
          $scope.$emit('notification', {type: 'danger', message: '请填写节目授权开始时间'});
          return;
        }
        if ($scope.programAuth.authStartTime&&!isValidDateTimeFormat($scope.programAuth.authStartTime)) {
          $scope.$emit('notification', {type: 'danger', message: '节目授权开始时间不符合规范'});
          return;
        }
        if (!$scope.programAuth.authEndTime) {
          $scope.$emit('notification', {type: 'danger', message: '请填写节目授权结束时间'});
          return;
        }
        if ($scope.programAuth.authEndTime&&!isValidDateTimeFormat($scope.programAuth.authEndTime)) {
          $scope.$emit('notification', {type: 'danger', message: '节目授权结束时间不符合规范'});
          return;
        }
      }
      $http({
        method: 'POST',
        url: '/web/program/setAllAuthProgramTime',
        data:{data : $scope.programAuth}
      }).then(function (response) {
        $timeout(function () {
          $('#setAuthWin').modal('hide')
        }, 10);
        if (response.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '设置成功'});
          loadTable(true);
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    }
    $scope.showDeleteModal = function (data) {
      $scope.toDelProjectData =data;
      $timeout(function () {
        $('#closePageWin').modal('show')
      }, 10);
    };
    $scope.toDelete = function(){
      $http({
        method: 'POST',
        url: '/web/program/delVideo',
        data:{data : $scope.toDelProjectData}
      }).then(function (response) {
        $timeout(function () {
          $('#closePageWin').modal('hide')
        }, 10);
        if (response.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '删除成功'});
          loadTable(true);
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    }
    $scope.pullFile = function(){
      $http({
        method: 'Get',
        url: '/web/program/getTemplateFile',
        responseType: 'arraybuffer' // 设置响应类型为 arraybuffer
      }).then(function(response) {
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const objectUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objectUrl;
          link.download = '推流数据模板.xlsx';
          link.click();
          URL.revokeObjectURL(objectUrl);
      }, function(err) {
          $scope.$emit('notification', { type: 'danger', message: '网络错误，与服务器通信错误' });
      });
    }
    $scope.uploadFiles = function (file) {
      if (!file[0]) {
        return;
      }
      Upload.upload({
        url: '/web/program/addVideoByFile',
        method: 'POST',
        data: { projectCode:$stateParams.belongProject },
        file: file
      }).then(function (resp) {
        if (resp.data.code != 0) {
          $scope.$emit('notification', {type: 'danger', message: resp.data.msg});
        } else {
          $timeout(function () {
            loadTable(true);
          }, 1000);
          $scope.$emit('notification', {type: 'success', message: '成功导入' + resp.data.count + '条数据'});
        }
      }, function (resp) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };
    $scope.showDeleteAllModal = function () {
      $timeout(function () {
        $('#closeAllPageWin').modal('show')
      }, 10);
    };
    $scope.toDeleteAll = function(){
      $http({
        method: 'POST',
        url: '/web/program/delAllVideo',
        data: { projectCode:$stateParams.belongProject },
      }).then(function (response) {
        $timeout(function () {
          $('#closeAllPageWin').modal('hide')
        }, 10);
        if (response.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '删除成功'});
          loadTable(true);
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    }
    //提交数据
    $scope.submit = function () {
      var mess = "默认节目配置错误:";
      if ($scope.program.defaultChnlOnPower) {
        if ($scope.program.defaultChnlOnPower.length > 0) {
          if (!isNumber($scope.program.defaultChnlOnPower.chnl_num)) {
            $scope.$emit('notification', { type: 'danger', message: mess + '节目号只可以是数字,请重新输入' });
            return;
          } else if (/[']/.test($scope.program.defaultChnlOnPower.chnl_name)) {
            $scope.$emit('notification', { type: 'danger', message: mess + '节目名称包含英文的单引号，请正确填写' });
            return;
          } else if (!$scope.program.defaultChnlOnPower.chnl_num && !$scope.program.defaultChnlOnPower.chnl_name) {
            $scope.$emit('notification', { type: 'danger', message: mess + '请填写节目名称' });
            return;
          }
        }
      }
      if ($scope.program.delDvbList.length > 0) {
        for (var i = 0; i < $scope.program.delDvbList.length; i++) {
          let element = $scope.program.delDvbList[i];
          var mes = "黑名单:";
          if (!isExist(element.chnl_num)&&!isExist(element.chnl_name)) {
            $scope.$emit('notification', { type: 'danger', message:  mes + '节目号和名称至少填写一个' });
            return;
          }
          if (isExist(element.chnl_num)&&!isNumber(element.chnl_num)) {
            $scope.$emit('notification', { type: 'danger', message: mes + '节目号只可以是数字,请重新输入' });
            return;
          }
          if (/[']/.test(element.chnl_name)
          ) {
            $scope.$emit('notification', { type: 'danger', message: mes + '节目名称包含英文的单引号，请正确填写' });
            return;
          }
        }
      }

      if($scope.program.newDvbList){
        if ($scope.program.newDvbList.length > 0) {
          for (var i = 0; i < $scope.program.newDvbList.length; i++) {
            let element = $scope.program.newDvbList[i];
            var mes = "新增列表:";
            if (!element.chnl_num && element.chnl_num != 0) {
              $scope.$emit('notification', { type: 'danger', message: mes + '请填写节目号' });
              return
            }
            if (isExist(element.chnl_num)&&!isNumber(element.chnl_num)) {
              $scope.$emit('notification', { type: 'danger', message: mes + '节目号只可以是数字,请重新输入' });
              return;
            }
            if (/[']/.test(element.chnl_name)
            ) {
              $scope.$emit('notification', { type: 'danger', message: mes + '节目名称包含英文的单引号，请正确填写' });
              return;
            }
            if (!element.chnl_name) {
              $scope.$emit('notification', { type: 'danger', message: mes + '请填写节目名称' });
              return;
            }
            if (!element.url) {
              $scope.$emit('notification', { type: 'danger', message: mes + '请填写url！' });
              return;
            }
            if (/[']/.test(element.url)) {
              $scope.$emit('notification', { type: 'danger', message: mes + 'url包含英文的单引号，请正确填写!' });
              return;
            }
            if (isChinese(element.url)) {
              $scope.$emit('notification', { type: 'danger', message: mes + 'url包含中文,请正确填写' });
              return;
            }
          }
        }
      }

      if ($scope.program.onlyShowDvbList.length > 0) {
        for (var i = 0; i < $scope.program.onlyShowDvbList.length; i++) {
          let element = $scope.program.onlyShowDvbList[i];
          var mes = "白名单:";
          if (!isExist(element.chnl_num)&&!isExist(element.chnl_name)) {
            $scope.$emit('notification', { type: 'danger', message:  mes + '节目号和名称至少填写一个' });
            return;
          }
          if (isExist(element.chnl_num)&&!isNumber(element.chnl_num)) {
            $scope.$emit('notification', { type: 'danger', message: mes + '节目号只可以是数字,请重新输入' });
            return;
          }
          if (/[']/.test(element.chnl_name)
          ) {
            $scope.$emit('notification', { type: 'danger', message: mes + '节目名称包含英文的单引号，请正确填写' });
            return;
          }
        }
      }
      if($scope.program.lanmeiVideoServerList.length>0){
        for (var i = 0; i < $scope.program.lanmeiVideoServerList.length; i++) {
          let element = $scope.program.lanmeiVideoServerList[i];
          var mes = "配置蓝莓加密授权工作站:";
          if (!isExist(element.uuid)) {
            $scope.$emit('notification', { type: 'danger', message:  mes + '请填写工作站标识' });
            return;
          }
          if (/[']/.test(element.uuid)
          ) {
            $scope.$emit('notification', { type: 'danger', message: mes + '工作站标识包含英文的单引号，请正确填写' });
            return;
          }
        }
      }

      if ($scope.program.customizeGroupList.length > 0) {
        for (var parentIndex = 0; parentIndex < $scope.program.customizeGroupList.length; parentIndex++) {
          if(!$scope.program.customizeGroupList[parentIndex].groupName||$scope.program.customizeGroupList[parentIndex].groupName==""){
            $scope.$emit('notification',{type:'danger',message:'请填写组名'});
            return;
          }
          for(var i=0;i<$scope.program.customizeGroupList.length;i++){
            if (i != parentIndex) {
              if ($scope.program.customizeGroupList[parentIndex].groupName==$scope.program.customizeGroupList[i].groupName) {
                $scope.$emit('notification', { type: 'danger', message: mes + '组名重复，请重新输入' });
                return;
              }
            }
          }
          if ($scope.program.customizeGroupList[parentIndex].channelList.length > 0) {
            for (var i = 0; i < $scope.program.customizeGroupList[parentIndex].channelList.length; i++) {
              let element = $scope.program.customizeGroupList[parentIndex].channelList[i];
              var mes = "自定义列表-" + $scope.program.customizeGroupList[parentIndex].groupName + "：";
              if (!element.chnl_num && element.chnl_num != 0) {
                $scope.$emit('notification', { type: 'danger', message: mes + '请填写节目号' });
                return
              }
              if (!isNumber(element.chnl_num)) {
                $scope.$emit('notification', { type: 'danger', message: mes + '节目号只可以是数字,请重新输入' });
                return;
              }

              let programList = $scope.program.customizeGroupList[parentIndex].channelList;

              for (let index = 0; index < programList.length; index++) {
                const program = programList[index];
                if (i != index) {
                  if (program.chnl_num == element.chnl_num) {
                    $scope.$emit('notification', { type: 'danger', message: mes + '节目号重复，请重新输入' });
                    return;
                  }
                }
              }

              if (/[']/.test(element.chnl_name)
              ) {
                $scope.$emit('notification', { type: 'danger', message: mes + '节目名称包含英文的单引号，请正确填写' });
                return;
              }
              if (!element.chnl_name) {
                $scope.$emit('notification', { type: 'danger', message: mes + '请填写节目名称' });
                return;
              }
              for (let index = 0; index < programList.length; index++) {
                const program = programList[index];
                if (i != index) {
                  if (program.chnl_name == element.chnl_name) {
                    $scope.$emit('notification', { type: 'danger', message: mes + '节目重复,请输入其他节目' });
                    return;
                  }
                }
              }
              if (element.isChnlDataFromLocalServer == "true") {
                if (!element.url) {
                  $scope.$emit('notification', { type: 'danger', message: mes + '请填写url！' });
                  return;
                }
                if (/[']/.test(element.url)) {
                  $scope.$emit('notification', { type: 'danger', message: mes + 'url包含英文的单引号，请正确填写!' });
                  return;
                }
                if (isChinese(element.url)) {
                  $scope.$emit('notification', { type: 'danger', message: mes + 'url包含中文,请正确填写' });
                  return;
                }
              } else if (element.isChnlDataFromLocalServer == "false") {
                if (element.url) {
                  $scope.$emit('notification', { type: 'danger', message: mes + '没有自定义节目，不需要填写url' });
                  return;
                }
              } else if (element.isChnlDataFromLocalServer == "" || element.isChnlDataFromLocalServer == null) {
                $scope.$emit('notification', { type: 'danger', message: mes + '请选择是否为自定义节目！' });
                return;
              }
            }
          }
        }
      }

      let data = {
        type: $scope.program.type,
        projectCode: $stateParams.belongProject,
        programId: $stateParams.programId,
        defaultChnlOnPower: $scope.program.defaultChnlOnPower,
        delDvbList: $scope.program.delDvbList,
        newDvbList: $scope.program.newDvbList,
        onlyShowDvbList: $scope.program.onlyShowDvbList,
        lanmeiVideoServerList: $scope.program.lanmeiVideoServerList,
        customizeGroupList: $scope.program.customizeGroupList,
      }

      $http({
        method: 'POST',
        url: '/web/program/upgradeAllProgram',
        data: data,
      }).then(function (result) {
        if (result.data.code == 0) {
          $scope.$emit('notification', { type: 'success', message: '提交成功，2秒后跳回节目配置' });
          $timeout(function () {
            $state.go('main.configProjectInfo', { belongProject: $stateParams.belongProject });
          }, 2000)
        } else {
          $scope.$emit('notification', { type: 'danger', message: result.data.msg });
        }
      }, function (err) {
        $scope.$emit('notification', { type: 'danger', message: '网络错误，提交失败' });
      });
    };
    $scope.autoAddCustomizeGroupList = function () {
      
      $http({
        method: 'POST',
        url: '/web/program/downloadProgramOnline',
      }).then(function (result) {
        if (result.data.code == 0) {
          $scope.$emit('notification', { type: 'success', message: '节目生成成功' });
          $scope.program.customizeGroupList = result.data.data;
          // $timeout(function () {
          //   $window.location.reload();
          // }, 1000)
        } else {
          $scope.$emit('notification', { type: 'danger', message: result.data.msg });
        }
      }, function (err) {
        $scope.$emit('notification', { type: 'danger', message: '网络错误，提交失败' });
      });
    }
    function isExist(str) {
      return str!=undefined&&str!='';
    }
    $scope.addDelDvbList = function () {
      $scope.program.delDvbList.push("");
    }
    $scope.delDelDvbList = function (index) {
      $scope.program.delDvbList.splice(index, 1);
    }
    $scope.addNewDvbList = function () {
      $scope.program.newDvbList.push("");
    }
    $scope.delNewDvbList = function (index) {
      $scope.program.newDvbList.splice(index,1)
    }
    $scope.addOnlyShowDvbList = function () {
      $scope.program.onlyShowDvbList.push("");
    }
    $scope.delOnlyShowDvbList = function (index) {
      $scope.program.onlyShowDvbList.splice(index, 1);
    }
    $scope.addLanmeiVideoServerList = function () {
      $scope.program.lanmeiVideoServerList.push("");
    }
    $scope.delLanmeiVideoServerList = function (index) {
      $scope.program.lanmeiVideoServerList.splice(index, 1);
    }
    $scope.addCustomizeGroupList = function () {
      let customizeGroupList = {
        channelList: [{
          chnl_num: "",
          chnl_name: "",
          isChnlDataFromLocalServer: "",
          url: "",
        }],
        groupName: "",
      }
      $scope.program.customizeGroupList.push(customizeGroupList);
    }
    $scope.delCustomizeGroupList = function (index) {
      $scope.program.customizeGroupList.splice(index, 1);
    }
    $scope.addChannelList = function (index) {
      $scope.program.customizeGroupList[index].channelList.push("");
    }
    $scope.delChannelList = function (parentIndex, index) {
      console.log("parentIndex:------------" + parentIndex + "index:--------------" + index);
      $scope.program.customizeGroupList[parentIndex].channelList.splice(index, 1);
    }



    $scope.backUrl = function () {
      $state.go('main.configProjectInfo', { belongProject: $stateParams.belongProject });
    }
    var isChinese = function (temp) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(temp)) {
        return true;
      }
      return false;
    }
    function isValidDateTimeFormat(dateTimeString) {
      // Regular expression for YYYY-MM-DD HH:mm format
      var dateTimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;
      
      return dateTimeRegex.test(dateTimeString);
    } 
  }
]);