/**
 * Created by Administrator on 2017/7/12.
 */

'use strict';
angular.module('controllers').controller('upgradeLayoutProjectCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state', '$stateParams',
  function ($scope, Upload, $http, $window, $timeout, $state, $stateParams) {
    $scope.layoutProject ={
      nameCn:'',
      nameEn:'',
      pageTemplateProjectId:''
    }
    $scope.checkState = function () {
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
          if (response.data.code === 0) {
              $scope.projects = [];
              $scope.projects.push({
                nameCn:'无',
                nameEn:'',
                _id:''
              });
              if(response.data.layoutProject){
                response.data.layoutProject.forEach(element => {
                  if(element.isTemplate){
                    $scope.projects.push(element);
                  }
                });
              }
          } else {
            $scope.$emit('notification', {type: 'danger', message: response.data.msg});
          }
      }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })
    };
  
    $scope.createProject = function(){
      if (!$scope.layoutProject.nameCn) {
        $scope.$emit('notification', {type: 'danger', message: '请填写项目名'});
        return;
      }
      if (!$scope.layoutProject.nameEn) {
        $scope.$emit('notification', {type: 'danger', message: '请填写code'});
        return;
      }
      if (/[']/.test($scope.layoutProject.nameCn)
      ||/[']/.test($scope.layoutProject.nameEn)
      ) {
        $scope.$emit('notification', {type: 'danger', message: '内容包含英文的单引号，不允许提交!'});
        return;
      }
      $http({
        method: 'POST',
        url: '/layoutPage/createLayoutProject',
        data: {
          data: $scope.layoutProject
        }
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '创建成功'});
          $timeout(function(){
            $state.go("main.layoutProject")
          },500)
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    }

    $scope.backUrl = function(){
      $state.go('main.layoutProject');
    }
    var isChinese = function(temp){
      if(/.*[\u4e00-\u9fa5]+.*$/.test(temp)) {
        return true;
      }
      return false;
    }
    $scope.getTime = function(){
      return Date.now();
    }
  }
]);