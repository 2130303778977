/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('upgradeHandleApprovalCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state','$stateParams',
  function ($scope, Upload, $http, $window, $timeout, $state,$stateParams) {
    $scope.approvalProcessReply = "";
    var loadTable = function (reload) {
      if(reload){
        $('#table2').bootstrapTable('refresh');
        return;
      }
      $('#table2').bootstrapTable({
          method : 'get',
          url : "/web/approvalProcess/getBindMac?approvalId="+$stateParams.dataId,//请求路径
          striped : true, //是否显示行间隔色
          pagination : true,//是否分页
          sidePagination : 'server',//server:服务器端分页|client：前端分页
          pageSize : 2,//单页记录数
          pageList : [2, 5, 10, 20, 30 ],//可选择单页记录数
          showRefresh : true,//刷新按钮
          cache: false,
          search: true,
          showColumns: 'true',
          showExport: true,//显示导出按钮
          exportDataType: 'all',//导出类型
          queryParams : function(params) {//上传服务器的参数
            var temp = {//如果是在服务器端实现分页，limit、offset这两个参数是必须的
              limit : params.limit, // 每页显示数量
              //offset : params.offset, // SQL语句起始索引
              page : (params.offset / params.limit) + 1, //当前页码 
              searchName : $('.search input').val(),
            };
            return temp;
          },
          columns: [{
            title: 'mac',
            align: 'center',
            field: 'mac',
        
          }, {
            title: '时间',
            align: 'center',
            field: 'updateTime',
            formatter: function (value, row, index) {
              return moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')
            }
          }, {
            title: '操作',
            align: 'center',
            width:'120px',
            formatter: function (value, row, index) {
              row = angular.toJson(row);
              return '<button style="margin-top:5px" class=\'btn btn-danger btn-xs\' onclick=\'angular.element(this).scope().toUnBindApproval(' + row + ')\'>删除</button>'
            }
          }]
        });
    }
    $scope.toUnBindApproval= function (data) {
      $http({
        method: 'POST',
        url: '/web/approvalProcess/approvalUnbindMac',
        data:{
            mac:data.mac,
            approvalId:$stateParams.dataId
        }
      }).then(function (result) {
        if (result.data.code == 0) {
          loadTable(true);
        } else {
          $scope.$emit('notification', {type: 'danger', message: result.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
      });
    }

    $scope.upgradeInit = function (reload) {
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          loadTable();
        }
      });
      $http({
        method: 'POST',
        url: '/web/approvalProcess/findOne',
        data: {
          _id:$stateParams.dataId,
        }
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.approvalProcess = response.data.data;
          for (let index = 0; index < $scope.approvalProcess.reviewer.length; index++) {
            const element = $scope.approvalProcess.reviewer[index];
            if(element.state=="1"){
              $scope.approvalProcessReply = element.reply
              break;
            }
          }
        }
      });
    };
    $scope.backUrl = function(){
      $state.go("main.handleApproval")
    }

    $scope.approvalBind = function(){
      $http({
        method: 'POST',
        url: '/web/approvalProcess/approvalBindMac',
        data:{
            mac:$scope.gatewayUuid,
            approvalId:$stateParams.dataId
        }
      }).then(function (result) {
        if (result.data.code == 0) {
          loadTable(true);
        } else {
          $scope.$emit('notification', {type: 'danger', message: result.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
      });
    }

    $scope.approvalPass = function(){
      if (/[']/.test($scope.approvalProcessReply)
      ) {
        $scope.$emit('notification', {type: 'danger', message: '内容包含英文的单引号，不允许提交!'});
        return;
      }
      if (!$scope.approvalProcessReply) {
        $scope.$emit('notification', {type: 'danger', message: '请填写审批意见'});
        return;
      }
      $http({
        method: 'POST',
        url: '/web/approvalProcess/approvalPass',
        data:{
            approvalId:$stateParams.dataId,
            approvalProcessReply:$scope.approvalProcessReply
        }
      }).then(function (result) {
        if (result.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '已设置审核通过,2秒后返回列表'});
          $timeout(function(){
            $scope.backUrl();
          },2000)
        } else {
          $scope.$emit('notification', {type: 'danger', message: result.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
      });
    }

    $scope.approvalUnpass = function(){
      if (/[']/.test($scope.approvalProcessReply)
      ) {
        $scope.$emit('notification', {type: 'danger', message: '内容包含英文的单引号，不允许提交!'});
        return;
      }
      if (!$scope.approvalProcessReply) {
        $scope.$emit('notification', {type: 'danger', message: '请填写审批意见'});
        return;
      }
      $http({
        method: 'POST',
        url: '/web/approvalProcess/approvalUnpass',
        data:{
            approvalId:$stateParams.dataId,
            approvalProcessReply:$scope.approvalProcessReply
        }
      }).then(function (result) {
        if (result.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '已设置审核不通过,2秒后返回列表'});
          $timeout(function(){
            $scope.backUrl();
          },2000)
        } else {
          $scope.$emit('notification', {type: 'danger', message: result.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
      });
    }
  }
])
;