/**
 * Created by Administrator on 2017/7/4.
 */
// hotelNum:10,
// activeRoomNum:10,
// activeRoomNumInMonth:10,
// chartY:[792, 786, 782, 734, 762, 744, 773],//近7天的客房在线数
// chartX:[792, 786, 782, 734, 762, 744, 773],
// count:10
'use strict';
angular.module('controllers').controller('statisticCtr', ['$scope', '$window','$http',
  function ($scope,$window,$http) {
    var dom = document.getElementById("container");
    var myChart = echarts.init(dom);
    var option;
    $scope.data = {};
    $scope.layoutProjectEn ='';
    $scope.statisticsInit = function () {
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
          if (response.data.code === 0) {
              $scope.projects = [];
              $scope.projects.push({
                nameCn:'全部',
                nameEn:'',
                _id:''
              });
              if(response.data.layoutProject){
                response.data.layoutProject.forEach(element => {
                    $scope.projects.push(element);
                });
              }
          } else {
            $scope.$emit('notification', {type: 'danger', message: response.data.msg});
          }
      }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })
      $scope.refreshData();
    };

    $scope.refreshData = function(){
      $http({
        method: 'GET',
        url: '/web/statistic/getAllStatistic?belongProject='+$scope.layoutProjectEn,
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.data.hotelNum = response.data.data.hotelNum;
          $scope.data.roomNum = response.data.data.roomNum;
          $scope.data.activeRoomNum = response.data.data.activeRoomNum;
          $scope.data.activeRoomNumInMonth = response.data.data.activeRoomNumInMonth;
          let listX = [];
          let listY = [];
          if(response.data.data.dataList){
            response.data.data.dataList.map(one=>{
              listX.push(one.day);
              listY.push(one.activeRoomNum)
            })
          }
          option ={
            title: {
              text: '',//主标题
            },
            tooltip: {
              trigger: 'axis',
              show: true
            },
            grid: {
              left: '6%',
              right: '6%',
              bottom: '8%',// 这几个属性可以控制图表上下左右的空白尺寸，可以自己试试。
              containLabel: true
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
//      axisLabel: {rotate: 20}, //旋转
              data:listX
            },
            yAxis: {
              type: 'value',
              scale:true
//      minInterval: 20
            },
            series: [{
              name: '设备数',
              type: 'line',
              // smooth: true,
              data: listY,
              label:{show:true,color:'blue'},
              areaStyle: {color:'#33ff66'},
              lineStyle:{color:'#0099ff'}
            }]
          };
          if (option && typeof option === "object") {
            myChart.setOption(option, true);
            myChart.dispatchAction({type: 'showTip', seriesIndex: '0', dataIndex: '0'});
          }

        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    }

    $(window).on('resize', function () {
      dom.style.width = $('.panel-body').width()+'px';
      myChart.resize();
    });

  }
]);