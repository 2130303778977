/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('configDiffpkgCtr', ['$scope', 'Upload', '$http', '$window', '$timeout','$state','$stateParams',
  function ($scope, Upload, $http, $window, $timeout,$state,$stateParams) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");

    $scope.plug = {
      name: '',
      id: '',
    };

    var loadTable = function (data, reload) {
      if (reload) {
        $('#table2').bootstrapTable('load', data);
      } else {
        $('#table2').bootstrapTable({
          pagination: true,
          pageSize: 10, //每页显示10条
          cache: false,
          search: true,
          showColumns: 'true',
          showExport: true,//显示导出按钮
          exportDataType: 'all',//导出类型
          columns: [{
            title: '配置类型',
            align: 'center',
            field: 'type',
            width:'180px',
            sortable: true,
            formatter: function (value, row, index) {
              var str =  "未知"
              if(value==2){
                str = "Android系统（2G版本）"
              }else if(value==6){
                str = "Android系统（1G版本）"
              }
              return str
            }
          },{
            title: '旧版本号',
            align: 'center',
            field: 'version',
          },{
            title: '新版本号',
            align: 'center',
            field: 'version2',
          }, {
            title: 'md5',
            align: 'center',
            field: 'md5',
          },{
            title: '下载链接',
            align: 'center',
            sortable: true,
            field: 'url',
          }, {
            title: '更新说明',
            align: 'center',
            field: 'content',
            width:'100px',
            formatter: function (value, row, index) {
              var result =  value.replace(/\n/g,'<br/>');
              return result==''?"无":result
            }
          }, {
            title: '是否禁用',
            align: 'center',
            field: 'forbidden',
            formatter: function (value, row, index) {
              return value=='1'?'<span style="color:red">禁用</span>':'<span style="color:green">启用</span>';
            }
          }, {
            title: '更新日期',
            align: 'center',
            field: 'updateTime',
            formatter: function (value, row, index) {
              return moment(value, 'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm')
            }
          }, {
            title: '操作',
            align: 'center',
            width:'120px',
            formatter: function (value, row, index) {
              var row = angular.toJson(row);
              return '<button  class=\'btn btn-success btn-xs\' onclick=\'angular.element(this).scope().toUploadPage(' + row + ')\'>更新配置</button>&nbsp;' 
              +'<br><button style="margin-top:5px" class=\'btn btn-warning btn-xs\' onclick=\'angular.element(this).scope().showForbiddenModal(' + row + ')\'>禁用/启用</button>'
              +'<br><button style="margin-top:5px" class=\'btn btn-danger btn-xs\' onclick=\'angular.element(this).scope().delPlugModal(' + row + ')\'>删除配置</button>';

            }
          }],
          data: data
        });
      }
    };

    $scope.plugInit = function (reload) {
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if(element.nameEn==$stateParams.belongProject){
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
        }
      });
      $http({
        method: 'GET',
        url: '/web/configDiffpkg/webGetAll?projectCode='+$stateParams.belongProject,
      }).then(function (response) {
        if (response.data.code == 0) {
          console.log(response.data.data);
          loadTable(response.data.data, reload)
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };
    $scope.toUploadPage = function (data) {
      $state.go('main.upgradeConfigDiffpkg', {upgradeId:data._id,belongProject:$stateParams.belongProject})
    };
    $scope.delPlugModal = function (data) {
      $scope.plug = {};
      $scope.plug.fileName = data.fileName;
      $scope.plug.id = data._id;
      $timeout(function () {
        $('#delModal').modal('show')
      }, 10);
    };
    $scope.showForbiddenModal = function (data) {
      $scope.plug.id = data._id;
      $scope.plug.showName = data.fileName;
      $scope.plug.newForbidden = '0';
      $scope.plug.forbidden = data.forbidden=='1'?'1':'0';

      $timeout(function () {
        $('#forbiddenModal').modal('show')
      }, 10);
    };
    $scope.doUpdateForbidden = function () {
      $http({
        method: 'POST',
        url: '/web/configDiffpkg/changeForbidden',
        data: {
          id: $scope.plug.id,
          forbidden:$scope.plug.newForbidden,
          projectCode:$stateParams.belongProject,
        }
      }).then(function (response) {
        $timeout(function () {
          $('#forbiddenModal').modal('hide')
        }, 10);

        if (response.data.code == 0) {
          $scope.plugInit(true);
          $scope.$emit('notification', {type: 'success', message: '修改成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

    $scope.doDelPlug = function () {
      $http({
        method: 'DELETE',
        url: '/web/configDiffpkg/delPlug',
        params: {
          id: $scope.plug.id
        }
      }).then(function (response) {
        $timeout(function () {
          $('#delModal').modal('hide')
        }, 10);

        if (response.data.code == 0) {
          $scope.plugInit(true);
          $scope.$emit('notification', {type: 'success', message: '删除成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

  }
]);