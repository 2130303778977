/**
 * Created by Administrator on 2017/1/19.
 */
angular.module('services')
  .factory('FlagInterceptor',['$q', '$window','$rootScope','Global',function($q, $window,$rootScope,Global) {
    return {
      request: function (config) {
        config.headers = config.headers || {};

        return config;
      },

      response: function (response) {
        var data = response.data;
        if (response.status==403||(data.code && data.code == 10004)) {
          $rootScope.$emit("notification", {type: 'danger',status:403, message: '登录信息失效'});
        }

        return response || $q.when(response);
      },
      responseError: function (response) {
        return $q.reject(response);
      }
    };
  }]);
