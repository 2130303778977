/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('statisticFileDetailCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state',"$stateParams",
  function ($scope, Upload, $http, $window, $timeout, $state,$stateParams) {
    
    var loadTable = function (data, reload) {
        if (reload) {
          $('#table2').bootstrapTable('load', data);
        } else {
          $('#table2').bootstrapTable({
              pagination: true,
              pageSize: 10, //每页显示10条
              cache: false,
              search: true,
              showColumns: 'true',
              showExport: true,//显示导出按钮
              exportDataType: 'all',//导出类型
              columns: [{
                title: '归属项目',
                sortable: true,
                align: 'center',
                field: 'belongProject',
              }, {
                title: '唯一标识',
                sortable: true,
                align: 'center',
                field: 'uuid',
              }, {
                title: '下载次数',
                sortable: true,
                align: 'center',
                field: 'downloadSum',
              }, {
                title: '最近一次的下载时间',
                sortable: true,
                align: 'center',
                field: 'updateTime',
                formatter: function (value, row, index) {
                  return moment(value, 'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm')
                }
              }
            ],
              data: data
            }
          );
        }
      }
    ;
    $scope.toDetail = function(id){
      $scope.toUpdate = function(data){
        $state.go('main.statisticFileDetail',{itemId:id})
      }
     
    }
    $scope.file = {};
    $scope.otherDownloadSum = 0;
    $scope.upgradeInit = function (reload) {
      $http({
        method: 'GET',
        url: '/web/statistic/getFile?fileId='+$stateParams.itemId,
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.file = response.data.data;
          $http({
            method: 'GET',
            url: '/web/statistic/getAllFileDetail?fileId='+$stateParams.itemId,
          }).then(function (response) {
            if (response.data.code == 0) {
              var sum = 0;
              if(response.data.data.length>0){
                for (let index = 0; index < response.data.data.length; index++) {
                  sum = sum+response.data.data[index].downloadSum;
                }
              }
              $scope.otherDownloadSum = $scope.file.downloadSum - sum;
              loadTable(response.data.data, reload)
            } else {
              $scope.$emit('notification', {type: 'danger', message: response.data.msg});
            }
          }, function (err) {
            $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
          });

        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

  }
])
;