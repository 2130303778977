/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('layoutPageGroupCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state','$stateParams',
  function ($scope, Upload, $http, $window, $timeout, $state,$stateParams) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");
    var pageParam = {
      dimension_h:2,
      dimension_w:3,
      margin_h:3,
      margin_w:3,
      max_cols:60,
    }
    $scope.canForceApproval = false;
    var loadTable = function (data, reload) {
        if (reload) {
          $('#table2').bootstrapTable('load', data);
        } else {
          $('#table2').bootstrapTable({
              pagination: true,
              pageSize: 10, //每页显示10条
              cache: false,
              search: true,
              showColumns: 'true',
              showExport: true,//显示导出按钮
              exportDataType: 'all',//导出类型
              columns: [{
                title: '页面别名（用户不可见，方便后台人员查找页面）',
                align: 'center',
                field: 'name',
              }, {
                title: '所属页面',
                align: 'center',
                field: 'belongActivity',
                sortable: true,
                formatter:function (value,row,index) {
                  for (let index = 0; index < $scope.activitys.length; index++) {
                    const element = $scope.activitys[index];
                    if(row.belongActivity==element.nameEn){
                      return element.nameCn;
                    }
                  }
                  return row.belongActivity
                }
              }, {
                title: '更新日期',
                align: 'center',
                field: 'updateTime',
                formatter: function (value, row, index) {
                  return moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')
                }
              },{
                title: '操作',
                align: 'center',
                width:'120px',
                formatter: function (value, row, index) {
                  row = angular.toJson(row);
                  if(!$scope.checkApprovalProcessNotFinish){
                      return '<button  class=\'btn btn-success btn-xs\' onclick=\'angular.element(this).scope().toUpdate(' + row + ')\'>更新</button>'
                    +'&nbsp;&nbsp;<button  class=\'btn btn-danger btn-xs\' onclick=\'angular.element(this).scope().showDeleteModal(' + row + ')\'>删除</button>';
                  }else{
                    return '<button  class=\'btn btn-default btn-xs\' onclick=\'angular.element(this).scope().showNoRight(' + row + ')\'>更新</button>'
                    +'&nbsp;&nbsp;<button  class=\'btn btn-default btn-xs\' onclick=\'angular.element(this).scope().showNoRight(' + row + ')\'>删除</button>';
                  }
                  
                }
              }
            ],
              data: data
            }
          );
        }
      }
    ;
    $scope.doUpdate = function(){
      $http({
        method: 'POST',
        url: '/layout/uploadLayout',
        data: $scope.upgrade
      }).then(function (result) {
        $timeout(function () {
          $('#changeModal').modal('hide')
        }, 10);

        if (result.data.code == 0) {
          $scope.init(true);
          $scope.$emit('notification', {type: 'success', message: '修改成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: result.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
      });
    }
    var getCanForceApproval = function(){
      $http({
        method: 'POST',
        url: '/web/approvalProcess/getCanForceApproval',
        data:{
          projectCode:$stateParams.belongProject
        }
      }).then(function (result) {
        if (result.data.code == 0) {
          $scope.canForceApproval = result.data.canForceApproval
        } else {
          $scope.$emit('notification', {type: 'danger', message: result.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
      });
    }
    $scope.forceApproval = function(data){
      if($scope.checkApprovalProcessNotFinish){
        $scope.$emit('notification', {type: 'danger', message: "页面在审核中，请先审核！"});
        return;
      }
      $http({
        method: 'POST',
        url: '/web/approvalProcess/forceApproval',
        data:{
          projectCode:$stateParams.belongProject
        }
      }).then(function (result) {
        if (result.data.code == 0) {
          location.reload();
        } else {
          $scope.$emit('notification', {type: 'danger', message: result.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
      });
      
    }
    $scope.toUpdateSubPage = function(data){
      //let urlHref = $state.href('main.layoutPage',{itemId:data.items[0].page});
        //  window.open(urlHref);
      $state.go('main.layoutPage',{itemId:data.items[0].page})
    }
    $scope.toUpdate = function(data){
      window.location.href=$state.href('main.upgradeLayoutPage',{itemId:data._id});
    }
    $scope.changeProjectCardAttr = function(){
      $state.go('main.upgradeLayoutProjectCardAttr', {projectId:$scope.curProjectInfo._id,belongProject:$stateParams.belongProject})
    }
    $scope.createPage = function(){
      $http({
        method: 'POST',
        url: '/layoutPage/createLayoutPageGroup',
        data:{
          data:{
            name:'页面别名',
            belongActivity:'otherPage',
            belongProject:$stateParams.belongProject,
            layoutType:'absolute',
            pageParam : pageParam,
            minUsedVer:'0',
            pageAttr:'{}'
          }
        }
      }).then(function (result) {
        if (result.data.code == 0) {
          $scope.init(true);
        } else {
          $scope.$emit('notification', {type: 'danger', message: result.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
      });
     
    }
    $scope.showDeleteModal = function (data) {
      $scope.toDelPageData =data;
      console.log(data);
      $timeout(function () {
        $('#closePageWin').modal('show')
      }, 10);
    };
    $scope.toDelete = function(){
      $http({
        method: 'POST',
        url: '/layoutPage/delLayoutPage',
        data:{data : $scope.toDelPageData}
      }).then(function (response) {
        $timeout(function () {
          $('#closePageWin').modal('hide')
        }, 10);
        if (response.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '删除成功'});
          $scope.init(true);
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    }
    $scope.init = function (reload) {
      $scope.needApproval = false;
      $http({
        method: 'POST',
        url: '/web/approvalProcess/findConfigApproval',
        data: {
          projectCode:$stateParams.belongProject
        }
      }).then(function (response) {
        console.log(response)
        if (response.data.code == 0) {
          var configApproval = response.data.data;
          if(configApproval&&configApproval.reviewer&&configApproval.reviewer.length>0){
            $scope.needApproval =true;
          }
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
      $http({
        method: 'POST',
        url: '/web/approvalProcess/checkApprovalProcessNotFinish',
        data: {
          projectCode:$stateParams.belongProject
        }
      }).then(function (result) {
        if (result.data.code == 0) {
          $scope.checkApprovalProcessNotFinish = false;
        } else {
          $scope.checkApprovalProcessNotFinish = true;
          $scope.ApprovalUserPromoter = result.data.data.userPromoter;
        }
        $http({
          method: 'GET',
          url: '/config/getLayoutConfig',
        }).then(function (response) {
            if (response.data.code === 0) {
                $scope.belongs = response.data.layoutProject;
                for (let index = 0; index < $scope.belongs.length; index++) {
                  const element = $scope.belongs[index];
                  if(element.nameEn==$stateParams.belongProject){
                    $scope.curProjectNameEn = element.nameEn;
                    $scope.curProjectName = element.nameCn;
                    $scope.curProjectInfo = element;
                    break;
                  }
                }
                $scope.activitys = response.data.layoutActivity;
                $http({
                  method: 'GET',
                  url: '/layoutPage/getAllLayoutPage?belongProject='+$stateParams.belongProject
                }).then(function (response) {
                  if (response.data.code == 0) {
                    loadTable(response.data.data, reload)
                  } else {
                    $scope.$emit('notification', {type: 'danger', message: response.data.msg});
                  }
                }, function (err) {
                  $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
                });
            }
        }, function (err) {
            $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
        })

      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误'});
      });

      $http({
        method: 'POST',
        url: '/layoutPage/getLayoutPageState',
        data:{
          projectCode:$stateParams.belongProject,
        }
      }).then(function (result) {
        if (result.data.code == 0) {
          $scope.pageState = result.data.data.state;
        } else {
          $scope.$emit('notification', {type: 'danger', message: result.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
      });
      getCanForceApproval();
    };
    $scope.showReference = function (mData) {
      console.log(mData);
      $http({
        method: 'GET',
        url: '/weixin/getUser?openId='+mData.reference
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.preferenceUser = response.data.data;
          $timeout(function () {
            $('#referenceWin').modal('show')
          }, 10);
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
     
    };
    $scope.toApprovalProcess = function (data) {
      $state.go('main.upgradeApprovalProcess', {belongProject:$stateParams.belongProject})
    };
    $scope.toHandleApprovalProcess = function (data) {
      $state.go('main.handleApproval')
    };

    $scope.showNoRight = function(){
      if($scope.canForceApproval){
        $scope.$emit('notification', {type: 'danger', message: '页面在审核中，请先处理审核！'});
      }else{
        $scope.$emit('notification', {type: 'danger', message: '页面在审核中，请等待审核或者撤销审核请求！'});
      }
     
    }
  
  }
])
;