/**
 * Created by Administrator on 2017/7/12.
 */

'use strict';
angular.module('controllers').controller('upgradeConfigAndroidCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state', '$stateParams','uploadFileService',
  function ($scope, Upload, $http, $window, $timeout, $state, $stateParams,uploadFileService) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");
    var subscription = null;
    //粗略检测是否非法进入此页面
    $scope.checkState = function () {
      if (!$stateParams.upgradeId||!$stateParams.belongProject) {
        $scope.$emit('notification', {type: 'danger', message: '非法请求页面'});
        $scope.errorRequest = true;
        return;
      }
      $scope.upgrade = {
        type: 0,
        projectCode:$stateParams.belongProject,
        md5: '',
        version: '',
        fileName: '',
        force: 0,
        max_force: 0,
        content: '',
        force_time:{
          type:0,
          delay:0,
          start:'',
          end:''
        }
      };
      if($stateParams.upgradeId){
        $http({
          method: 'Post',
          url: '/web/configAndroid/findOne',
          data:{data:{_id:$stateParams.upgradeId}},
        }).then(function (response) {
            if (response.data.code === 0) {
              $scope.upgrade = response.data.data;
              if($scope.upgrade.force_time&&$scope.upgrade.force_time.type==1){
                $scope.upgrade.force_time.start = new Date($scope.upgrade.force_time.start);  
                $scope.upgrade.force_time.end = new Date($scope.upgrade.force_time.end);  
              }
              
            }
        }, function (err) {
            $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
        })
      }
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if(element.nameEn==$stateParams.belongProject){
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
          $http({
            method: 'GET',
            url: '/config/getWebConfig',
          }).then(function (response) {
              if (response.data.code === 0) {
                  $scope.domain = response.data.domain+"/"+$scope.curProjectNameEn;
                  $scope.uploadToken = response.data.uploadToken;
                  $scope.fileSaveWay = response.data.fileSaveWay;
              }
          }, function (err) {
              $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
          })
        }
      });
    };
    
    $scope.uploadFile = function () {
      let nickName;
      if (/[']/.test($scope.upgrade.version)
      ||/[']/.test($scope.upgrade.content)
      ) {
        $scope.$emit('notification', {type: 'danger', message: '内容包含英文的单引号，不允许提交!'});
        return;
      }
      if(isNaN($scope.upgrade.version)&&($scope.upgrade.type==1||$scope.upgrade.type == 2||$scope.upgrade.type == 6)){
        $scope.$emit('notification', {type: 'danger', message: '版本号必须是数值'});
        return;
      }
      if (!$scope.upgrade.version&&($scope.upgrade.type==1||$scope.upgrade.type == 2||$scope.upgrade.type == 6)) {
        $scope.$emit('notification', {type: 'danger', message: '请填写版本号'});
        return;
      }
      if(!$scope.upgrade.file){
        if(!$scope.upgrade.fileName){
          $scope.$emit('notification', {type: 'danger', message: '请选择上传的文件'});
          return;
        }
      }else{
        if(isChinese($scope.upgrade.file.name)){
          $scope.$emit('notification', {type: 'danger', message: '文件名不能包含中文'});
          return;
        }
        if($scope.upgrade.type==2||$scope.upgrade.type==6){
          var check = new RegExp(".zip$", 'i').test($scope.upgrade.file.name);
          if (!check) {
            $scope.$emit('notification', {type: 'danger', message: '上传文件不是zip格式'});
            return
          }
        }
        if($scope.upgrade.type==1){
          var check = new RegExp(".bin$", 'i').test($scope.upgrade.file.name);
          if (!check) {
            $scope.$emit('notification', {type: 'danger', message: '上传文件不是bin格式'});
            return
          }
        }
        if($scope.upgrade.type==4||$scope.upgrade.type==5){
          var check = new RegExp(".mp4$", 'i').test($scope.upgrade.file.name);
          if (!check) {
            $scope.$emit('notification', {type: 'danger', message: '上传文件不是mp4格式'});
            return
          }
        }
        if($scope.upgrade.type==3){
          var check = new RegExp(".jpg$", 'i').test($scope.upgrade.file.name);
          if (!check) {
            $scope.$emit('notification', {type: 'danger', message: '上传文件不是jpg格式'});
            return
          }
        }
        if($scope.upgrade.type==2){
          if ($scope.upgrade.file.name.indexOf("update_2G") == -1) {
            $scope.$emit('notification', {type: 'danger', message: '文件名必须包含update_2G'});
            return
          }
        }
        if($scope.upgrade.type==6){
          if ($scope.upgrade.file.name.indexOf("update_1G") == -1 ) {
            $scope.$emit('notification', {type: 'danger', message: '文件名必须包含update_1G'});
            return
          }
        }
        nickName = "upgradefile"+$scope.getTime()+"_"+$scope.upgrade.file.name;
        $scope.upgrade.fileName = nickName;
        $scope.upgrade.url = $scope.domain+"/"+nickName;
        $scope.upgrade.file.nickName = nickName;
      }
      

      var data = {
        type: $scope.upgrade.type,
        md5: $scope.upgrade.md5,
        fileName: $scope.upgrade.fileName,
        content: $scope.upgrade.content,
        url: $scope.upgrade.url,
        projectCode: $scope.upgrade.projectCode,
        version:$scope.upgrade.version,
        force: $scope.upgrade.force,
      };
      console.log(data);
      uploadFileService.cancelUpload($scope);
      uploadFileService.uploadFile($scope,$scope.upgrade.projectCode,[$scope.upgrade.file],data,finishUploadCallBack);
    };
    
    function finishUploadCallBack(data,uploadResData){
      console.log(data);
      console.log(uploadResData);
      if(uploadResData&&uploadResData.length==1&&uploadResData[0].md5){
        data.md5 = uploadResData[0].md5;
      }
      $http({
        method: 'POST',
        url: '/web/configAndroid/newUpgradeFile',
        data: data
      }).then(function (response) {
        if (response.data.code === 0) {
            $scope.$emit('notification', {type: 'success', message: '成功，2秒后返回列表'});
            $timeout(function () {
              $state.go('main.configAndroid',{belongProject:$stateParams.belongProject})
            }, 2000)
        } else {
          $('#progress')[0].style.width = '0%';
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })
    }

    $scope.cancelUpload = function () {
      console.log("cancelUpload");
      uploadFileService.cancelUpload($scope);
    }

    $scope.backUrl = function(){
      $state.go('main.configAndroid',{belongProject:$stateParams.belongProject})
    }
    var isChinese = function(temp){
      if(/.*[\u4e00-\u9fa5]+.*$/.test(temp)) {
            return true;
          } 
          return false;
    }
    $scope.getTime = function(){
      return Date.now();
    }
  }
]);