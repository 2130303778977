/**
 * Created by Administrator on 2017/7/12.
 */

'use strict';
angular.module('controllers').controller('addEngineerUserCtr', ['$scope', 'Upload', '$http', '$window','$timeout','$state','$stateParams',
  function ($scope, Upload, $http, $window,$timeout,$state,$stateParams) {

    
    $scope.checkState = function () {
      $scope.projectPower = "all";
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
          if (response.data.code === 0) {
              $scope.belongs = response.data.layoutProject;
              $scope.activitys = response.data.layoutActivity;
              if($stateParams.userId){
                $http({
                  method: 'POST',
                  url: '/engineerApp/findUser',
                  data: {
                    id:$stateParams.userId
                  }
                }).then(function (response) {
                  if (response.data.code == 0) {
                    $scope.engineerUser = response.data.data;
                    if($scope.engineerUser.belongProject&&$scope.engineerUser.belongProject.length>0){
                      $scope.projectPower = "notAll";
                      for (let index = 0; index < $scope.belongs.length; index++) {
                        const element_belongs = $scope.belongs[index];
                        for (let index = 0; index < $scope.engineerUser.belongProject.length; index++) {
                          const element_belongProject = $scope.engineerUser.belongProject[index];
                          if(element_belongs._id==element_belongProject._id){
                            element_belongs.isChoice = true;
                            break;
                          }
                        }
                      }
                    }
                  } else {
                    $scope.$emit('notification', {type: 'danger', message: response.data.msg});
                  }
                }, function (err) {
                  $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
                });
              }else{
                $scope.engineerUser = {
                  bindProjectRight:0,
                  unbindProjectRight:0,
                  setInfoRight:0
                }
              }
          } else {
            $scope.$emit('notification', {type: 'danger', message: response.data.msg});
          }
      }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })
    };
    
    $scope.resetPwd = function(){
      $http({
        method: 'POST',
        url: '/engineerApp/resetPwd',
        data: {
          id:$stateParams.userId,
        }
      }).then(function (result) {
        if (result.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '重置密码成功'});
           
        } else {
          $scope.$emit('notification', {type: 'danger', message: result.data.msg});
        }

      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
      });
    }
    var isChinese = function(temp){
      if(/.*[\u4e00-\u9fa5]+.*$/.test(temp)) {
            return true;
          } 
          return false;
    }
    //提交数据
    $scope.submit = function () {
      
      if (/[']/.test($scope.engineerUser.account)
      ){
        $scope.$emit('notification', {type: 'danger', message: '用户名包含英文的单引号，不允许提交!'});
        return;
      }
      if(isChinese($scope.engineerUser.account)){
        $scope.$emit('notification', {type: 'danger', message: '用户名不能包含中文'});
        return;
      }
      if (!$scope.engineerUser.account) {
        $scope.$emit('notification', {type: 'danger', message: '请填写用户名'});
        return;
      }
      
      $scope.engineerUser.belongProject = [];
      if($scope.projectPower == "notAll"){
        for (let index = 0; index < $scope.belongs.length; index++) {
          const element_belongs = $scope.belongs[index];
          if(element_belongs.isChoice){
            $scope.engineerUser.belongProject.push(element_belongs._id);
          }
          
        }
      }
      $http({
        method: 'POST',
        url: '/engineerApp/uploadUser',
        data: $scope.engineerUser
      }).then(function (result) {
        if (result.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '提交成功,2秒后跳回用户列表'});
            $timeout(function(){
              $state.go("main.engineerUser")
            },3000)
        } else {
          $scope.$emit('notification', {type: 'danger', message: result.data.msg});
        }

      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
      });
     
    };

  }

]);