/**
 * Created by Administrator on 2017/7/12.
 */

'use strict';
angular.module('controllers').controller('upgradeVideoCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state', '$stateParams',
  function ($scope, Upload, $http, $window, $timeout, $state, $stateParams) {
    $scope.layoutProject ={
      programType:1,
      encrypted:0,
      encryption:0,
      curKey:1,
      authFrom:0,
      projectCode: $stateParams.belongProject
    }
    $scope.pageNumber = sessionStorage.getItem("pageNumber");
   
    $scope.checkState = function () {
      if (!$stateParams.belongProject) {
        $scope.$emit('notification', {type: 'danger', message: '非法请求页面'});
        $scope.errorRequest = true;
        return;
      }
      $scope.programId = $stateParams.programId;
      if($stateParams.upgradeId){
        $http({
          method: 'Post',
          url: '/web/program/findVideo',
          data:{data:{_id:$stateParams.upgradeId}},
        }).then(function (response) {
          if (response.data.code === 0) {
            $scope.layoutProject = response.data.data;
          }
        }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
        })
      }
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if (element.nameEn == $stateParams.belongProject) {
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
        }
      });
    };
    $scope.createProject = function(){
      // if ($scope.layoutProject.programType==undefined) {
      //   $scope.$emit('notification', {type: 'danger', message: '请选择节目添加方式'});
      //   return;
      // }
		  if($scope.layoutProject.programType==0){
        if (!isExist($scope.layoutProject.num)&&!isExist($scope.layoutProject.name)) {
          $scope.$emit('notification', { type: 'danger', message: '节目号和名称至少填写一个' });
          return;
        }
        if (isExist($scope.layoutProject.num)&&!isNumber($scope.layoutProject.num)) {
          $scope.$emit('notification', { type: 'danger', message: '节目号只可以是数字,请重新输入' });
          return;
        }
      }
      if($scope.layoutProject.programType==1){
        if (!isNumber($scope.layoutProject.num)) {
          $scope.$emit('notification', { type: 'danger', message: '节目号只可以是数字,请重新输入' });
          return;
        }
        if (!$scope.layoutProject.name) {
          $scope.$emit('notification', {type: 'danger', message: '请填写节目名称'});
          return;
        }
        if (!$scope.layoutProject.sUrl) {
          $scope.$emit('notification', {type: 'danger', message: '请填写播放地址'});
          return;
        }
        if($scope.layoutProject.encrypted){
          if (!isNumber($scope.layoutProject.maxRam)) {
            $scope.$emit('notification', { type: 'danger', message: '最大内存只可以是数字,请重新输入' });
            return;
          }
          if (!$scope.layoutProject.oUrl) {
            $scope.$emit('notification', {type: 'danger', message: '请填写源地址'});
            return;
          }
          if($scope.layoutProject.curKey==1){
            if (!$scope.layoutProject.keyOdd) {
              $scope.$emit('notification', {type: 'danger', message: '请填写奇秘钥'});
              return;
            }
          }else if($scope.layoutProject.curKey==0){
            if (!$scope.layoutProject.keyEven) {
              $scope.$emit('notification', {type: 'danger', message: '请填写偶秘钥'});
              return;
            }
          }
          if ($scope.layoutProject.keyOdd&&!isHexadecimal($scope.layoutProject.keyOdd)) {
            $scope.$emit('notification', {type: 'danger', message: '奇秘钥不符合规范'});
            return;
          }
          if ($scope.layoutProject.keyEven&&!isHexadecimal($scope.layoutProject.keyEven)) {
            $scope.$emit('notification', {type: 'danger', message: '偶秘钥不符合规范'});
            return;
          }
          if($scope.layoutProject.authFrom==0){
            if (!$scope.layoutProject.authStartTime) {
              $scope.$emit('notification', {type: 'danger', message: '请填写节目授权开始时间'});
              return;
            }
            if ($scope.layoutProject.authStartTime&&!isValidDateTimeFormat($scope.layoutProject.authStartTime)) {
              $scope.$emit('notification', {type: 'danger', message: '节目授权开始时间不符合规范'});
              return;
            }
            if (!$scope.layoutProject.authEndTime) {
              $scope.$emit('notification', {type: 'danger', message: '请填写节目授权结束时间'});
              return;
            }
            if ($scope.layoutProject.authEndTime&&!isValidDateTimeFormat($scope.layoutProject.authEndTime)) {
              $scope.$emit('notification', {type: 'danger', message: '节目授权结束时间不符合规范'});
              return;
            }
          }
        }
      }
      if (/[']/.test($scope.layoutProject.name)
        ||/[']/.test($scope.layoutProject.oUrl)
	      ||/[']/.test($scope.layoutProject.sUrl)
      ) {
        $scope.$emit('notification', {type: 'danger', message: '内容包含英文的单引号，不允许提交!'});
        return;
      }
      $http({
        method: 'POST',
        url: '/web/program/createVideo',
        data: {
          data: $scope.layoutProject
        }
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '成功'});
          $timeout(function(){
            $scope.backUrl();
          },500)
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    }
    function isExist(str) {
      return str!=undefined&&str!='';
    }
    function isHexadecimal(str) {
      // Check if the string matches the pattern for 16 hexadecimal characters
      if (!/^[0-9a-f]{16}$/i.test(str)) {
        return false;
      }
    
      // Check if the string contains at least one letter
      return /[a-f]/i.test(str);
    }
    $scope.createKeyOdd = function(){
      $scope.layoutProject.keyOdd = generateHex16();
    }
    $scope.createKeyEven = function(){
      $scope.layoutProject.keyEven = generateHex16();
    }
    function generateHex16() {
      let result = '';
      const characters = "0123456789abcdef";
      const letters = "abcdef"; // Define a string containing only letters
      const charactersLength = characters.length;
      const lettersLength = letters.length;
    
      // Generate 15 characters randomly
      for (let i = 0; i < 15; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
    
      // Add one random letter
      result += letters.charAt(Math.floor(Math.random() * lettersLength));
    
      // Shuffle the result string to ensure randomness
      result = shuffleString(result);
    
      return result;
    }
    function shuffleString(str) {
      const array = str.split('');
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array.join('');
    }
    var isNumber = function (temp) {
      if ((/^\d{1,10}$/.test(temp))) {
        return true;
      }
      return false;
    }
    function isValidDateTimeFormat(dateTimeString) {
      // Regular expression for YYYY-MM-DD HH:mm format
      var dateTimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;
      
      return dateTimeRegex.test(dateTimeString);
    } 
    $scope.backUrl = function(){
      $state.go('main.upgradeAllProgram',{belongProject: $stateParams.belongProject,
        programId: $stateParams.programId});
    }
    var isChinese = function(temp){
      if(/.*[\u4e00-\u9fa5]+.*$/.test(temp)) {
        return true;
      }
      return false;
    }
    $scope.getTime = function(){
      return Date.now();
    }
  }
]);