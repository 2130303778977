/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';

angular.module('controllers').controller('configAdTimeCardCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state','$location','$stateParams',
  function ($scope, Upload, $http, $window, $timeout, $state,$location,$stateParams) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");
    $scope.upgrade = {showName: '', id: '', version: '', newVersion: ''};
    var loadTable = function (data, reload) {
      if (reload) {
        $('#table2').bootstrapTable('load', data);
      } else {
        $('#table2').bootstrapTable({
          pagination: true,
          pageSize: 10, //每页显示10条
          cache: false,
          search: true,
          showColumns: 'true',
          showExport: true,//显示导出按钮
          exportDataType: 'all',//导出类型
          columns: [{
            title: 'Index<br>(越小优先级越高)',
            align: 'center',
            formatter: function (value, row, index) {
              return index
            }
          },{
            title: '有效时间',
            align: 'center',
            width:'200px',
            formatter: function (value, row, index) {
              if(row.validTimeAttr&&row.validTime=='1'){
                return moment(row.validTimeAttr.start).format('YYYY-MM-DD')
                +" ~ "+moment(row.validTimeAttr.end).format('YYYY-MM-DD');
              }else{
                return "永久"
              }
            }
          },{
            title: '重复',
            align: 'center',
            width:'80px',
            formatter: function (value, row, index) {
              if(row.repeatAttr){
                let resultStr='';
                if(row.repeatAttr.monday){
                  resultStr += "星期一<br>"
                }
                if(row.repeatAttr.tuesday){
                  resultStr += "星期二<br>"
                }
                if(row.repeatAttr.wednesday){
                  resultStr += "星期三<br>"
                }
                if(row.repeatAttr.thursday){
                  resultStr += "星期四<br>"
                }
                if(row.repeatAttr.friday){
                  resultStr += "星期五<br>"
                }
                if(row.repeatAttr.saturday){
                  resultStr += "星期六<br>"
                }
                if(row.repeatAttr.sunday){
                  resultStr += "星期日"
                }
                return resultStr;
              }else{
                return "-"
              }
            }
          },{
            title: '时段',
            align: 'center',
            width:'100px',
            formatter: function (value, row, index) {
              let resultStr='';
              for (let index = 0; index < row.timeInDays.length; index++) {
                let element = row.timeInDays[index];
                resultStr+=timeFormat(element.start)+"~"+timeFormat(element.end)+"<br>";
              }
              return resultStr;
            }
         
          }, {
            title: '广告内容',
            align: 'center',
            field: 'url',
            width:'100px',
            formatter: function (value, row, index) {
              let result='';
              if(row.cardType=="card"&&row.isShowBgPic&&row.bgPic){
                result = row.bgPic.picUrl;
              }else if(row.cardType=="card"&&row.isShowTitle&&row.title){
                result = row.title.name;
              }else if(row.cardType=="videoView"&&row.videoAttr&&row.videoAttr.files&&row.videoAttr.files.length>0){
                result = row.videoAttr.files[0].url;
              }else if(row.cardType=="bannerView"&&row.bannerAttr&&row.bannerAttr.files&&row.bannerAttr.files.length>0){
                for (let index = 0; index < row.bannerAttr.files.length; index++) {
                  let element = row.bannerAttr.files[index];
                  result += element.fileName+'<br>'
                }
              }
              return result==''?"无":result;
            }
          }, {
            title: '备注',
            align: 'center',
            field: 'remark',
            width: '80px',
          }, {
            title: '是否禁用',
            align: 'center',
            field: 'forbidden',
            formatter: function (value, row, index) {
              return value=='1'?'<span style="color:red">禁用</span>':'<span style="color:green">启用</span>';
            }
          }, {
            title: '更新日期',
            align: 'center',
            field: 'updatedAt',
            formatter: function (value, row, index) {
              return moment(value).format('YYYY-MM-DD HH:mm')
            }
          },{
            title: '操作',
            align: 'center',
            width:'200px',
            formatter: function (value, row, index) {
              row = angular.toJson(row);
              return '<button  class=\'btn btn-success btn-xs\' onclick=\'angular.element(this).scope().toUploadPage(' + row + ')\'>更新</button>'
                  +'&nbsp;&nbsp;<button  class=\'btn btn-danger btn-xs\' onclick=\'angular.element(this).scope().showDeleteModal(' + row + ','+index+')\'>删除</button>'
                  +'&nbsp;&nbsp;<button  class=\'btn btn-warning btn-xs\' onclick=\'angular.element(this).scope().changePos(' + row + ',"up")\'>上移</button>'
                        +'&nbsp;&nbsp;<button  class=\'btn btn-warning btn-xs\' onclick=\'angular.element(this).scope().changePos(' + row + ',"down")\'>下移</button>';
            }
          }],
          data: data
        });
      }
    };
    $scope.changePos = function(data,posOption){
      $http({
        method: 'POST',
        url: '/web/layoutPageCardAdTime/changePos',
        data:{belongConfigId:$stateParams.configId,_id:data._id,posOption:posOption}
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '操作成功'});
          $scope.upgradeInit(true);
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    }
    $scope.showDeleteModal = function (data,index) {
      $scope.toDelData =data;
      $scope.toDelDataIndex = index;
      console.log(data);
      $timeout(function () {
        $('#deleteItemWin').modal('show')
      }, 10);
    };
    $scope.toDelete = function(){
      $http({
        method: 'POST',
        url: '/web/layoutPageCardAdTime/deleteOne',
        data:$scope.toDelData
      }).then(function (response) {
        $timeout(function () {
          $('#deleteItemWin').modal('hide')
        }, 10);
        if (response.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '删除成功'});
          $scope.upgradeInit(true);
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    }

    $scope.upgradeInit = function (reload) {
      $scope.configId = $stateParams.configId;
      if (!$stateParams.belongProject) {
        $scope.$emit('notification', {type: 'danger', message: '非法请求页面'});
        $scope.errorRequest = true;
        return;
      }
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if(element.nameEn==$stateParams.belongProject){
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
        }
      });
      $http({
        method: 'Post',
        url: '/web/layoutPageCardAd/findOne',
        data:{data:{_id:$stateParams.configId}},
      }).then(function (response) {
          if (response.data.code === 0) {
            $scope.configAdName = "广告名称："+response.data.data.name;
            console.log(response.data.data.layoutPageCardAdTimes)
            loadTable(response.data.data.layoutPageCardAdTimes, reload)
          }
      }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })
    };
    //$scope.configAd={};

    $scope.toUploadPage = function (data) {
      $state.go('main.upgradeLayoutPageCardAdTime', {upgradeId: data._id, belongConfigId: $stateParams.configId, belongProject:$stateParams.belongProject})
    };
    $scope.createItem = function (data) {
      $state.go('main.upgradeLayoutPageCardAdTime', {belongConfigId: $stateParams.configId, belongProject:$stateParams.belongProject})
    };
    function timeFormat(str) {
      str = str+'';
      if(str.length<=1){
          str='0'+str;
      }
      str = str+":00"
      return str
    }

  }
])
;