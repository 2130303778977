
'use strict';
angular.module('controllers').controller('layoutGatewayCtr', ['$scope', 'Upload', '$http', '$window', '$timeout','$stateParams',
  function ($scope, Upload, $http, $window, $timeout,$stateParams) {

    $scope.device = {
      uuid: ''
    };
    $scope.selectedRoom = {
      roomNum:"",
      familyId:""
    }
    $scope.gateway = {
      uuid: '',
      userSn:'',
    };

    $scope.pageNumber = sessionStorage.getItem("pageNumber");
    


    var loadTable = function (reload) {
      if(reload){
        $('#table2').bootstrapTable('refresh');
        return;
      }
        $('#table2').bootstrapTable({
           method : 'get',
          url : '/layoutGateway/getAll',//请求路径
          striped : true, //是否显示行间隔色
          pagination : true,//是否分页
          sidePagination : 'server',//server:服务器端分页|client：前端分页
          pageSize: 10, //每页显示10条
          cache: false,
          search: true,
          showColumns: 'true',
          showExport: true,//显示导出按钮
          exportDataType: 'all',//导出类型
          queryParams : function(params) {//上传服务器的参数
            var temp = {//如果是在服务器端实现分页，limit、offset这两个参数是必须的
              limit : params.limit, // 每页显示数量
              //offset : params.offset, // SQL语句起始索引
              page : (params.offset / params.limit) + 1, //当前页码 
              searchName : $('.search input').val(),
              order : params.order, 
              sort : params.sort,
              projectNameEn:$stateParams.projectNameEn
            };
            return temp;
          },
          columns: [{
            title: 'UUID(网关MAC)',
            align: 'center',
            field: 'uuid'
          },{
            title: '房间号',
            align: 'center',
            sortable: true,
            field: 'userSn',
          },{
            title: '家庭Id',
            align: 'center',
            sortable: true,
            field: 'familyId',
          },{
            title: '软件版本',
            align: 'center',
            sortable: true,
            field: 'softVersion',
          },{
            title: '操作员',
            align: 'center',
            sortable: true,
            field: 'engineer',
          },{
            title: '修改时间',
            align: 'center',
            sortable: true,
            field: 'updateTime',
            formatter: function (value, row, index) {
              return moment(value, 'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm')
            }
          },{
              title: '操作',
              align: 'center',
              formatter: function (value, row, index) {
                return '<button  class=\'btn btn-danger btn-xs\' onclick=\'angular.element(this).scope().delDevice("' + row.uuid + '")\'>删除</button>';
              }
            }
          ]
        });
    };

    $scope.roomList=[]
    $scope.deviceInit = function (reload) {
      if (!$stateParams.projectNameEn) {
        $scope.$emit('notification', {type: 'danger', message: '非法请求页面'});
        $scope.errorRequest = true;
        return;
      }
      $http({
        method: 'GET',
        url: '/web/hotel/getHotelRoomList?belongProject='+$stateParams.projectNameEn,
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.roomList = response.data.data;
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if(element.nameEn==$stateParams.projectNameEn){
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
        }
      });
      loadTable()
    };

    $scope.delDevice = function (uuid) {
      $scope.device.uuid = uuid;
      $timeout(function () {
        $('#resetModal').modal('show')
      }, 10);

    };

    $scope.addDevice = function () {
      if(isChinese($scope.gateway.userSn)){
        $scope.$emit('notification', {type: 'danger', message: '房间号不能有中文'});
        return;
      }
      if(!$scope.gateway.uuid){
        $scope.$emit('notification', {type: 'danger', message: '请输入UUID'});
        return;
      }
      if (/[']/.test($scope.gateway.uuid)) {
        $scope.$emit('notification', {type: 'danger', message: '内容包含英文的单引号，不允许提交!'});
        return;
      }
      $http({
        method: 'POST',
        url: '/layoutGateway/addGateway',
        data: {
          uuid: $scope.gateway.uuid,
          belongProject:$scope.curProjectNameEn,
          userSn:$scope.gateway.userSn,
          belongProjectCn:$scope.curProjectName
        }
      }).then(function (response) {
        $('#resetModal').modal('hide');
        if (response.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '添加成功'});
          $scope.gateway.uuid = '';
          $scope.gateway.userSn = '';
          loadTable(true);
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });

    };

    $scope.toDoReset = function () {
      $http({
        method: 'POST',
        url: '/layoutGateway/delGateway',
        data: {uuid: $scope.device.uuid}
      }).then(function (response) {
        $('#resetModal').modal('hide');
        if (response.data.code == 0) {
          loadTable(true);
          $scope.$emit('notification', {type: 'success', message: '删除成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    }

    $scope.uploadFiles = function (file) {
      if (!file[0]) {
        return;
      }
      Upload.upload({
        url: '/layoutGateway/addGatewayByFile',
         method: 'POST',
         data: { projectNameEn:$stateParams.projectNameEn },
          file: file
      }).then(function (resp) {
        if (resp.data.code != 0) {
          $scope.$emit('notification', {type: 'danger', message: resp.data.msg});
        } else {
          loadTable(true);
          $scope.$emit('notification', {type: 'success', message: '成功导入/更新' + resp.data.count + '条数据'});
        }
      }, function (resp) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };
    $scope.pullFile = function(){
      $http({
        method: 'Get',
        url: '/layoutGateway/getTemplateFile',
        responseType: 'arraybuffer' // 设置响应类型为 arraybuffer
      }).then(function(response) {
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const objectUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objectUrl;
          link.download = '网关管理模板.xlsx';
          link.click();
          URL.revokeObjectURL(objectUrl);
      }, function(err) {
          $scope.$emit('notification', { type: 'danger', message: '网络错误，与服务器通信错误' });
      });
    }
    var isChinese = function(temp){
      if(/.*[\u4e00-\u9fa5]+.*$/.test(temp)) {
            return true;
          } 
          return false;
    }

  }
]);