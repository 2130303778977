/**
 * Created by Administrator on 2017/7/12.
 */

'use strict';
angular.module('controllers').controller('setPwdCtr', ['$scope', 'Upload', '$http', '$window','$timeout','$state','$stateParams','$location',
  function ($scope, Upload, $http, $window,$timeout,$state,$stateParams,$location) {
    $scope.checkState = function () {
      $http({
        method: 'GET',
        url: '/web/user/getUser',
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.userTmp = response.data.user;
          console.log($scope.user);
          $scope.user = {
            id:$scope.userTmp._id,
            name: $scope.userTmp.name||'',
            pwd: '',
            layoutPageManage:$scope.userTmp.layoutPageManage||0,
            userManage:$scope.userTmp.userManage||0,
            adUpload: $scope.userTmp.adUpload||0,
            wifiUpload: $scope.userTmp.wifiUpload||0,
            androidUpload: $scope.userTmp.androidUpload||0,
          };
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
      

    };

    //提交数据
    $scope.submit = function () {

      if (!$scope.user.pwd) {
        $scope.$emit('notification', {type: 'danger', message: '请填写密码'});
        return;
      }
      var regex = new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,30}');
      if (!regex.test($scope.user.pwd)) {
        $scope.$emit('notification', {type: 'danger', message: '密码复杂度太低（密码中必须包含大小字母、数字、特殊字符）'});
        return;
      }
      if ($scope.user.pwd.indexOf("'") != -1 || $scope.user.pwd.indexOf("\"") != -1|| $scope.user.pwd.indexOf("\\") != -1) {
        $scope.$emit('notification', {type: 'danger', message: '不能使用英文单双引号或反斜杠(\\)'});
        return;
      }

      if ($scope.user.pwd=='HNyx@123456') {
        $scope.$emit('notification', {type: 'danger', message: '不能使用默认密码'});
        return;
      }

      if($scope.user.userManage==1){
        $scope.user.adUpload = 1;
        $scope.user.wifiUpload = 1;
        $scope.user.androidUpload = 1;
        $scope.user.layoutPageManage = 1;
      }
      $http({
        method: 'POST',
        url: '/web/user/resetPwd',
        data: $scope.user
      }).then(function (result) {
        if (result.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '提交成功,2秒后跳转'});
            $timeout(function(){
              $state.go("main");
            },2000)
        } else {
          $scope.$emit('notification', {type: 'danger', message: result.data.msg});
        }

      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
      });
     
    };

  }

]);