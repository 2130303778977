/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('hotelDeviceCtrlManageCtr', ['$scope', 'Upload', '$http', '$window', '$timeout','$state','$stateParams',
  function ($scope, Upload, $http, $window, $timeout,$state,$stateParams) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");
    $scope.devList = []
    $scope.devicesDescribeList=[];
   

    $scope.plugInit = function (reload) {
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if(element.nameEn==$stateParams.belongProject){
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
        }
      });
      $http({
        method: 'GET',
        url: '/web/hotel/getDevDescription',
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.devicesDescribeList = response.data.data.device_name_list;
          $http({
            method: 'GET',
            url: '/web/hotel/getHotelRoom?roomId='+$stateParams.roomId,
          }).then(function (response) {
            if (response.data.code == 0) {
              $http({
                method: 'POST',
                url: '/web/hotel/getHotelDevList',
                data: {
                  account:response.data.data.lmAccount
                }
              }).then(function (response) {
                if (response.data.code == 0) {
                  $scope.rowDevList = response.data.data;
                  $scope.showDev();
                } else {
                  $scope.$emit('notification', {type: 'danger', message: response.data.msg});
                }
              }, function (err) {
                $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
              });
            } else {
              $scope.$emit('notification', {type: 'danger', message: response.data.msg});
            }
          }, function (err) {
            $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
          });
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

    $scope.searchTxt = "";
    $scope.doSearch = function () {
      $scope.showDev(1,$scope.searchTxt);
    };
    $scope.showDev = function(type,devName){
      $scope.devList = [];
      for (let index = 0; index < $scope.rowDevList.length; index++) {
        const element_rowDev = $scope.rowDevList[index];
        if(devName){
          if(String(element_rowDev.alias).indexOf(devName)==-1){
            continue;
          }
        }
        if(findTypeInDevList($scope.devList,getDevType(element_rowDev)))
        {
          addDevInSameDevTypeList($scope.devList,getDevType(element_rowDev),element_rowDev);
        }else{
          if(type==2&&getDevType(element_rowDev)!="开关/面板")continue;
          if(type==3&&getDevType(element_rowDev)!="插座")continue;
          if(type==4&&(getDevType(element_rowDev)=="开关/面板"||getDevType(element_rowDev)=="插座"))continue;
          $scope.devType = {
            devType:getDevType(element_rowDev),
            devTypeList:[element_rowDev]
          };
          $scope.devList.push($scope.devType);
        }
      }
    }
    var findTypeInDevList = function(list ,devType){
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        if(element.devType==devType){
          return true;
        }
      }
      return false;
    }
    var addDevInSameDevTypeList = function(list ,devType ,dev){
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        if(element.devType==devType){
          element.devTypeList.push(dev);
          return;
        }
      }
    } 

    var getDevType = function(dev){
      for (let index = 0; index < $scope.devicesDescribeList.length; index++) {
        const element = $scope.devicesDescribeList[index];
        if(element.device_name==dev.device_name){
          return element.device_type;
        }
      }
      return "";
    }
    $scope.updatePlug = function (data) {
      $state.go('main.upgradeConfigPlug', {upgradeId:data._id,belongProject:$stateParams.belongProject})
    };

    $scope.doResetPwd = function () {
      $http({
        method: 'PUT',
        url: '/web/account/resetPwd',
        data: {
          phone: $scope.user.phone
        }
      }).then(function (response) {
        $timeout(function () {
          $('#resetModal').modal('hide')
        }, 10);

        if (response.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '重置成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };
   
    $scope.delPlugModal = function (data) {
      $scope.plug.name = data.name;
      $scope.plug.id = data._id;
      $timeout(function () {
        $('#delModal').modal('show')
      }, 10);
    };

    $scope.showCmdModel = function(cmd){
      $scope.plug.install_cmd = cmd;
      $timeout(function () {
        $('#cmdModal').modal('show')
      }, 10);
    };

    $scope.showForbiddenModal = function (data) {
      $scope.plug.id = data._id;
      $scope.plug.showName = "插件名称："+data.name;
      $scope.plug.newForbidden = '0';
      $scope.plug.forbidden = data.forbidden=='1'?'1':'0';

      $timeout(function () {
        $('#forbiddenModal').modal('show')
      }, 10);
    };
    $scope.doUpdateForbidden = function () {
      $http({
        method: 'POST',
        url: '/web/configPlug/changeForbidden',
        data: {
          id: $scope.plug.id,
          forbidden:$scope.plug.newForbidden,
          projectCode:$stateParams.belongProject,
        }
      }).then(function (response) {
        $timeout(function () {
          $('#forbiddenModal').modal('hide')
        }, 10);

        if (response.data.code == 0) {
          $scope.plugInit(true);
          $scope.$emit('notification', {type: 'success', message: '修改成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };
    $scope.checkIsOpen = function(endpoints) {
      for (let index = 0; index < endpoints.length; index++) {
        const element = endpoints[index];
        if(element.state==1){
          return true;
        }
      }
      return false;
    }

    $scope.onclickSwitch = function(dev) {
      // $http({
      //   method: 'POST',
      //   url: '/web/hotel/ctrlSmartDev',
      //   data: {
      //     dev:dev
      //   }
      // }).then(function (response) {
      //   if (response.data.code == 0) {
      //     $timeout(function () {
      //       $scope.plugInit();
      //     }, 300);
      //   }
      // }, function (err) {
      //   $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      // });
      $scope.$emit('notification', {type: 'danger', message: '暂不支持控制'});
    }
    $scope.doDelPlug = function () {
      $http({
        method: 'DELETE',
        url: '/web/configPlug/delPlug',
        params: {
          id: $scope.plug.id
        }
      }).then(function (response) {
        $timeout(function () {
          $('#delModal').modal('hide')
        }, 10);

        if (response.data.code == 0) {
          $scope.plugInit(true);
          $scope.$emit('notification', {type: 'success', message: '删除成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

  }
]);