/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('programGroupCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state',
  function ($scope, Upload, $http, $window, $timeout, $state) {

    var loadTable = function (reload) {
      if(reload){
        $('#table2').bootstrapTable('refresh');
        return;
      }
      $('#table2').bootstrapTable({
          method : 'get',
          url : "/web/operLog/getAll",//请求路径
          striped : true, //是否显示行间隔色
          pagination : true,//是否分页
          sidePagination : 'server',//server:服务器端分页|client：前端分页
          pageSize : 10,//单页记录数
          pageList : [ 5, 10, 20, 30 ],//可选择单页记录数
          showRefresh : true,//刷新按钮
          cache: false,
          search: true,
          showColumns: 'true',
          showExport: true,//显示导出按钮
          exportDataType: 'all',//导出类型
          queryParams : function(params) {//上传服务器的参数
            var temp = {//如果是在服务器端实现分页，limit、offset这两个参数是必须的
              limit : params.limit, // 每页显示数量
              //offset : params.offset, // SQL语句起始索引
              page : (params.offset / params.limit) + 1, //当前页码 
              searchName : $('.search input').val(),
              order : params.order, 
              sort : params.sort,
            };
            return temp;
          },
          columns: [{
            title: '操作用户',
            align: 'center',
            field: 'name',
          }, {
            title: '操作内容',
            align: 'left',
            field: 'oper',
          }, {
            title: '操作时间',
            align: 'center',
            sortable: true,
            field: 'updateTime',
            formatter: function (value, row, index) {
              return moment(value, 'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm')
            }
          }]
        });
    }

    $scope.upgradeInit = function (reload) {
      loadTable()
    };
  }
])
;