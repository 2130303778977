/**
 *
 * 主页
 * 主要业务：
 *      1.选择订单
 *      2.选择维修项目
 * Created by Administrator on 2017/5/16.
 *
 */

angular.module('controllers').controller('loginCtr', ['$scope', '$state', '$http',
  function ($scope, $state, $http) {

    $scope.user = {
      name: '',
      pwd: ''
    };

    $scope.doLoginIn = function () {
      if (!$scope.user.name || !$scope.user.pwd) {
        $scope.promptMsg = '请输入用户名与密码';
      } else {
        $http({
          method: 'POST',
          url: '/web/user/loginIn',
          data: $scope.user
        }).then(function (response) {
          if (response.data.code == 0) {
            if(!response.data.toChangePwd){
              $state.go('main');
            }else{
              $state.go('main.setPwd');
            }
          } else {
            $scope.promptMsg = response.data.msg;
          }
        }, function (err) {
          $scope.promptMsg = '网络错误,登录失败';
        });
      }
    };

  }
]);
