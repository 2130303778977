/**
 * Created by Administrator on 2017/7/12.
 */

'use strict';


angular.module('controllers').controller('upgradeConfigAdShadowCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state', '$stateParams','uploadFileService',
  function ($scope, Upload, $http, $window, $timeout, $state, $stateParams,uploadFileService) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");
    //粗略检测是否非法进入此页面
    $scope.checkState = function () {
      if (!$stateParams.upgradeId||!$stateParams.belongProject) {
        $scope.$emit('notification', {type: 'danger', message: '非法请求页面'});
        $scope.errorRequest = true;
        return;
      }
      $scope.myTimes=[];
      for (let index = 0; index < 25; index++) {
        let element = {
          name:"",
          value:index,
        };
        if(index>=10){
          element.name=index+":00"
        }else{
          element.name="0"+index+":00"
        }
        $scope.myTimes.push(element);
      }
      $http({
        method: 'Post',
        url: '/web/configAd/findOneAdShadow',
        data:{data:{_id:$stateParams.upgradeId}},
      }).then(function (response) {
          if (response.data.code === 0) {
            $scope.upgrade = response.data.data;
            
            $scope.upgrade.attr = angular.fromJson($scope.upgrade.attr);
            if($scope.upgrade.timeText&&$scope.upgrade.timeText.attr){
              $scope.upgrade.timeText.attr = angular.fromJson($scope.upgrade.timeText.attr);
            }
            
            if($scope.upgrade.validTime==1){
              $scope.upgrade.validTimeAttr.start = new Date($scope.upgrade.validTimeAttr.start);  
              $scope.upgrade.validTimeAttr.end = new Date($scope.upgrade.validTimeAttr.end);  
            }
          }
      }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if(element.nameEn==$stateParams.belongProject){
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
          $http({
            method: 'GET',
            url: '/config/getWebConfig',
          }).then(function (response) {
              if (response.data.code === 0) {
                  $scope.domain = response.data.domain+"/"+$scope.curProjectNameEn;
                  $scope.uploadToken = response.data.uploadToken;
                  $scope.fileSaveWay = response.data.fileSaveWay;
              }
          }, function (err) {
              $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
          })
        }
      });
    };
    function timeAdd0(str) {
      str = str+'';
      if(str.length<=1){
          str='0'+str;
      }
      console.log(str);
      return str
    }
    $scope.uploadFile = function () {
      if (!isNumber($scope.upgrade.timeSum)) {
        $scope.$emit('notification', {type: 'danger', message: "计时时长格式错误,应为：0-9999"});
        return;
      }
      if (/[']/.test($scope.upgrade.timeText.name)
      ) {
        $scope.$emit('notification', {type: 'danger', message: '文字内容包含英文的单引号，不允许提交!'});
        return;
      }
      if (!isSize($scope.upgrade.timeText.attr.size)) {
        $scope.$emit('notification', {type: 'danger', message: "文字大小格式错误，应为：0-9999px"});
        return;
      }

      if ($scope.upgrade.timeText.attr.frameWidth&&!isSize($scope.upgrade.timeText.attr.frameWidth)) {
        $scope.$emit('notification', {type: 'danger', message: "文字宽度格式错误，应为：0-9999px"});
        return;
      }
      if ($scope.upgrade.timeText.attr.frameHeight&&!isSize($scope.upgrade.timeText.attr.frameHeight)) {
        $scope.$emit('notification', {type: 'danger', message: "文字高度格式错误，应为：0-9999px"});
        return;
      }
      
      if (!isColor($scope.upgrade.timeText.attr.color)) {
        $scope.$emit('notification', {type: 'danger', message: "文字颜色格式错误，应为：#000|#000000|#00000000"});
        return;
      }
      if (!isColor($scope.upgrade.timeText.attr.frameColor)) {
        $scope.$emit('notification', {type: 'danger', message: "文字背景颜色格式错误，应为：#000|#000000|#00000000"});
        return;
      }
      if (!isMargin($scope.upgrade.timeText.attr.margin)) {
        $scope.$emit('notification', {type: 'danger', message: "文字边距格式错误,应为：0-9999px 0-9999px 0-9999px 0-9999px"});
        return;
      }
      
      if(!$scope.upgrade.file){
        if(!$scope.upgrade.fileName){
          $scope.$emit('notification', {type: 'danger', message: '请上传文件'});
          return;
        }
      }else{
        let nickName = "upgradefile" + $scope.getTime() + "_" + getFileType($scope.upgrade.file.name);
        $scope.upgrade.fileName = nickName;
        $scope.upgrade.url = $scope.domain+"/"+nickName;
        $scope.upgrade.file.nickName = nickName;
      }
      var check = new RegExp(".mp4$", 'i').test($scope.upgrade.fileName.toLowerCase());
      var check2 = new RegExp(".jpg$", 'i').test($scope.upgrade.fileName.toLowerCase());
      if($scope.upgrade.type=="0"&&!check){
        $scope.$emit('notification', {type: 'danger', message: '上传文件不是mp4格式'});
        return
      }
      if($scope.upgrade.type=="1"&&!check2){
        $scope.$emit('notification', {type: 'danger', message: '上传文件不是jpg格式'});
        return
      }
      var data = {
        _id:$scope.upgrade._id,
        projectCode: $scope.upgrade.projectCode,
        type: $scope.upgrade.type,
        md5: $scope.upgrade.md5,
        fileName: $scope.upgrade.fileName,
        url: $scope.upgrade.url,
        remark: $scope.upgrade.remark,
        forbidden:$scope.upgrade.forbidden,
        autoSkipWhenAuth: $scope.upgrade.autoSkipWhenAuth,//鉴权成功直接进入
        autoSkipWhenNet: $scope.upgrade.autoSkipWhenNet,//网络连通直接进入
        autoSkipWhenClickHome: $scope.upgrade.autoSkipWhenClickHome,//按主页键跳过
        clockType: $scope.upgrade.clockType,//计时方式 0:不启用计时，1：正计时，2：倒计时
        timeSum:$scope.upgrade.timeSum,//视频时长
        timeText:{
          name:$scope.upgrade.timeText.name,
          attr: isJson($scope.upgrade.timeText.attr)?$scope.upgrade.timeText.attr:angular.toJson($scope.upgrade.timeText.attr),
        }
      };
      uploadFileService.cancelUpload($scope);
      uploadFileService.uploadFile($scope,$scope.upgrade.projectCode,[$scope.upgrade.file],data,finishUploadCallBack);
    };
    
    function finishUploadCallBack(data,uploadResData){
      console.log(data);
      console.log(uploadResData);
      if(uploadResData&&uploadResData.length==1&&uploadResData[0].md5){
        data.md5 = uploadResData[0].md5;
      }
      $http({
        method: 'POST',
        url: '/web/configAd/newUpgradeFileAdShadow',
        data: data
      }).then(function (response) {
        if (response.data.code === 0) {
            $scope.$emit('notification', {type: 'success', message: '成功，2秒后返回列表'});
            $timeout(function () {
              $state.go('main.configAd',{belongProject:$stateParams.belongProject})
            }, 2000)
        } else {
          $('#progress')[0].style.width = '0%';
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })
    }
    //正则判断margin属性
    var isMargin = function (temp) {
      if (/^([0-9]{1,4}px [0-9]{1,4}px [0-9]{1,4}px [0-9]{1,4}px)$/.test(temp)) {
        return true;
      } else {
        return false;
      }
    }
    //正则判断是否为数字
    var isNumber = function (temp) {
      if ((/^\d{1,10}$/.test(temp))) {
        return true;
      }
      return false;
    }
    let isJson = function(str) {
      if(!str)return false;
      if (/[']/.test(str))return false;
      if (str==1)return false;
      if (typeof str == 'string') {
          try {
              JSON.parse(str);
              return true;
          } catch(e) {
              return false;
          }
      }
    }
    //正则判断size属性
    var isSize = function (temp) {
      if (/^([0-9]{1,4})px$/.test(temp)) {
        return true;
      } else {
        return false;
      }
    }
    var isColor = function (temp) {
      if (/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{8})$/.test(temp)) {
        return true;
      } else {
        return false;
      }
    }
    $scope.addTimeInDay = function(){
      $scope.upgrade.timeInDays.push({
        start:0,
        end:24
      });
    }
    $scope.delTimeInDay = function(index){
      $scope.upgrade.timeInDays.splice(index,1);
    }
    $scope.cancelUpload = function () {
      uploadFileService.cancelUpload($scope);
    }
    $scope.addUuid = function(){
      if(!$scope.upgrade.uuid_list){
        $scope.upgrade.uuid_list = [];
      }
      $scope.upgrade.uuid_list.push("");
    }
    $scope.delUuid = function(index){
      $scope.upgrade.uuid_list.splice(index,1);
    }

    $scope.backUrl = function(){
      $state.go('main.configAd',{belongProject:$stateParams.belongProject})
    }
    var isChinese = function(temp){
      if(/.*[\u4e00-\u9fa5]+.*$/.test(temp)) {
            return true;
          } 
          return false;
    }
    $scope.getTime = function(){
      return Date.now();
    }
    var getFileType = function(name){
      return name.substring(name.lastIndexOf("."));
    }
  }
]);