/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('acquiredDataCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state',
  function ($scope, Upload, $http, $window, $timeout, $state) {

    var loadTable = function () {
      $('#table2').bootstrapTable({
        method : 'get',
        url : "/acquiredData/getData",//请求路径
        striped : true, //是否显示行间隔色
        pagination : true,//是否分页
        sidePagination : 'server',//server:服务器端分页|client：前端分页
        pageSize : 10,//单页记录数
        pageList : [ 5, 10, 20, 30 ],//可选择单页记录数
        showRefresh : true,//刷新按钮
        cache: false,
        search: true,
        showColumns: 'true',
        showExport: true,//显示导出按钮
        exportDataType: 'all',//导出类型
        queryParams : function(params) {//上传服务器的参数
          var temp = {//如果是在服务器端实现分页，limit、offset这两个参数是必须的
            limit : params.limit, // 每页显示数量
            //offset : params.offset, // SQL语句起始索引
            page : (params.offset / params.limit) + 1, //当前页码 
            searchName : $('.search input').val(),
          };
          return temp;
        },
          
        columns: [{
          title: '设备MAC',
          sortable: true,
          align: 'center',
          field: 'id',
        },{
          title: '消息产生时间',
          sortable: true,
          align: 'center',
          field: 'time',
          formatter: function (value, row, index) {
            return moment(Number(value)).format('YYYY-MM-DD HH:mm:ss')
          }
        }, {
          title: '事件',
          sortable: true,
          align: 'center',
          field: 'event',
        }, {
          title: '数据',
          sortable: true,
          align: 'center',
          field: 'paras',
        }, {
          title: '原始数据',
          sortable: true,
          align: 'center',
          field: 'raw',
        }, {
          title: '上报时间',
          sortable: true,
          align: 'center',
          field: 'created_at',
        }]
      });
    }

    $scope.upgradeInit = function (reload) {
      loadTable()
    };
  }
])
;