/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('upgradeApprovalProcessCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state','$stateParams',
  function ($scope, Upload, $http, $window, $timeout, $state,$stateParams) {
    
    $scope.init = function (reload) {
      $scope.approvalProcess = {
        projectCode:$stateParams.belongProject,
        sum:""
      }
      showProjectName();
    };
    var showProjectName = function() {
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if(element.nameEn==$stateParams.belongProject){
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
        }
      });
    }
    $scope.backUrl = function(){
      $state.go('main.approvalProcess',{belongProject:$stateParams.belongProject})
    }

    $scope.create = function () {
      if (/[']/.test($scope.approvalProcess.sum)
      ) {
        $scope.$emit('notification', {type: 'danger', message: '内容包含英文的单引号，不允许提交!'});
        return;
      }
      if (!$scope.approvalProcess.sum) {
        $scope.$emit('notification', {type: 'danger', message: '请简要概述页面修改的内容'});
        return;
      }
      $http({
        method: 'POST',
        url: '/web/approvalProcess/createApprovalProcess',
        data: $scope.approvalProcess
      }).then(function (response) {
        if (response.data.code === 0) {
            $scope.$emit('notification', {type: 'success', message: '成功，2秒后返回列表'});
            $timeout(function () {
              $state.go('main.approvalProcess',{belongProject:$stateParams.belongProject})
            }, 2000)
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })
    }

  }
])
;