/**
 * Created by Administrator on 2017/7/12.
 */

'use strict';
angular.module('controllers').controller('upgradeConfigApprovalCtr', ['$scope', 'Upload', '$http', '$window','$timeout','$state','$stateParams',
  function ($scope, Upload, $http, $window,$timeout,$state,$stateParams) {

    
    $scope.checkState = function () {
      $scope.configApprovalProjectCode = "";
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
          if (response.data.code == 0) {
              $scope.projects = response.data.layoutProject;
              $http({
                method: 'GET',
                url: '/web/user/getAll',
              }).then(function (response) {
                if (response.data.code == 0) {
                  $scope.users = response.data.data;
                  initData();
                } else {
                  $scope.$emit('notification', {type: 'danger', message: response.data.msg});
                }
              }, function (err) {
                $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
              });
          } else {
            $scope.$emit('notification', {type: 'danger', message: response.data.msg});
          }
      }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })


    };
    var initData = function(projectCode){
      if(projectCode){
        $http({
          method: 'POST',
          url: '/web/approvalProcess/findConfigApproval',
          data: {
            projectCode:projectCode
          }
        }).then(function (response) {
          if (response.data.code == 0) {
            $scope.configApproval = response.data.data;
            if(projectCode){
              $scope.configApproval.projectCode = projectCode;
            }
            if($scope.configApproval.reviewer
              &&$scope.configApproval.reviewer.length>0){
              for (let index = 0; index < $scope.users.length; index++) {
                const element_users = $scope.users[index];
                for (let index = 0; index < $scope.configApproval.reviewer.length; index++) {
                  const element_reviewer = $scope.configApproval.reviewer[index];
                  if(element_users._id==element_reviewer.user._id){
                    element_users.isChoice = true;
                    break;
                  }
                }
              }
            }else{
              for (let index = 0; index < $scope.users.length; index++) {
                  const element_users = $scope.users[index];
                  element_users.isChoice = false;
              }
            }
          } else {
            $scope.$emit('notification', {type: 'danger', message: response.data.msg});
          }
        }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
        });
      }else{
        $scope.configApproval = {}
      }
    }
    $scope.projectChange = function () {
      initData($scope.configApprovalProjectCode);
    }
    //提交数据
    $scope.submit = function () {
      if (!$scope.configApproval.projectCode) {
        $scope.$emit('notification', {type: 'danger', message: '请选择项目'});
        return;
      }
      $scope.configApproval.reviewer = [];
      for (let index = 0; index < $scope.users.length; index++) {
        const element_users = $scope.users[index];
        if(element_users.isChoice){
          $scope.configApproval.reviewer.push({user:element_users._id});
        }
      }
      $http({
        method: 'POST',
        url: '/web/approvalProcess/uploadConfigApproval',
        data: $scope.configApproval
      }).then(function (result) {
        if (result.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '提交成功,2秒后返回项目审核管理'});
            $timeout(function(){
              $state.go("main.configApproval")
            },3000)
        } else {
          $scope.$emit('notification', {type: 'danger', message: result.data.msg});
        }

      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
      });
     
    };

  }

]);