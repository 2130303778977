'use strict';
angular.module('controllers').controller('upgradeLayoutPageCardAdTimeCtr', ['$sce','$scope', 'Upload', '$http', '$window','$timeout','$state','$stateParams','$compile','uploadFileService',
function ($sce,$scope, Upload, $http, $window,$timeout,$state,$stateParams,$compile,uploadFileService) {
  $scope.pageNumber = sessionStorage.getItem("pageNumber");

  $scope.newCard={
    projectCode:$stateParams.belongProject,
    belongConfigId:$stateParams.belongConfigId,
    remark:'',
    validTime:'0',
    forbidden:'0',
    repeatAttr:{
      monday:true,
      tuesday:true,
      wednesday:true,
      thursday:true,
      friday:true,
      saturday:true,
      sunday:true,
    },
    timeInDays:[{
      start:0,
      end:24
    }],
    bannerAttr: {
      attr:'',
      files:[]
    },
    videoAttr: {
      attr:'',
      files:[]
    },
    title: {
      name: '',
      nameEng: '',
      attr:''
    },
    bgPic:{
      picUrl: '',
      picEnUrl:''
    }
  }
  $scope.uploadFiles = {};
  $scope.uploadFiles.bgPic = null;
  $scope.uploadFiles.videos = null;
  $scope.checkState = function () {
    console.log($scope.newCard.belongConfigId);
    $scope.belongConfigId = $stateParams.belongConfigId;
      if (!$stateParams.belongConfigId||!$stateParams.belongProject) {
        $scope.$emit('notification', {type: 'danger', message: '非法请求页面'});
        $scope.errorRequest = true;
        return;
      }
    $http({
      method: 'GET',
      url: '/config/getLayoutConfig',
    }).then(function (response) {
      if (response.data.code === 0) {
        $scope.belongs = response.data.layoutProject;
        for (let index = 0; index < $scope.belongs.length; index++) {
          const element = $scope.belongs[index];
          if(element.nameEn==$stateParams.belongProject){
            $scope.curProjectNameEn = element.nameEn;
            $scope.curProjectName = element.nameCn;
            break;
          }
        }
        $http({
          method: 'GET',
          url: '/config/getWebConfig',
        }).then(function (response) {
            if (response.data.code === 0) {
                $scope.domain = response.data.domain+"/"+$scope.curProjectNameEn;
                $scope.uploadToken = response.data.uploadToken;
                $scope.fileSaveWay = response.data.fileSaveWay;
            }
        }, function (err) {
            $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
        })
      }
    });
    
    $http({
      method: 'Post',
      url: '/web/layoutPageCardAd/findOne',
      data:{data:{_id:$stateParams.belongConfigId}},
    }).then(function (response) {
        if (response.data.code === 0) {
          console.log(response.data.data);
          $scope.belongConfigName = response.data.data.name;
          $scope.newCard.isShowBgPic = response.data.data.isShowBgPic;
          $scope.newCard.isShowTitle = response.data.data.isShowTitle;
          $scope.newCard.cardType = response.data.data.cardType;
          if($stateParams.upgradeId){
            $http({
              method: 'Post',
              url: '/web/layoutPageCardAdTime/findOne',
              data:{data:{_id:$stateParams.upgradeId}},
            }).then(function (response) {
                if (response.data.code === 0) {
                  $scope.newCard = response.data.data;
                  if($scope.newCard.validTime==1){
                    $scope.newCard.validTimeAttr.start = new Date($scope.newCard.validTimeAttr.start);  
                    $scope.newCard.validTimeAttr.end = new Date($scope.newCard.validTimeAttr.end);  
                  }
                }
            }, function (err) {
                $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
            })
          }
        }
    }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
    })
    
    
    $scope.myTimes=[];
      for (let index = 0; index < 25; index++) {
        let element = {
          name:"",
          value:index,
        };
        if(index>=10){
          element.name=index+":00"
        }else{
          element.name="0"+index+":00"
        }
        $scope.myTimes.push(element);
      }
  };

  $scope.createCard = function (){
    if (/[']/.test($scope.newCard.remark)
    ) {
      $scope.$emit('notification', {type: 'danger', message: '内容包含英文的单引号，不允许提交!'});
      return;
    }
    let files = [];
    if($scope.newCard.cardType!='videoView'){
      if($scope.newCard.videoAttr){
        $scope.newCard.videoAttr.files = [];
      }
    }else{
      if($scope.uploadFiles.videos){
        var check = new RegExp(".mp4$", 'i').test($scope.uploadFiles.videos.name);
        if (!check) {
          $scope.$emit('notification', {type: 'danger', message: '上传文件的格式不正确'});
          return
        }
        let tmpFiles = [];
        const element = $scope.uploadFiles.videos;
        if(/[']/.test(element.name)){
          document.getElementById('tip_msg').innerHTML = '<p style="color:red">文件名不能包含英文单引号</p>'
          return;
        }
        let nickName = "autolayout_video" + $scope.getTime() + "_" + getFileType(element.name);
        element.nickName = nickName;
        files.push(element);
        tmpFiles.push({
          fileName:element.name,
          url:$scope.domain+"/" +nickName
        })
        $scope.newCard.videoAttr.files = tmpFiles;
      }
    }

    if($scope.newCard.cardType!='bannerView'){
      if($scope.newCard.bannerAttr){
        $scope.newCard.bannerAttr.files = [];
      }
    }else{
      if($scope.uploadFiles.banners&&$scope.uploadFiles.banners.length!=0){
        let tmpFiles = [];
        for (let index = 0; index < $scope.uploadFiles.banners.length; index++) {
          const element = $scope.uploadFiles.banners[index];
          if(/[']/.test(element.name)){
            document.getElementById('tip_msg').innerHTML = '<p style="color:red">文件名不能包含英文单引号</p>'
            return;
          }
          let nickName = "autolayout_banner" + $scope.getTime() + "_"+index + getFileType(element.name);
          files.push(element);
          tmpFiles.push({
            fileName:element.name,
            url:$scope.domain+"/" +nickName
          })
        }
        $scope.newCard.bannerAttr.files = tmpFiles;
      }
    }

    if($scope.newCard.isShowBgPic){
      if($scope.uploadFiles.bgPic){
        if(/[']/.test($scope.uploadFiles.bgPic.name)){
          document.getElementById('tip_msg').innerHTML = '<p style="color:red">背景图片名不能包含英文单引号</p>'
          return;
        }
        $scope.newCard.bgPic = {};
        let nickName = "autolayout_bgPic" + $scope.getTime() + "_" + getFileType($scope.uploadFiles.bgPicEn.name);
        $scope.newCard.bgPic.picUrl = $scope.domain+"/" +nickName;
        $scope.uploadFiles.bgPic.nickName = nickName;
        files.push($scope.uploadFiles.bgPic);
      }
      if ($scope.uploadFiles.bgPicEn) {
        if(/[']/.test($scope.uploadFiles.bgPicEn.name)){
          document.getElementById('tip_msg').innerHTML = '<p style="color:red">英文背景图片名不能包含英文单引号</p>'
          return;
        }
        let nickName = "autolayout_bgPicEn" + $scope.getTime() + "_" + getFileType($scope.uploadFiles.bgPicEn.name);
        $scope.newCard.bgPic.picEnUrl = $scope.domain + "/" + nickName;
        $scope.uploadFiles.bgPicEn.nickName = nickName;
        files.push($scope.uploadFiles.bgPicEn);
      }
    }
    let finishUploadCallBack = function(data,uploadResData){
      console.log(data);
      console.log(uploadResData);
      if(uploadResData
        &&data.videoAttr
        &&data.videoAttr.files
        &&data.videoAttr.files.length>0){
        for (let i = 0; i < uploadResData.length; i++) {
          let ele_uploadResData = uploadResData[i];
          for (let j = 0; j < data.videoAttr.files.length; j++) {
            let ele_files = data.videoAttr.files[j];
            if(ele_files.url.indexOf(ele_uploadResData.newName)!=-1){
              ele_files.md5 = ele_uploadResData.md5;
            }
          }
        }
      }
      $http({
        method: 'POST',
        url: '/web/layoutPageCardAdTime/uploadLayoutPageCardAdTime',
        data: {data:data}
      }).then(function (response) {
          if (response.data.code == 0) {
            $scope.$emit('notification', {type: 'success', message: '成功，2秒后返回列表'});
            $timeout(function () {
              $scope.backUrl();
            }, 2000)
          } else {
            $scope.$emit('notification', {type: 'danger', message: response.data.msg});
          }
        }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
        });
    }
    $scope.$emit('notification', {type: 'success', message: '正在提交……'});
    uploadFileService.uploadFile($scope,$scope.curProjectNameEn,files,$scope.newCard,finishUploadCallBack);
  }

  $scope.backUrl = function(){
    $state.go('main.configAdTimeCard',{configId:$scope.belongConfigId,belongProject:$stateParams.belongProject})
  }

  let isJson = function(str) {
    if(!str)return false;
    if (/[']/.test(str))return false;
    if (str==1)return false;
    if (typeof str == 'string') {
        try {
            JSON.parse(str);
            return true;
        } catch(e) {
            return false;
        }
    }
  }
  
  $scope.getTime = function(file){
    return Date.now();
  }
  var isChinese = function(temp){
    if(/.*[\u4e00-\u9fa5]+.*$/.test(temp)) {
          return true;
        } 
        return false;
  }
  $scope.addTimeInDay = function(){
    $scope.newCard.timeInDays.push({
      start:0,
      end:24
    });
  }
  $scope.delTimeInDay = function(index){
    $scope.newCard.timeInDays.splice(index,1);
  }
  var getFileType = function(name){
    return name.substring(name.lastIndexOf("."));
  }

}]);