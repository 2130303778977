/**
 * Created by Administrator on 2017/7/12.
 */

'use strict';
angular.module('controllers').controller('addHotelRoomCtr', ['$scope', 'Upload', '$http', '$window','$timeout','$state','$stateParams',
  function ($scope, Upload, $http, $window,$timeout,$state,$stateParams) {
    $scope.floor_list=[]
    $scope.formData = {
      addMode:0,
      roomNum:'',
      floorName:'',
      description:'',
      room_list:[{
        startRoomNum:'',
        endRoomNum:'',
        floorName:''
      }]
    }
    $scope.checkState = function () {
      $scope.projectPower = "all";
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
          if (response.data.code === 0) {
              $scope.belongs = response.data.layoutProject;
              $scope.activitys = response.data.layoutActivity;
              for (let index = 0; index < $scope.belongs.length; index++) {
                const element = $scope.belongs[index];
                if(element.nameEn==$stateParams.belongProject){
                  $scope.curProjectNameEn = element.nameEn;
                  $scope.curProjectName = element.nameCn;
                  break;
                }
              }
          } else {
            $scope.$emit('notification', {type: 'danger', message: response.data.msg});
          }
      }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })
      $http({
        method: 'GET',
        url: '/web/hotel/getHotelFloorList?belongProject='+$stateParams.belongProject,
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.floor_list = response.data.data;
          if($scope.floor_list&&$scope.floor_list.length>0){
            $scope.formData.floorName = $scope.floor_list[0].floorName
            $scope.formData.room_list[0].floorName = $scope.floor_list[0].floorName
          }
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };
    $scope.addItem = function(){
      let item = {
        startRoomNum:'',
        endRoomNum:'',
        floorName:''
      }
      if($scope.floor_list&&$scope.floor_list.length>0){
        item.floorName = $scope.floor_list[0].floorName
      }
      $scope.formData.room_list.push(item);
    }
    $scope.delItem = function(index){
      $scope.formData.room_list.splice(index,1);
    }
    
    var isChinese = function(temp){
      if(/.*[\u4e00-\u9fa5]+.*$/.test(temp)) {
          return true;
         }
         return false;
    }
    //正则判断是否为数字
    var isNumber = function (temp) {
      if ((/^\d{1,10}$/.test(temp))) {
        return true;
      }
      return false;
    }
    //提交数据
    $scope.submit = function () {
      
      if (/[']/.test($scope.formData.description)
      ){
        $scope.$emit('notification', {type: 'danger', message: '内容包含英文的单引号，不允许提交!'});
        return;
      }
      if($scope.formData.addMode=="1"){//1：单个 0：批量
        if(!isNumber($scope.formData.roomNum)){
          $scope.$emit('notification', {type: 'danger', message: '房间号只可以是数字,请重新输入'});
          return;
        }
      }else{
        for (let index = 0; index < $scope.formData.room_list.length; index++) {
          const element =  $scope.formData.room_list[index];
          if(!isNumber(element.startRoomNum)){
            $scope.$emit('notification', {type: 'danger', message: '开始房间号只可以是数字,请重新输入'});
            return;
          }
          if(!isNumber(element.endRoomNum)){
            $scope.$emit('notification', {type: 'danger', message: '结束房间号只可以是数字,请重新输入'});
            return;
          }
          if(element.startRoomNum>element.endRoomNum){
            $scope.$emit('notification', {type: 'danger', message: '开始房间号不能大于结束房间号,请重新输入'});
            return;
          }
        }
      }
      $scope.formData.belongProject = $stateParams.belongProject;
      $scope.formData.belongProjectCn = $scope.curProjectName;
      $http({
        method: 'POST',
        url: '/web/hotel/uploadHotelRoom',
        data: $scope.formData
      }).then(function (result) {
        if (result.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '提交成功,2秒后跳回房间列表'});
            $timeout(function(){
              $state.go("main.hotelRoomManage",{belongProject:$stateParams.belongProject})
            },3000)
        } else {
          $scope.$emit('notification', {type: 'danger', message: result.data.msg});
        }

      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
      });
     
    };

  }

]);