/**
 * Created by Administrator on 2017/7/4.
 */

'use strict';
angular.module('controllers').controller('statisticsCtr', ['$scope', '$window',
  function ($scope,$window) {

    // var socket = io('/mqttRoom');

    // socket.on('message',function(data1,data2){
    //   console.log(data1)
    //   console.log(data2)
    // })

  }
]);