/**
 * Created by Administrator on 2017/7/11.
 */


angular.module('controllers').controller('engineerUserCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state',
  function ($scope, Upload, $http, $window, $timeout, $state) {
    $scope.user = {name: '', id: ''};

    var loadTable = function (data, reload) {
        if (reload) {
          $('#table2').bootstrapTable('load', data);
        } else {
          $('#table2').bootstrapTable({
              pagination: true,
              pageSize: 10, //每页显示10条
              cache: false,
              search: true,
              showColumns: 'true',
              showExport: true,//显示导出按钮
              exportDataType: 'all',//导出类型
              columns: [{
                title: '用户名',
                align: 'center',
                field: 'account',
              },{
                title: '允许操作的项目列表',
                align: 'center',
                sortable: true,
                field: 'belongProject',
                formatter: function (value, row, index) {
                  let str = '';
                  if(value&&value.length>0){
                    for (let index = 0; index < value.length; index++) {
                      const element = value[index];
                      str = str+'<div>'+element.nameCn+'-'+element.nameEn+'</div>'
                    }
                  }else{
                    str = "所有"
                  }
                  return str
                }
              }, {
                title: '将网关与项目绑定的权限',
                align: 'center',
                sortable: true,
                field: 'bindProjectRight',
                formatter: function (value, row, index) {
                  var str =  "✖"
                  if(value){
                    str = "✔"
                  }else{
                    str = "✖"
                  }
                  return str
                }
              }, {
                title: '解绑网关的权限',
                align: 'center',
                sortable: true,
                field: 'unbindProjectRight',
                formatter: function (value, row, index) {
                  var str =  "✖"
                  if(value){
                    str = "✔"
                  }else{
                    str = "✖"
                  }
                  return str
                }
              },{
                title: '设置网关信息的权限',
                align: 'center',
                sortable: true,
                field: 'setInfoRight',
                formatter: function (value, row, index) {
                  var str =  "✖"
                  if(value){
                    str = "✔"
                  }else{
                    str = "✖"
                  }
                  return str
                }
              },{
                title: '账号信息备注',
                align: 'center',
                field: 'remark',
              },{
                title: '操作',
                align: 'center',
                width:'120px',
                formatter: function (value, row, index) {
                  var data = angular.toJson(row);
                  //return '<button  class=\'btn btn-success btn-xs\' onclick=\'angular.element(this).scope().toUploadPage(' + row + ')\'>修改权限</button>&nbsp;'
                  return (row.userManage==1?'无':'<button  class=\'btn btn-success btn-xs\' onclick=\'angular.element(this).scope().toUploadPage(' + data + ')\'>修改</button>' +
                  '<br><button  style="margin-top:5px"  class=\'btn btn-danger btn-xs\'  onclick=\'angular.element(this).scope().toDelete(' + data + ')\'>删除</button>');
                }
              }
              ],
              data: data
            }
          );
        }
      }
    ;

    $scope.upgradeInit = function (reload) {
      $http({
        method: 'GET',
        url: '/config/getWebConfig',
      }).then(function (response) {
          if (response.data.code === 0) {
              $scope.domain = response.data.domain;
              $scope.uploadToken = response.data.uploadToken;
              $scope.fileSaveWay = response.data.fileSaveWay;
              $http({
                method: 'GET',
                url: '/engineerApp/getAll',
              }).then(function (response) {
                if (response.data.code == 0) {
                  loadTable(response.data.data, reload)
                } else {
                  $scope.$emit('notification', {type: 'danger', message: response.data.msg});
                }
              }, function (err) {
                $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
              });
          }
      }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })
      
    };

    $scope.toUploadPage = function (data) {
      $state.go('main.addEngineerUser',{userId:data._id})
    };
    
    $scope.toDelete = function (data) {
      $scope.user.name = data.name;
      console.log(data);
      $scope.user.id = data._id;
      $timeout(function () {
        $('#delModal').modal('show')
      }, 10);
    };

    $scope.doDelUser = function () {
      $http({
        method: 'DELETE',
        url: '/engineerApp/delUser',
        params: {
          id: $scope.user.id
        }
      }).then(function (response) {
        $timeout(function () {
          $('#delModal').modal('hide')
        }, 10);

        if (response.data.code == 0) {
          $scope.upgradeInit(true);
          $scope.$emit('notification', {type: 'success', message: '删除成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        console.log(err);
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

 

  }
])
;