/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('configWifiUuidCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state','$stateParams',
  function ($scope, Upload, $http, $window, $timeout, $state,$stateParams) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");

    $scope.upgrade = {showName: '', id: '', version: '', newVersion: ''};


    var loadTable = function (data, reload) {
        if (reload) {
          $('#table2').bootstrapTable('load', data);
        } else {
          $('#table2').bootstrapTable({
              pagination: true,
              pageSize: 10, //每页显示10条
              cache: false,
              search: true,
              showColumns: 'true',
              showExport: true,//显示导出按钮
              exportDataType: 'all',//导出类型
              columns: [{
                title: 'UUID(网关MAC)',
                align: 'center',
                field: 'uuid_type',
                width:'100px',
                formatter: function (value, row, index) {
                  var msg = '-';
                  if(value==0){
                    msg = '';
                    if(row.uuid_list){
                      row.uuid_list.forEach(element => {
                        msg = msg+element+'<br>';
                      });
                    }
                  }else{
                    msg = row.uuid_start+"-"+row.uuid_end;
                  }
                  return msg;
                }
              }, {
                title: '版本号',
                align: 'center',
                field: 'version',
              }, {
                title: 'md5',
                align: 'center',
                field: 'md5',
              }, {
                title: '下载链接',
                align: 'center',
                field: 'url',
              }, {
                title: '更新日期',
                align: 'center',
                field: 'updateTime',
                formatter: function (value, row, index) {
                  return moment(value, 'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm')
                }
              }, {
                title: '升级方式',
                align: 'center',
                field: 'force',
                formatter: function (value, row, index) {
                  return value == 1 ? "强制升级" : "手动升级"
                }
              }, {
                title: '强制升级方式',
                align: 'center',
                field: 'force',
                formatter: function (value, row, index) {
                  if(value==0||!value){
                    return '-'
                  }
                  if(!row.force_time){
                    return '-'
                  }
                  return row.force_time.type == 1 ? "固定时间段内进行升级" : "开机获取配置后延时进行升级"
                }
              }, {
                title: '强制升级时间',
                align: 'center',
                field: 'force',
                formatter: function (value, row, index) {
                  if(value==0||!value){
                    return '-'
                  }
                  if(!row.force_time){
                    return '-'
                  }
                  if(row.force_time.type==0){
                    return "延时:"+row.force_time.delay+"秒"
                  }else{
                    var d_start = new Date(row.force_time.start);  
                    row.force_time.start = timeAdd0(d_start.getHours())+":"+timeAdd0(d_start.getMinutes());
                    var d_end = new Date(row.force_time.end);  
                    row.force_time.end = timeAdd0(d_end.getHours())+":"+timeAdd0(d_end.getMinutes());
                    return "时间段:"+row.force_time.start+"~"+row.force_time.end
                  }
                }
              }, {
                title: '操作',
                align: 'center',
                width:'120px',
                formatter: function (value, row, index) {
                  row = angular.toJson(row);
                  return '<button  class=\'btn btn-success btn-xs\' onclick=\'angular.element(this).scope().toUploadPage(' + row + ')\'>更新</button>&nbsp;' +
                    '&nbsp;<button  class=\'btn btn-danger btn-xs\' onclick=\'angular.element(this).scope().showDeleteModal(' + row + ')\'>删除</button>';
                }
              }
              ],
              data: data
            }
          );
        }
      }
    ;
    function timeAdd0(str) {
      str = str+'';
      if(str.length<=1){
          str='0'+str;
      }
      return str
    }
    $scope.showDeleteModal = function (data) {
      $scope.toDelProjectData =data;
      $timeout(function () {
        $('#closePageWin').modal('show')
      }, 10);
    };
    $scope.toDelete = function(){
      console.log($scope.toDelProjectData._id)
      $http({
        method: 'POST',
        url: '/web/configWifiUuid/delete',
        data:{_id : $scope.toDelProjectData._id}
      }).then(function (response) {
        $timeout(function () {
          $('#closePageWin').modal('hide')
        }, 10);
        if (response.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '删除成功'});
          $scope.upgradeInit(true);
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    }
    $scope.upgradeInit = function (reload) {
      if (!$stateParams.belongProject||!$stateParams.type) {
        $scope.$emit('notification', {type: 'danger', message: '非法请求页面'});
        $scope.errorRequest = true;
        return;
      }
      $scope.fileType = $stateParams.type;
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if(element.nameEn==$stateParams.belongProject){
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
        }
      });
      $http({
        method: 'GET',
        url: '/web/configWifiUuid/getAll?projectCode='+$stateParams.belongProject+"&type="+$stateParams.type,
      }).then(function (response) {
        if (response.data.code == 0) {
          loadTable(response.data.data, reload)
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };
    $scope.toBack = function(){
      $state.go('main.configWifi',{belongProject:$stateParams.belongProject});
    }
    $scope.toUploadPage = function (data) {
      if(data){
        $state.go('main.upgradeConfigWifiUuid', {itemId:data._id,belongProject:$stateParams.belongProject,type:$stateParams.type})
      }else{
        $state.go('main.upgradeConfigWifiUuid',{belongProject:$stateParams.belongProject,type:$stateParams.type})
      }
    };

  }
])
;