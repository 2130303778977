/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('hotelManageCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state','$stateParams',
  function ($scope, Upload, $http, $window, $timeout, $state,$stateParams) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");
    var pageParam = {
      dimension_h:2,
      dimension_w:3,
      margin_h:3,
      margin_w:3,
      max_cols:60,
    }
    var loadTable = function (data, reload) {
        if (reload) {
          $('#table2').bootstrapTable('load', data);
        } else {
          $('#table2').bootstrapTable({
              pagination: true,
              pageSize: 10, //每页显示10条
              cache: false,
              search: true,
              showColumns: 'true',
              showExport: true,//显示导出按钮
              exportDataType: 'all',//导出类型
              columns: [{
                title: '配置分类',
                align: 'center',
                field: 'name',
              },{
                title: '操作',
                align: 'center',
                width:'600px',
                formatter: function (value, row, index) {
                  let jsonRow = angular.toJson(row);
                  let result="-";
                  if(row.right){
                    result = '<button  class=\'btn btn-success btn-xs\' onclick=\'angular.element(this).scope().toUpdate(' + jsonRow + ')\'>查看/更新</button>'
                  }else{
                    result = '<button  class=\'btn btn-default btn-xs\' onclick=\'angular.element(this).scope().showNoRight()\'>查看/更新</button>'
                  }
                  return result;
                }
              },
            ],
              data: data
            }
          );
        }
      }
    ;

    $scope.toUpdate = function(data){
      window.location.href=$state.href(data.url,{belongProject:$stateParams.belongProject});
    }
    $scope.showNoRight = function(){
      $scope.$emit('notification', {type: 'danger', message: '该账号没有操作此配置的权限，请联系管理员开通！'});
    }
    
    
    $scope.init = function (reload) {
      if (!$stateParams.belongProject) {
        $scope.$emit('notification', {type: 'danger', message: '非法请求页面'});
        $scope.errorRequest = true;
        return;
      }
      $http({
        method: 'GET',
        url: '/web/user/getUser',
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.user = response.data.user;
          $http({
            method: 'GET',
            url: '/config/getLayoutConfig',
          }).then(function (response) {
              if (response.data.code === 0) {
                  $scope.belongs = response.data.layoutProject;
                  for (let index = 0; index < $scope.belongs.length; index++) {
                    const element = $scope.belongs[index];
                    if(element.nameEn==$stateParams.belongProject){
                      $scope.curProjectNameEn = element.nameEn;
                      $scope.curProjectName = element.nameCn;
                      break;
                    }
                  }
                  tableData = [{
                      name:'<span class="fa fa-fw fa-lg fa-lightbulb-o"></span><div class="project_config_title">客控管理</div>',
                      right:($scope.user.projectInfoConfigManage==1||$scope.user.userManage==1),
                      url:'main.hotelDeviceManage'
                    },{
                        name:'<span class="fa fa-fw fa-lg fa-home"></span><div class="project_config_title">房间管理</div>',
                        right:($scope.user.layoutPageManage==1||$scope.user.userManage==1),
                        url:'main.hotelRoomManage'
                    },{
                      name:'<span class="fa fa-fw fa-lg fa-list-ol"></span><div class="project_config_title">楼层管理</div>',
                      right:($scope.user.layoutPageManage==1||$scope.user.userManage==1),
                      url:'main.hotelFloorManage'
                    }
                  ]
                  loadTable(tableData, reload)
              }
          }, function (err) {
              $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
          })
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };
  
  }
])
;