/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('configPlugCtr', ['$scope', 'Upload', '$http', '$window', '$timeout','$state','$stateParams',
  function ($scope, Upload, $http, $window, $timeout,$state,$stateParams) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");

    $scope.plug = {
      name: '',
      id: '',
      install_cmd:''
    };

    var loadTable = function (data, reload) {
      if (reload) {
        $('#table2').bootstrapTable('load', data);
      } else {
        $('#table2').bootstrapTable({
          pagination: true,
          pageSize: 10, //每页显示10条
          cache: false,
          search: true,
          showColumns: 'true',
          showExport: true,//显示导出按钮
          exportDataType: 'all',//导出类型
          columns: [{
            title: '插件名称',
            align: 'center',
            field: 'name',
            sortable: true
          },{
            title: '插件别名',
            align: 'center',
            field: 'nickname',
            sortable: true
          },{
            title: '版本号',
            align: 'center',
            field: 'version',
          }, {
            title: 'md5',
            align: 'center',
            field: 'md5',
          },{
            title: '下载链接',
            align: 'center',
            sortable: true,
            field: 'url',
          }, {
            title: '需要重启',
            align: 'center',
            field: 'reset',
            formatter: function (value, row, index) {
              return value==1?"是":"否"
            }
          },{
            title: '开机自启',
            align: 'center',
            field: 'boot',
            formatter: function (value, row, index) {
              return value==1?"是":"否"
            }
          },{
            title: '安装命令',
            align: 'center',
            field: 'install_cmd',
            formatter: function (value, row, index) {
              var jsonValue = angular.toJson(value);
              if(!value){
                return '无'
              }else{
                return '<button  class=\'btn btn-default btn-xs\' onclick=\'angular.element(this).scope().showCmdModel('+ jsonValue+')\'>查看</button>'
              }
            }
          }, {
            title: '是否禁用',
            align: 'center',
            field: 'forbidden',
            formatter: function (value, row, index) {
              return value=='1'?'<span style="color:red">禁用</span>':'<span style="color:green">启用</span>';
            }
          }, {
            title: '更新日期',
            align: 'center',
            field: 'updateTime',
            formatter: function (value, row, index) {
              return moment(value, 'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm')
            }
          }, {
            title: '操作',
            align: 'center',
            width:'120px',
            formatter: function (value, row, index) {
              var jsonRow = angular.toJson(row);
              return '<button  class=\'btn btn-success btn-xs\' onclick=\'angular.element(this).scope().updatePlug(' + jsonRow + ')\'>更新配置</button>'
              +'<br><button style="margin-top:5px" class=\'btn btn-warning btn-xs\' onclick=\'angular.element(this).scope().showForbiddenModal(' + jsonRow + ')\'>禁用/启用</button>'
              +'<br><button style="margin-top:5px" class=\'btn btn-danger btn-xs\' onclick=\'angular.element(this).scope().delPlugModal('+jsonRow+')\'>删除配置</button>';
              
            }
          }],
          data: data
        });
      }
    };

    $scope.plugInit = function (reload) {
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if(element.nameEn==$stateParams.belongProject){
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
        }
      });
      $http({
        method: 'GET',
        url: '/web/configPlug/getAllWeb?projectCode='+$stateParams.belongProject,
      }).then(function (response) {
        if (response.data.code == 0) {
          loadTable(response.data.data, reload)
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

    $scope.updatePlug = function (data) {
      $state.go('main.upgradeConfigPlug', {upgradeId:data._id,belongProject:$stateParams.belongProject})
    };

    $scope.doResetPwd = function () {
      $http({
        method: 'PUT',
        url: '/web/account/resetPwd',
        data: {
          phone: $scope.user.phone
        }
      }).then(function (response) {
        $timeout(function () {
          $('#resetModal').modal('hide')
        }, 10);

        if (response.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '重置成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

    $scope.delPlugModal = function (data) {
      $scope.plug.name = data.name;
      $scope.plug.id = data._id;
      $timeout(function () {
        $('#delModal').modal('show')
      }, 10);
    };

    $scope.showCmdModel = function(cmd){
      $scope.plug.install_cmd = cmd;
      $timeout(function () {
        $('#cmdModal').modal('show')
      }, 10);
    };

    $scope.showForbiddenModal = function (data) {
      $scope.plug.id = data._id;
      $scope.plug.showName = "插件名称："+data.name;
      $scope.plug.newForbidden = '0';
      $scope.plug.forbidden = data.forbidden=='1'?'1':'0';

      $timeout(function () {
        $('#forbiddenModal').modal('show')
      }, 10);
    };
    $scope.doUpdateForbidden = function () {
      $http({
        method: 'POST',
        url: '/web/configPlug/changeForbidden',
        data: {
          id: $scope.plug.id,
          forbidden:$scope.plug.newForbidden,
          projectCode:$stateParams.belongProject,
        }
      }).then(function (response) {
        $timeout(function () {
          $('#forbiddenModal').modal('hide')
        }, 10);

        if (response.data.code == 0) {
          $scope.plugInit(true);
          $scope.$emit('notification', {type: 'success', message: '修改成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

    $scope.doDelPlug = function () {
      $http({
        method: 'DELETE',
        url: '/web/configPlug/delPlug',
        params: {
          id: $scope.plug.id
        }
      }).then(function (response) {
        $timeout(function () {
          $('#delModal').modal('hide')
        }, 10);

        if (response.data.code == 0) {
          $scope.plugInit(true);
          $scope.$emit('notification', {type: 'success', message: '删除成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

  }
]);