/**
 * Created by Administrator on 2017/7/12.
 */

'use strict';
angular.module('controllers').controller('upgradeConfigDiffpkgCtr', ['$scope', 'Upload', '$http', '$window','$timeout','$state','$stateParams','uploadFileService',
  function ($scope, Upload, $http, $window,$timeout,$state,$stateParams,uploadFileService) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");

    
    $scope.checkState = function () {
      if (!$stateParams.belongProject) {
        $scope.$emit('notification', {type: 'danger', message: '非法请求页面'});
        $scope.errorRequest = true;
        return;
      }
      $scope.upgrade = {
        name: '',
        nickname:'',
        version: '',
        version2: '',
        md5: '',
        content: '',
        fileName: '',
        url:'',
        type:2,
        projectCode:$stateParams.belongProject
      };
      if($stateParams.upgradeId){
        $http({
          method: 'Post',
          url: '/web/configDiffpkg/findOne',
          data:{data:{_id:$stateParams.upgradeId}},
        }).then(function (response) {
            if (response.data.code === 0) {
              $scope.upgrade = response.data.data;
            }
        }, function (err) {
            $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
        })
      }
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if(element.nameEn==$stateParams.belongProject){
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
          $http({
            method: 'GET',
            url: '/config/getWebConfig',
          }).then(function (response) {
              if (response.data.code === 0) {
                  $scope.domain = response.data.domain+"/"+$scope.curProjectNameEn;
                  $scope.uploadToken = response.data.uploadToken;
                  $scope.fileSaveWay = response.data.fileSaveWay;
              }
          }, function (err) {
              $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
          })
        }
      });
    };

    //提交数据
    $scope.uploadFile = function () {
      let nickName;
      if (/[']/.test($scope.upgrade.version)
      ||/[']/.test($scope.upgrade.version2)
      ||/[']/.test($scope.upgrade.content)
      ) {
        $scope.$emit('notification', {type: 'danger', message: '内容包含英文的单引号，不允许提交!'});
        return;
      }

      if (!$scope.upgrade.version) {
        $scope.$emit('notification', {type: 'danger', message: '请填写旧版本号'});
        return;
      }
      if (!$scope.upgrade.version2) {
        $scope.$emit('notification', {type: 'danger', message: '请填写新版本号'});
        return;
      }
      if(isNaN($scope.upgrade.version)){
        $scope.$emit('notification', {type: 'danger', message: '旧版本号必须是数值'});
        return;
      }
      if(isNaN($scope.upgrade.version2)){
        $scope.$emit('notification', {type: 'danger', message: '新版本号必须是数值'});
        return;
      }
      if(!$scope.upgrade.file){
        if(!$scope.upgrade.fileName){
          $scope.$emit('notification', {type: 'danger', message: '请选择上传的文件'});
          return;
        }
      }else{
        if(isChinese($scope.upgrade.file.name)){
          $scope.$emit('notification', {type: 'danger', message: '文件名不能包含中文'});
          return;
        }
        var check = new RegExp(".zip$", 'i').test($scope.upgrade.file.name);
        if (!check) {
          $scope.$emit('notification', {type: 'danger', message: '上传文件不是zip格式'});
          return
        }
        nickName = "upgradefile"+$scope.getTime()+"_"+$scope.upgrade.file.name;
        $scope.upgrade.fileName = nickName;
        $scope.upgrade.url = $scope.domain+"/"+nickName;
        $scope.upgrade.file.nickName = nickName;
      }
      uploadFileService.cancelUpload($scope);
      uploadFileService.uploadFile($scope,$scope.upgrade.projectCode,[$scope.upgrade.file],$scope.upgrade,finishUploadCallBack);
    };

    function finishUploadCallBack(data,uploadResData){
      console.log(data);
      console.log(uploadResData);
      if(uploadResData&&uploadResData.length==1&&uploadResData[0].md5){
        data.md5 = uploadResData[0].md5;
      }
      $http({
        method: 'POST',
        url: '/web/configDiffpkg/uploadPlug',
        data: data
      }).then(function (result) {
        if (result.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '成功,2秒后返回列表'});
          $timeout(function(){
            $state.go("main.configDiffpkg",{belongProject:$stateParams.belongProject})
          },2000)
        } else {
          $('#progress')[0].style.width = '0%';
          $scope.$emit('notification', {type: 'danger', message: result.data.msg});
        }

      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
      });
    }

    $scope.cancelUpload = function () {
       uploadFileService.cancelUpload($scope);
    }
    
    var isChinese = function(temp){
      if(/.*[\u4e00-\u9fa5]+.*$/.test(temp)) {
            return true;
          } 
          return false;
    }
    $scope.getTime = function(){
      return Date.now();
    }

  }
]);