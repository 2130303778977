/**
 * Created by Administrator on 2017/7/12.
 */

'use strict';
angular.module('controllers').controller('upgradeLayoutProjectCardAttrCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state', '$stateParams',
  function ($scope, Upload, $http, $window, $timeout, $state, $stateParams) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");
    $scope.curProjectInfo ={
      cardAttr:'',
      titleAttr:'',
      iconAttr:''
    }
    $scope.checkState = function () {
      initCardInput();
      if (!$stateParams.projectId||!$stateParams.belongProject) {
        $scope.$emit('notification', {type: 'danger', message: '非法请求页面'});
        $scope.errorRequest = true;
        return;
      }
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if(element.nameEn==$stateParams.belongProject){
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              $scope.curProjectInfo = element;
              initCardInput();
              break;
            }
          }
          $http({
            method: 'GET',
            url: '/config/getWebConfig',
          }).then(function (response) {
              if (response.data.code === 0) {
                  $scope.domain = response.data.domain+"/"+$scope.curProjectNameEn;
                  $scope.uploadToken = response.data.uploadToken;
                  $scope.fileSaveWay = response.data.fileSaveWay;

              }
          }, function (err) {
              $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
          })
        }
      });
    };
    var initCardInput = function(){
      $scope.cardInput = {
        cardAttr: $scope.curProjectInfo.cardAttr?angular.fromJson($scope.curProjectInfo.cardAttr):{
          corner: "5px",
          frameColor: "#ffffff",
          bgColor: "#00000000",
          zoomIndex: "1",
          shadow: {
            isShow: "false",
            color: "#99000000",
            height: "70px",
            position: "bottom",
            margin: "0px 0px 0px 0px"
          }
        },
        title: {
          name: '',
          nameEng: "",
          attr:$scope.curProjectInfo.titleAttr?angular.fromJson($scope.curProjectInfo.titleAttr):{
            id: "",
            ifNotFocusCanScroll: "true",
            size: "40px",
            color: "#ffffffff",
            width: "",
            height: "",
            position: "left|bottom",
            margin: "0px 0px 10px 15px"
          }
        },
        icon: {
          picUrl: '',
          attr: $scope.curProjectInfo.iconAttr?angular.fromJson($scope.curProjectInfo.iconAttr):{
            width: "110px",
            height: "110px",
            position: "center_horizontal|top",
            margin: "40px 0px 0px 0px",
          }
        },
      }
    }
  
    $scope.changeProject = function(){
      if(1){
        if ($scope.cardInput.cardAttr.corner == "" || $scope.cardInput.cardAttr.frameColor == "" || $scope.cardInput.cardAttr.bgColor == "" || $scope.cardInput.cardAttr.shadow.color == ""
        || $scope.cardInput.cardAttr.shadow.height == "" || $scope.cardInput.cardAttr.shadow.position == "" || $scope.cardInput.cardAttr.shadow.margin == "") {
        sendMsg("卡片属性不能为空，请补充完整！");
        return;
        }
        if (!isSize($scope.cardInput.cardAttr.corner)) {
          sendMsg("卡片圆角半径属性格式错误，应为：0-9999px");
          return;
        }
        if (!isColor($scope.cardInput.cardAttr.frameColor)) {
          sendMsg("卡片选中框颜色属性格式错误，应为：#000|#000000|#00000000");
          return;
        }
        if (!isColor($scope.cardInput.cardAttr.bgColor)) {
          sendMsg("卡片选中背景颜色属性格式错误，应为：#000|#000000|#00000000");
          return;
        }
        if (!isColor($scope.cardInput.cardAttr.shadow.color)) {
          sendMsg("shadow的颜色属性格式错误，应为：#000|#000000|#00000000");
          return;
        }
        if (!isSize($scope.cardInput.cardAttr.shadow.height)) {
          sendMsg("shadow的height属性格式错误，应为：0-9999px");
          return;
        }
        if (!isPosition($scope.cardInput.cardAttr.shadow.position)) {
          sendMsg("shadow的position属性格式错误,应为：left|right|top|bottom|center|center_horizontal|center_vertical");
          return;
        }
        if (!isMargin($scope.cardInput.cardAttr.shadow.margin)) {
          sendMsg("shadow的margin属性格式错误,应为：0-9999px 0-9999px 0-9999px 0-9999px");
          return;
        }
      }
      if (1) {
        if ($scope.cardInput.title.attr.size == "" || $scope.cardInput.title.attr.color == "" || $scope.cardInput.title.attr.position == ""
          || $scope.cardInput.title.attr.margin == "") {
          sendMsg("标题属性不能为空，请补充完整！");
          return;
        }
        if ($scope.cardInput.title.attr.id) {
          if (isContainQuotationMarks($scope.cardInput.title.attr.id)) {
            sendMsg("标题id属性包含英文单引号或双引号，不允许提交！");
            return;
          }
        }
        if (!isSize($scope.cardInput.title.attr.size)) {
          sendMsg("标题size属性格式错误，应为：0-9999px");
          return;
        }
        if (!isColor($scope.cardInput.title.attr.color)) {
          sendMsg("标题color属性格式错误，应为：#000|#000000|#00000000");
          return;
        }
        if ($scope.cardInput.title.attr.width) {
          if (!isSize($scope.cardInput.title.attr.width)) {
            sendMsg("标题width属性格式错误，应为：0-9999px");
            return;
          }
        }
        if ($scope.cardInput.title.attr.height) {
          if (!isSize($scope.cardInput.title.attr.height)) {
            sendMsg("标题height属性格式错误，应为：0-9999px");
            return;
          }
        }
        if (!isPosition($scope.cardInput.title.attr.position)) {
          sendMsg("标题position属性格式错误,应为：left|right|top|bottom|center|center_horizontal|center_vertical");
          return;
        }
        if (!isMargin($scope.cardInput.title.attr.margin)) {
          sendMsg("标题margin属性格式错误,应为：0-9999px 0-9999px 0-9999px 0-9999px");
          return;
        }
      }
      if (1) {
        if ($scope.cardInput.icon.attr.width == "" || $scope.cardInput.icon.attr.height == "" || $scope.cardInput.icon.attr.position == ""
          || $scope.cardInput.icon.attr.margin == "") {
          sendMsg("图标属性不能为空，请补充完整!");
          return;
        }
        if (!isSize($scope.cardInput.icon.attr.width)) {
          sendMsg("图标width属性格式错误，应为：0-9999px");
          return;
        }
        if (!isSize($scope.cardInput.icon.attr.height)) {
          sendMsg("图标height属性格式错误,应为：0-9999px");
          return;
        }
        if (!isPosition($scope.cardInput.icon.attr.position)) {
          sendMsg("图标position属性格式错误，应为：left|right|top|bottom|center|center_horizontal|center_vertical");
          return;
        }
        if (!isMargin($scope.cardInput.icon.attr.margin)) {
          sendMsg("图标margin属性格式错误,应为：0-9999px 0-9999px 0-9999px 0-9999px");
          return;
        }
      }
      $scope.curProjectInfo.cardAttr = angular.toJson($scope.cardInput.cardAttr);
      $scope.curProjectInfo.titleAttr = angular.toJson($scope.cardInput.title.attr);
      $scope.curProjectInfo.iconAttr = angular.toJson($scope.cardInput.icon.attr);
      $http({
        method: 'POST',
        url: '/layoutPage/uploadLayoutProject',
        data: {
          data: $scope.curProjectInfo
        }
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '修改成功'});
          $timeout(function(){
            $scope.backUrl();
          },500)
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    }

    $scope.backUrl = function(){
      $state.go('main.layoutPageGroup',{belongProject:$scope.curProjectNameEn});
    }
    var isChinese = function(temp){
      if(/.*[\u4e00-\u9fa5]+.*$/.test(temp)) {
        return true;
      }
      return false;
    }
    $scope.getTime = function(){
      return Date.now();
    }
    var sendMsg = function(msg){
      $scope.$emit('notification', {type: 'danger', message: msg});
    }
    //正则判断size属性
    var isSize = function (temp) {
      if (/^([0-9]{1,4})px$/.test(temp)) {
        return true;
      } else {
        return false;
      }
    }
    //正则判断color属性
    var isColor = function (temp) {
      if (/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{8})$/.test(temp)) {
        return true;
      } else {
        return false;
      }
    }

    //正则判断position属性
    var isPosition = function (temp) {
      if (/^(left|right|top|bottom|center|center_horizontal|center_vertical){1}(([|]left)|([|]right)|([|]top)|([|]bottom)|([|]center)|([|]center_horizontal)|([|]center_vertical)){0,1}$/.test(temp)) {
        return true;
      } else {
        return false;
      }
    }
    //正则判断margin属性
    var isMargin = function (temp) {
      if (/^([0-9]{1,4}px [0-9]{1,4}px [0-9]{1,4}px [0-9]{1,4}px)$/.test(temp)) {
        return true;
      } else {
        return false;
      }
    }

  }
]);