/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('statisticFileCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state',
  function ($scope, Upload, $http, $window, $timeout, $state) {

    var loadTable = function (data, reload) {
        if (reload) {
          $('#table2').bootstrapTable('load', data);
        } else {
          $('#table2').bootstrapTable({
              method : 'get',
              url : "/web/statistic/getAllFile",//请求路径
              striped : true, //是否显示行间隔色
              pagination : true,//是否分页
              sidePagination : 'server',//server:服务器端分页|client：前端分页
              pageSize : 10,//单页记录数
              pageList : [ 5, 10, 20, 30 ],//可选择单页记录数
              showRefresh : true,//刷新按钮
              cache: false,
              search: true,
              showColumns: 'true',
              showExport: true,//显示导出按钮
              exportDataType: 'all',//导出类型
              queryParams : function(params) {//上传服务器的参数
                var temp = {//如果是在服务器端实现分页，limit、offset这两个参数是必须的
                  limit : params.limit, // 每页显示数量
                  //offset : params.offset, // SQL语句起始索引
                  page : (params.offset / params.limit) + 1, //当前页码 
                  searchName : $('.search input').val(),
                  order : params.order, 
                  sort : params.sort,
                };
                return temp;
              },
              columns: [{
                title: '文件名',
                sortable: true,
                align: 'center',
                field: 'name',
              }, {
                title: '下载次数',
                sortable: true,
                align: 'center',
                field: 'downloadSum',
              }, {
                title: '最近一次的下载时间',
                sortable: true,
                align: 'center',
                field: 'updateTime',
                formatter: function (value, row, index) {
                  return moment(value, 'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm')
                }
              },{
                title: '操作',
                align: 'center',
                formatter: function (value, row, index) {
                  return '<button  class=\'btn btn-success btn-xs\' onclick=\'angular.element(this).scope().toDetail("' + row._id + '")\'>详情</button>';
                }
              }
            ],
              data: data
            }
          );
        }
      }
    ;
    $scope.toDetail = function(id){
      $state.go('main.statisticFileDetail',{itemId:id})
    }

    $scope.upgradeInit = function (reload) {
      loadTable(reload)
    };

  }
])
;