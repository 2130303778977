/**
 * Created by Administrator on 2017/7/12.
 */

'use strict';
angular.module('controllers').controller('upgradeLayoutProjectModeCtr', ['$scope', 'Upload', '$http', '$window','$timeout','$state','$stateParams',
  function ($scope, Upload, $http, $window,$timeout,$state,$stateParams) {

    
    $scope.checkState = function () {
      $scope.projectPower = "all";
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
          if (response.data.code === 0) {
              $scope.belongs = response.data.layoutProject;
              $scope.activitys = response.data.layoutActivity;
          } else {
            $scope.$emit('notification', {type: 'danger', message: response.data.msg});
          }
      }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })
    };
    
    var isChinese = function(temp){
      if(/.*[\u4e00-\u9fa5]+.*$/.test(temp)) {
          return true;
         }
         return false;
    }
    //提交数据
    $scope.submit = function () {
      $http({
        method: 'POST',
        url: '/layoutPage/changeLayoutProject',
        data: $scope.belongs
      }).then(function (result) {
        if (result.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '提交成功,2秒后跳回项目管理'});
            $timeout(function(){
              $state.go("main.layoutProject")
            },3000)
        } else {
          $scope.$emit('notification', {type: 'danger', message: result.data.msg});
        }

      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
      });
     
    };

  }

]);