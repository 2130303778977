/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('configWifiCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state','$location','$stateParams',
  function ($scope, Upload, $http, $window, $timeout, $state,$location,$stateParams) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");
    
    $scope.upgrade = {showName: '', id: '', version: '', newVersion: ''};
    var loadTable = function (data, reload) {
        if (reload) {
          $('#table2').bootstrapTable('load', data);
        } else {
          $('#table2').bootstrapTable({
              pagination: true,
              pageSize: 10, //每页显示10条
              cache: false,
              search: true,
              showColumns: 'true',
              showExport: true,//显示导出按钮
              exportDataType: 'all',//导出类型
              columns: [{
                title: '配置名称',
                align: 'center',
                field: 'type',
                width:'180px',
                sortable: true,
                formatter: function (value, row, index) {
                  var msg = '未知配置';
                  switch (Number(value)) {
                    case 1:
                      msg = "路由系统固件";
                      break;
                    case 7:
                      msg = "智能家居设备固件";
                      break;
                    case 8:
                      msg = "智家语音面板固件";
                      break;
                    default:
                      break;
                  }
                  return msg;
                }
              }, {
                title: '版本号',
                align: 'center',
                field: 'version',
              },{
                title: 'md5',
                align: 'center',
                field: 'md5',
                formatter: function (value, row, index) {
                  return value==''?"无":value;
                }
              }, {
                title: '下载链接',
                align: 'center',
                field: 'url',
                formatter: function (value, row, index) {
                  return value==''?"无":value;
                }
              }, {
                title: '升级方式',
                align: 'center',
                field: 'force',
                formatter: function (value, row, index) {
                  return value == 1 ? "强制升级" : "手动升级"
                }
              }, {
                title: '强制升级时间',
                align: 'center',
                field: 'force',
                formatter: function (value, row, index) {
                  if(value==0){
                    return '-'
                  }
                  if(!row.force_time){
                    return '-'
                  }
                  if(row.force_time.type==0){
                    return "延时:"+row.force_time.delay+"秒"
                  }else{
                    var d_start = new Date(row.force_time.start);  
                    row.force_time.start = timeAdd0(d_start.getHours())+":"+timeAdd0(d_start.getMinutes());
                    var d_end = new Date(row.force_time.end);  
                    row.force_time.end = timeAdd0(d_end.getHours())+":"+timeAdd0(d_end.getMinutes());
                    return "时间段:"+row.force_time.start+"~"+row.force_time.end
                  }
                }
              }, {
                title: '更新说明',
                align: 'center',
                field: 'content',
                width:'100px',
                formatter: function (value, row, index) {
                  var result =  value.replace(/\n/g,'<br/>');
                  return result==''?"无":result
                }
              }, {
                title: '是否禁用',
                align: 'center',
                field: 'forbidden',
                formatter: function (value, row, index) {
                  return value=='1'?'<span style="color:red">禁用</span>':'<span style="color:green">启用</span>';
                }
              }, {
                title: '更新日期',
                align: 'center',
                field: 'updateTime',
                formatter: function (value, row, index) {
                  return moment(value, 'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm')
                }
              },{
                title: '操作',
                align: 'center',
                width:'120px',
                formatter: function (value, row, index) {
                  row = angular.toJson(row);
                  return '<button  class=\'btn btn-success btn-xs\' onclick=\'angular.element(this).scope().toUploadPage(' + row + ')\'>更新配置</button>'
                    +'<br><button style="margin-top:5px" class=\'btn btn-warning btn-xs\' onclick=\'angular.element(this).scope().showVersionModal(' + row + ')\'>修改版本</button>'
                    +'<br><button style="margin-top:5px"   class=\'btn btn-warning btn-xs\' onclick=\'angular.element(this).scope().toUUID(' + row + ')\'>录入UUID</button>'
                    +'<br><button style="margin-top:5px" class=\'btn btn-warning btn-xs\' onclick=\'angular.element(this).scope().showForbiddenModal(' + row + ')\'>禁用/启用</button>'
                }
              }
              ],
              data: data
            }
          );
        }
      }
    ;

    $scope.upgradeInit = function (reload) {
      if (!$stateParams.belongProject) {
        $scope.$emit('notification', {type: 'danger', message: '非法请求页面'});
        $scope.errorRequest = true;
        return;
      }
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if(element.nameEn==$stateParams.belongProject){
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
        }
      });
      $http({
        method: 'GET',
        url: '/web/configWifi/getWifi?projectCode='+$stateParams.belongProject,
      }).then(function (response) {
        if (response.data.code == 0) {
          loadTable(response.data.data, reload)
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

    $scope.toUploadPage = function (data) {
      $state.go('main.upgradeConfigWifi', {upgradeId: data._id, type: data.type,belongProject:$stateParams.belongProject})
    };
    
    $scope.toUUID = function (data) {
      $state.go('main.configWifiUuid', {belongProject:$stateParams.belongProject, type: data.type})
    };

    $scope.showVersionModal = function (data) {
      $scope.upgrade.id = data._id;
      $scope.upgrade.newVersion = '';
      switch (Number(data.type)) {
        case 1:
          $scope.upgrade.showName = "Wifi固件";
          break;
        case 2:
          $scope.upgrade.showName = "Android系统（2G版本）";
          break;
        case 6:
          $scope.upgrade.showName = "Android系统（1G版本）";
          break;
        default:
          $scope.upgrade.showName = '未知类型';
          break;
      }
      $scope.upgrade.version = data.version;
      $timeout(function () {
        $('#versionModal').modal('show')
      }, 10);
    };

    $scope.showForbiddenModal = function (data) {
      $scope.upgrade.id = data._id;
      switch (Number(data.type)) {
        case 1:
          $scope.upgrade.showName = "Wifi固件";
          break;
        case 2:
          $scope.upgrade.showName = "Android系统（2G版本）";
          break;
        case 6:
          $scope.upgrade.showName = "Android系统（1G版本）";
          break;
        default:
          $scope.upgrade.showName = '未知类型';
          break;
      }
      $scope.upgrade.newForbidden = '0';
      $scope.upgrade.forbidden = data.forbidden=='1'?'1':'0';

      $timeout(function () {
        $('#forbiddenModal').modal('show')
      }, 10);
    };

    $scope.doUpdate = function () {
      if (/[']/.test($scope.upgrade.newVersion)
      ) {
        $scope.$emit('notification', {type: 'danger', message: '内容包含英文的单引号，不允许提交!'});
        return;
      }
      if (!$scope.upgrade.newVersion) {
        $scope.$emit('notification', {type: 'danger', message: '请填写版本号'});
        return;
      }
      if(isNaN($scope.upgrade.newVersion)){
        $scope.$emit('notification', {type: 'danger', message: '版本号必须是数值'});
        return;
      }
      $http({
        method: 'PUT',
        url: '/web/configWifi/updateUpgrade',
        data: {
          id: $scope.upgrade.id,
          version:$scope.upgrade.newVersion,
          projectCode:$stateParams.belongProject,
        }
      }).then(function (response) {
        $timeout(function () {
          $('#versionModal').modal('hide')
        }, 10);

        if (response.data.code == 0) {
          $scope.upgradeInit(true);
          $scope.$emit('notification', {type: 'success', message: '修改成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
      
    };

    $scope.doUpdateForbidden = function () {
      $http({
        method: 'PUT',
        url: '/web/configWifi/updateUpgrade',
        data: {
          id: $scope.upgrade.id,
          forbidden:$scope.upgrade.newForbidden,
          projectCode:$stateParams.belongProject,
        }
      }).then(function (response) {
        $timeout(function () {
          $('#forbiddenModal').modal('hide')
        }, 10);

        if (response.data.code == 0) {
          $scope.upgradeInit(true);
          $scope.$emit('notification', {type: 'success', message: '修改成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

    function timeAdd0(str) {
      str = str+'';
      if(str.length<=1){
          str='0'+str;
      }
      return str
    }
  }
])
;