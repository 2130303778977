/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('hotelFloorManageCtr', ['$scope', 'Upload', '$http', '$window', '$timeout','$state','$stateParams',
  function ($scope, Upload, $http, $window, $timeout,$state,$stateParams) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");

    $scope.plug = {
      name: '',
      id: '',
      install_cmd:''
    };

    var loadTable = function (data, reload) {
      if (reload) {
        $('#table2').bootstrapTable('load', data);
      } else {
        $('#table2').bootstrapTable({
          pagination: true,
          pageSize: 10, //每页显示10条
          cache: false,
          search: true,
          showColumns: 'true',
          showExport: true,//显示导出按钮
          exportDataType: 'all',//导出类型
          columns: [{
            title: '楼层名称',
            align: 'center',
            field: 'floorName',
            sortable: true
          },{
            title: '创建时间',
            align: 'center',
            field: 'updateTime',
            sortable: true,
            formatter: function (value, row, index) {
              return moment(value, 'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm')
            }
          },{
            title: '操作',
            align: 'center',
            width:'120px',
            formatter: function (value, row, index) {
              var jsonRow = angular.toJson(row);
              return '<button style="" class=\'btn btn-danger btn-xs\' onclick=\'angular.element(this).scope().delPlugModal('+jsonRow+')\'>删除配置</button>';
              
            }
          }],
          data: data
        });
      }
    };
    $scope.addFloor = function (){
      $timeout(function () {
        $('#floorModal').modal('show')
      }, 10);
    }
    $scope.plugInit = function (reload) {
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if(element.nameEn==$stateParams.belongProject){
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
        }
      });
      $http({
        method: 'GET',
        url: '/web/hotel/getHotelFloorList?belongProject='+$stateParams.belongProject,
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.floor_list = response.data.data;

          loadTable(response.data.data, reload)
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

    $scope.updatePlug = function (data) {
      $state.go('main.upgradeConfigPlug', {upgradeId:data._id,belongProject:$stateParams.belongProject})
    };

    $scope.doResetPwd = function () {
      $http({
        method: 'PUT',
        url: '/web/account/resetPwd',
        data: {
          phone: $scope.user.phone
        }
      }).then(function (response) {
        $timeout(function () {
          $('#resetModal').modal('hide')
        }, 10);

        if (response.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '重置成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };
    $scope.delFloorName = "";
    $scope.delFloorId = "";
    $scope.delPlugModal = function (data) {
      $scope.delFloorName = data.floorName;
      $scope.delFloorId = data._id;
      $timeout(function () {
        $('#delModal').modal('show')
      }, 10);
    };
  
   
    $scope.floor_list =[""];
    $scope.submitFloors = function(){
      $http({
        method: 'POST',
        url: '/web/hotel/uploadHotelFloor',
        data: {
          floorList:$scope.floor_list,
          belongProject:$scope.curProjectNameEn
        }
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.plugInit(true);
          $scope.$emit('notification', {type: 'success', message: '修改成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    }
    $scope.addItem = function(){
      $scope.floor_list.push({floorName:""});
    }
    $scope.delItem = function(index){
      $scope.floor_list.splice(index,1);
    }

    $scope.doDelFloor = function () {
      $http({
        method: 'DELETE',
        url: '/web/hotel/delFloor',
        params: {
          id: $scope.delFloorId
        }
      }).then(function (response) {
        $timeout(function () {
          $('#delModal').modal('hide')
        }, 10);

        if (response.data.code == 0) {
          $scope.plugInit(true);
          $scope.$emit('notification', {type: 'success', message: '删除成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

  }
]);