/**
 * Created by Administrator on 2017/7/12.
 */

'use strict';


angular.module('controllers').controller('upgradeConfigWifiUuidCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state', '$stateParams','uploadFileService',
  function ($scope, Upload, $http, $window, $timeout, $state, $stateParams,uploadFileService) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");
    var subscription = null;
    $scope.checkState = function () {
      if (!$stateParams.belongProject||!$stateParams.type) {
        $scope.$emit('notification', {type: 'danger', message: '非法请求页面'});
        $scope.errorRequest = true;
        return;
      }
      $scope.fileType = $stateParams.type;
      $scope.upgrade = {
        type:$stateParams.type,
        projectCode:$stateParams.belongProject,
        uuid_list:[""],
        uuid_type:0,
        md5: '',
        version: '',
        fileName: '',
        force: 0,
        max_force: 0,
        content: '',
        force_time:{
          type:0,
          delay:0,
          start:'',
          end:''
        }
      };
      if($stateParams.itemId){
        $http({
          method: 'Post',
          url: '/web/configWifiUuid/findOne',
          data:{data:{_id:$stateParams.itemId}},
        }).then(function (response) {
            if (response.data.code === 0) {
              $scope.upgrade = response.data.data;
              if($scope.upgrade.force_time.type==1){
                $scope.upgrade.force_time.start = new Date($scope.upgrade.force_time.start);  
                $scope.upgrade.force_time.end = new Date($scope.upgrade.force_time.end);  
              }
              console.log($scope.upgrade);
              
            }
        }, function (err) {
            $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
        })
      }
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if(element.nameEn==$stateParams.belongProject){
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
          $http({
            method: 'GET',
            url: '/config/getWebConfig',
          }).then(function (response) {
              if (response.data.code === 0) {
                  $scope.domain = response.data.domain+"/"+$scope.curProjectNameEn;
                  $scope.uploadToken = response.data.uploadToken;
                  $scope.fileSaveWay = response.data.fileSaveWay;
              }
          }, function (err) {
              $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
          })
        }
      });
    };
    function timeAdd0(str) {
      str = str+'';
      if(str.length<=1){
          str='0'+str;
      }
      return str
    }
    $scope.uploadFile = function () {
      let files = [];
      let nickName;
      if (/[']/.test($scope.upgrade.version)
      ||/[']/.test($scope.upgrade.content)
      ) {
        $scope.$emit('notification', {type: 'danger', message: '内容包含英文的单引号，不允许提交!'});
        return;
      }
      if (!$scope.upgrade.version&&($scope.upgrade.type==1||$scope.upgrade.type == 7||$scope.upgrade.type == 8
        ||$scope.upgrade.type == 2||$scope.upgrade.type == 6)) {
        $scope.$emit('notification', {type: 'danger', message: '请填写版本号'});
        return;
      }
      if (!$scope.upgrade.subVersion&&$scope.upgrade.type==1) {
        $scope.$emit('notification', {type: 'danger', message: '请选择系统版本'});
        return;
      }
      // if(isNaN($scope.upgrade.version)){
      //   $scope.$emit('notification', {type: 'danger', message: '版本号必须是数值'});
      //   return;
      // }
      if(!$scope.upgrade.file){
        if(!$scope.upgrade.fileName){
          $scope.$emit('notification', {type: 'danger', message: '请选择上传的文件'});
          return;
        }
      }else{
        if(isChinese($scope.upgrade.file.name)){
          $scope.$emit('notification', {type: 'danger', message: '文件名不能包含中文'});
          return;
        }
        if($scope.upgrade.type==1||$scope.upgrade.type == 7||$scope.upgrade.type == 8){
          var check = new RegExp(".bin$", 'i').test($scope.upgrade.file.name);
          if (!check) {
            $scope.$emit('notification', {type: 'danger', message: '上传文件不是bin格式'});
            return
          }
        }
        nickName = "upgradefile_wifibin"+$scope.getTime()+"_" + getFileType($scope.upgrade.file.name);
        $scope.upgrade.fileName = nickName;
        $scope.upgrade.url = $scope.domain+"/"+nickName;
        $scope.upgrade.file.nickName = nickName;
        files.push($scope.upgrade.file);
      }

      if ($scope.upgrade.plug) {
        if(/[']/.test($scope.upgrade.plug.name)){
          $scope.$emit('notification', {type: 'danger', message: '脚本文件名不能包含英文单引号'});
          return;
        }
        let nickName = "upgradefile_plug" + $scope.getTime() + "_" + getFileType($scope.upgrade.plug.name);
        $scope.upgrade.plugName =nickName;
        $scope.upgrade.plugUrl = $scope.domain + "/" + nickName;
        $scope.upgrade.plug.nickName = nickName;
        files.push($scope.upgrade.plug);
      }
      if($scope.upgrade.uuid_type==1){
        if (/[']/.test($scope.upgrade.uuid_start)
        ||/[']/.test($scope.upgrade.uuid_end)
        ) {
          $scope.$emit('notification', {type: 'danger', message: '内容包含英文的单引号，不允许提交!'});
          return;
        }
        if(!$scope.upgrade.uuid_start){
          $scope.$emit('notification', {type: 'danger', message: '请填写起始UUID'});
          return;
        }
        if(!$scope.upgrade.uuid_end){
          $scope.$emit('notification', {type: 'danger', message: '请填写终止UUID'});
          return;
        }
        $scope.upgrade.uuid_list = [""];
      }else{
        if($scope.upgrade.uuid_list.length==0){
          $scope.$emit('notification', {type: 'danger', message: 'UUID列表不能为空'});
            return;
        }
        for (var i_uuid_list = 0; i_uuid_list<$scope.upgrade.uuid_list.length;i_uuid_list++) {
          let element = $scope.upgrade.uuid_list[i_uuid_list];
          console.log(element);
          if (/[']/.test(element)
          ) {
            $scope.$emit('notification', {type: 'danger', message: 'uuid包含英文的单引号，不允许提交!'});
            return;
          }
          if(!element){
            $scope.$emit('notification', {type: 'danger', message: '请填写uuid'});
            return;
          }
        }
        $scope.upgrade.uuid_start = '';
        $scope.upgrade.uuid_end = '';
      }
      var data = {
        _id:$scope.upgrade._id,
        type: $scope.upgrade.type,
        md5: $scope.upgrade.md5,
        fileName: $scope.upgrade.fileName,
        plugName: $scope.upgrade.plugName,
        plugUrl: $scope.upgrade.plugUrl,
        content: $scope.upgrade.content,
        url: $scope.upgrade.url,
        uuid_type:$scope.upgrade.uuid_type,
        uuid_start:$scope.upgrade.uuid_start,
        uuid_end:$scope.upgrade.uuid_end,
        uuid_list:$scope.upgrade.uuid_list,
        projectCode:$scope.upgrade.projectCode,
      };
      if (data.type == 1|| data.type == 7 ||data.type == 8) {
        data.subVersion = $scope.upgrade.subVersion;
        data.version = $scope.upgrade.version;
        data.force = $scope.upgrade.force;
        data.force_time = {
          type:$scope.upgrade.force_time.type,
          delay:$scope.upgrade.force_time.delay
        }
        if($scope.upgrade.force_time.start!=''){
          data.force_time.start = $scope.upgrade.force_time.start;
          // var d_start = new Date($scope.upgrade.force_time.start);  
          // data.force_time.start = timeAdd0(d_start.getHours())+":"+timeAdd0(d_start.getMinutes());
        }
        if($scope.upgrade.force_time.end!=''){
          data.force_time.end = $scope.upgrade.force_time.end;
          // var d_end = new Date($scope.upgrade.force_time.end); 
          // data.force_time.end = timeAdd0(d_end.getHours())+":"+timeAdd0(d_end.getMinutes());
        }
      }
      console.log(data);
      if (data.type == 2||data.type == 6) {
        data.version = $scope.upgrade.version;
        data.force = $scope.upgrade.force;
      }
      uploadFileService.cancelUpload($scope);
      uploadFileService.uploadFile($scope,$scope.upgrade.projectCode,files,data,finishUploadCallBack);
    };
    
    function finishUploadCallBack(data,uploadResData){
      console.log(data);
      console.log(uploadResData);
      if(uploadResData&&uploadResData.length>0){
        for (let index = 0; index < uploadResData.length; index++) {
          const element_uploadResData = uploadResData[index];
          if(element_uploadResData.md5&&element_uploadResData.newName
            &&element_uploadResData.newName.indexOf("upgradefile_wifibin")!=-1){
            data.md5 = element_uploadResData.md5;
            break;
          }
        }
       
      }
      
      $http({
        method: 'POST',
        url: '/web/configWifiUuid/newUpgradeFile',
        data: data
      }).then(function (response) {
        if (response.data.code === 0) {
            $scope.$emit('notification', {type: 'success', message: '成功'});
            $timeout(function () {
              $scope.backUrl();
            }, 1000)
        } else {
          $('#progress')[0].style.width = '0%';
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })
    }
  
    $scope.cancelUpload = function () {
      uploadFileService.cancelUpload($scope);
    }
    $scope.addUuid = function(){
      $scope.upgrade.uuid_list.push("");
    }
    $scope.delUuid = function(index){
      $scope.upgrade.uuid_list.splice(index,1);
    }
    $scope.backUrl = function(){
      $state.go('main.configWifiUuid', {belongProject:$stateParams.belongProject,type:$stateParams.type})
    }
    var isChinese = function(temp){
      if(/.*[\u4e00-\u9fa5]+.*$/.test(temp)) {
            return true;
          } 
          return false;
    }
    $scope.getTime = function(){
      return Date.now();
    }
    var getFileType = function(name){
      return name.substring(name.lastIndexOf("."));
    }
  }
]);