/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('approvalProcessDetailCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state','$stateParams',
  function ($scope, Upload, $http, $window, $timeout, $state,$stateParams) {
    
    $scope.init = function (reload) {
      showProjectName();
      initData();
    };
    
    var initData = function(){
      $scope.approvalProcessState= {
        stateStr:"",
        endTimeStr:""
      }
      $scope.approvalProcessList=[];
      $http({
        method: 'POST',
        url: '/web/approvalProcess/findOne',
        data: {
          _id:$stateParams.dataId,
        }
      }).then(function (response) {
        if (response.data.code === 0) {
          try {
            $scope.approvalProcess = response.data.data;
            var state = $scope.approvalProcess.state;
            var stateStr = "";
            if(state=="0"){
              stateStr = "待完成"
            }else if(state=="1"){
              stateStr = "通过"
            }else if(state=="-1"){
              stateStr = "待完成"
            }else if(state=="-2"){
              stateStr = "已撤销"
            }
            $scope.approvalProcessState.stateStr = stateStr;
            $scope.approvalProcessState.endTimeStr = $scope.approvalProcess.endTime?moment($scope.approvalProcess.endTime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm'):"";

            $scope.approvalProcessList.push({
              title:$scope.approvalProcess.userPromoter+"发起页面审核申请",
              titleTime:moment($scope.approvalProcess.startTime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm')
            })
            var reviewerTmp = null;
            var titleTmp = ""
            for (let index = 0; index < $scope.approvalProcess.reviewer.length; index++) {
              const element_reviewer = $scope.approvalProcess.reviewer[index];
              var state ="";
              if(element_reviewer.state=="0"){
                state = "（审核中）";
              }else if(element_reviewer.state=="1"){
                state = "（通过）";
              }else if(element_reviewer.state=="-1"){
                state = "（未通过）";
              }
              titleTmp = titleTmp+(index==0?"":",")+ element_reviewer.userName+""+state;
              if(element_reviewer.state!="0"){
                reviewerTmp = {
                  title:"",
                  titleTime:moment(element_reviewer.startTime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm'),
                  comment:element_reviewer.reply,
                  commentTime:moment(element_reviewer.endTime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm'),
                }
              }
            }
            if(reviewerTmp!=null){
              reviewerTmp.title = titleTmp;
            }else{
              reviewerTmp = {
                title:titleTmp,
                titleTime:moment($scope.approvalProcess.startTime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm'),
              }
            }
            $scope.approvalProcessList.push(reviewerTmp);
          } catch (error) {
            console.log(error);
          }
        }
      });
    }
    var showProjectName = function() {
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if(element.nameEn==$stateParams.belongProject){
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
        }
      });
    }
   




  }
])
;