/**
 * Created by Administrator on 2017/7/12.
 */

'use strict';
angular.module('controllers').controller('addUserCtr', ['$scope', 'Upload', '$http', '$window','$timeout','$state','$stateParams',
  function ($scope, Upload, $http, $window,$timeout,$state,$stateParams) {

    
    $scope.checkState = function () {
      $scope.projectPower = "all";
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
          if (response.data.code === 0) {
              $scope.belongs = response.data.layoutProject;
              $scope.activitys = response.data.layoutActivity;
              if($stateParams.userId){
                $http({
                  method: 'POST',
                  url: '/web/user/findUser',
                  data: {
                    id:$stateParams.userId
                  }
                }).then(function (response) {
                  if (response.data.code == 0) {
                    console.log(response.data.data);
                    $scope.webUser = response.data.data;
                    if($scope.webUser.belongProject&&$scope.webUser.belongProject.length>0){
                      $scope.projectPower = "notAll";
                      for (let index = 0; index < $scope.belongs.length; index++) {
                        const element_belongs = $scope.belongs[index];
                        for (let index = 0; index < $scope.webUser.belongProject.length; index++) {
                          const element_belongProject = $scope.webUser.belongProject[index];
                          if(element_belongs._id==element_belongProject._id){
                            element_belongs.isChoice = true;
                            break;
                          }
                        }
                      }
                    }
                  } else {
                    $scope.$emit('notification', {type: 'danger', message: response.data.msg});
                  }
                }, function (err) {
                  $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
                });
              }else{
                $scope.webUser = {
                  plugManage:0,
                  layoutPageManage:0,
                  userManage:0,
                  adUpload: 0,
                  wifiUpload:0,
                  androidUpload:0,
                  projectInfoConfigManage:0,
                  projectGatewayManage:0
                };
              }
          } else {
            $scope.$emit('notification', {type: 'danger', message: response.data.msg});
          }
      }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })
    };
    $scope.resetPwd = function(){
      
      $scope.webUser.pwd = "HNyx@123456";
      $http({
        method: 'POST',
        url: '/web/user/resetPwd',
        data: {
          id:$stateParams.userId,
          pwd: $scope.webUser.pwd,
        }
      }).then(function (result) {
        if (result.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '重置密码成功'});
           
        } else {
          $scope.$emit('notification', {type: 'danger', message: result.data.msg});
        }

      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
      });
    }
    var isChinese = function(temp){
      if(/.*[\u4e00-\u9fa5]+.*$/.test(temp)) {
            return true;
          } 
          return false;
    }
    //提交数据
    $scope.submit = function () {
      if (/[']/.test($scope.webUser.name)
      ){
        $scope.$emit('notification', {type: 'danger', message: '用户名包含英文的单引号，不允许提交!'});
        return;
      }
      if(isChinese($scope.webUser.name)){
        $scope.$emit('notification', {type: 'danger', message: '用户名不能包含中文'});
        return;
      }
      if (!$scope.webUser.name) {
        $scope.$emit('notification', {type: 'danger', message: '请填写用户名'});
        return;
      }
      
      if(!$scope.webUser.id){
        $scope.webUser.pwd = "HNyx@123456";
      }
      $scope.webUser.belongProject = [];
      if($scope.projectPower == "notAll"){
        for (let index = 0; index < $scope.belongs.length; index++) {
          const element_belongs = $scope.belongs[index];
          if(element_belongs.isChoice){
            $scope.webUser.belongProject.push(element_belongs._id);
          }
          
        }
      }
      console.log($scope.webUser);
      $http({
        method: 'POST',
        url: '/web/user/uploadUser',
        data: $scope.webUser
      }).then(function (result) {
        if (result.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '提交成功,2秒后跳回用户列表'});
            $timeout(function(){
              $state.go("main.user")
            },3000)
        } else {
          $scope.$emit('notification', {type: 'danger', message: result.data.msg});
        }

      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
      });
     
    };

  }

]);