/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('statisticGatewayCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state',
  function ($scope, Upload, $http, $window, $timeout, $state) {

    var loadTable = function () {
      $('#table2').bootstrapTable({
        method : 'get',
        url : "/web/statistic/getAllGateway",//请求路径
        striped : true, //是否显示行间隔色
        pagination : true,//是否分页
        sidePagination : 'server',//server:服务器端分页|client：前端分页
        pageSize : 10,//单页记录数
        pageList : [ 5, 10, 20, 30 ],//可选择单页记录数
        showRefresh : true,//刷新按钮
        cache: false,
        search: true,
        showColumns: 'true',
        showExport: true,//显示导出按钮
        exportDataType: 'all',//导出类型
        queryParams : function(params) {//上传服务器的参数
          var temp = {//如果是在服务器端实现分页，limit、offset这两个参数是必须的
            limit : params.limit, // 每页显示数量
            //offset : params.offset, // SQL语句起始索引
            page : (params.offset / params.limit) + 1, //当前页码 
            searchName : $('.search input').val(),
            order : params.order, 
            sort : params.sort,
          };
          return temp;
        },
        columns: [{
          title: '归属项目',
          sortable: true,
          align: 'center',
          field: 'belongProject',
        },{
          title: '当前项目',
          sortable: true,
          align: 'center',
          field: 'curBindProject',
        }, {
          title: 'UUID',
          sortable: true,
          align: 'center',
          field: 'uuid',
          width: '120px'
        }, {
          title: 'wifi版本',
          sortable: true,
          align: 'center',
          field: 'version_wifi',
        }, {
          title: 'android版本',
          sortable: true,
          align: 'center',
          field: 'version_android',
        }, {
          title: '最新更新时间',
          sortable: true,
          align: 'center',
          field: 'lastOnlineTime',
          formatter: function (value, row, index) {
            return moment(value, 'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm')
          }
        }, {
          title: '最近Wifi版本上报时间',
          align: 'center',
          field: 'lastWifiUpdateTimes',
          formatter: function (value, row, index) {
            if(!value)return '';
            let result='';
            for (let index = 0; index < value.length; index++) {
              const element = value[index];
              result +='<div>'+ moment(element, 'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm')+'</div>'
            }
            return result;
          }
        }, {
          title: '最近Android版本上报时间',
          align: 'center',
          field: 'lastAndroidUpdateTimes',
          formatter: function (value, row, index) {
            if(!value)return '';
            let result='';
            for (let index = 0; index < value.length; index++) {
              const element = value[index];
              result +='<div>'+ moment(element, 'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm')+'</div>'
            }
            return result;
          }
        }]
      });
    }

    $scope.toDetail = function(id){
      //$state.go('main.statisticFileDetail',{itemId:id})
    }

    $scope.upgradeInit = function (reload) {
      loadTable()
    };

  }
])
;