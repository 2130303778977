/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('configProjectInfoCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state','$location','$stateParams',
  function ($scope, Upload, $http, $window, $timeout, $state,$location,$stateParams) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");
    
    $scope.upgrade = {showName: '', id: '', version: '', newVersion: ''};
    let program=""
    var loadTable = function (data, reload) {
        if (reload) {
          $('#table2').bootstrapTable('load', data);
        } else {
          $('#table2').bootstrapTable({
              pagination: true,
              pageSize: 10, //每页显示10条
              cache: false,
              search: true,
              showColumns: 'true',
              showExport: true,//显示导出按钮
              exportDataType: 'all',//导出类型
              columns: [{
                title: 'wifi名称',
                align: 'center',
                field: 'wifiSSid',
                formatter: function (value, row, index) {
                  return (!value||value=='')?"未设置":value+'网关的用户编号信息';
                }
              }, {
                title: 'wifi密码',
                align: 'center',
                field: 'wifiPwd',
                formatter: function (value, row, index) {
                  return (!value||value=='')?"未设置":value;
                }
              }, {
                title: '是否禁用',
                align: 'center',
                field: 'forbidden',
                formatter: function (value, row, index) {
                  return value=='1'?'<span style="color:red">禁用</span>':'<span style="color:green">启用</span>';
                }
              }, {
                title: '更新日期',
                align: 'center',
                field: 'updateTime',
                formatter: function (value, row, index) {
                  return moment(value, 'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm')
                }
              },{
                title: '操作',
                align: 'center',
                width:'120px',
                formatter: function (value, row, index) {
                  row = angular.toJson(row);
                  return '<button  class=\'btn btn-success btn-xs\' onclick=\'angular.element(this).scope().toUploadPage(' + row + ')\'>更新配置</button>'
                  +'<br><button style="margin-top:5px" class=\'btn btn-warning btn-xs\' onclick=\'angular.element(this).scope().showForbiddenModal(' + row + ')\'>禁用/启用</button>';
                }
              }
              ],
              data: data
            }
          );
        }
      }
    ;
    var loadTable2 = function (data, reload) {
      if (reload) {
        $('#table3').bootstrapTable('load', data);
      } else {
        $('#table3').bootstrapTable({
            pagination: true,
            pageSize: 10, //每页显示10条
            cache: false,
            search: true,
            showColumns: 'true',
            showExport: true,//显示导出按钮
            exportDataType: 'all',//导出类型
            columns: [{
              title:'使用列表',
              align:'center',
              field:'type',
              formatter:function(value,row,index){
                let str = '';
                if(value==1){
                  str = "黑名单\\新增列表";
                }else if(value==2){
                  str = "白名单";
                }else if(value==3){
                  str = "自定义列表";
                }else if(value==4){
                  str = "多功能列表(新)";
                }
                return str;
              }
            },{
              title: '默认节目',
              align: 'center',
              field: 'defaultChnlOnPower',
              formatter: function (value, row, index) {
                let str = '';
                if(value&&value!=""){
                  if(isExist(value.chnl_num)&&isExist(value.chnl_name)){
                    str = str+'<div>'+value.chnl_num+'-'+value.chnl_name+'</div>';
                  }else if(isExist(value.chnl_num)){
                    str = str+'<div>'+value.chnl_num+'</div>';
                  }else if(isExist(value.chnl_name)){
                    str = str+'<div>'+value.chnl_name+'</div>';
                  }else{
                    str = "未配置";
                  }
                }else{
                  str = "未配置";
                }
                return str;
              }
            },{
              title: '操作',
              align: 'center',
              width:'120px',
              formatter: function (value, row, index) {
                row = angular.toJson(row);
                return '<button class=\'btn btn-success btn-xs\' onclick=\'angular.element(this).scope().toUpgradeAllProgram(' + row + ')\'>配置节目</button>';
              }
            }
            ],
            data: data
          }
        );
      }
    }
    ;
    function isExist(str) {
      return str!=undefined&&str!='';
    }
    var loadTable5 = function(data,reload){
      if(reload){
        $('#table6').bootstrapTable('load',data);
      }else{
        $('#table6').bootstrapTable({
          pagination:true,
          pageSize:10,
          cache:false,
          search:true,
          showColumns:'true',
          showExport:true,
          exportDataType:'all',
          columns:[{
            title:'直播播放器',
            align:'center',
            field:'livePlayer',
            formatter: function (value, row, index) {
              let str = "";
              if(value==0){
                str = "原生播放器";
              }else if(value==1){
                str = "内置播放器";
              }else if(value==2){
                str = "测试播放器";
              }else{
                str = "未设置";
              }
              return str;
            }
          },{
            title:'点播播放器',
            align:'center',
            field:'onDemandPlayer',
            formatter: function (value, row, index) {
              let str = "";
              if(value==0){
                str = "原生播放器";
              }else if(value==1){
                str = "内置播放器";
              }else{
                str = "未设置";
              }
              return str;
            }
          },{
            title:'解码方式',
            align:'center',
            field:'codec',
            formatter: function (value, row, index) {
              let str = "";
              if(value==0){
                str = "软解";
              }else if(value==1){
                str = "硬解";
              }else{
                str = "未设置";
              }
              return str;
            }
          },{
            title:'播放方式',
            align:'center',
            field:'playingMode',
            formatter: function (value, row, index) {
              let str = "";
              if(value==0){
                str = "组播优先";
              }else if(value==1){
                str = "单播优先";
              }else{
                str = "未设置";
              }
              return str;
            }
          },{
            title:'缓冲时间',
            align:'center',
            field:"bufferTime",
            formatter: function (value, row, index) {
              let str = "";
              if(value==-1){
                str = "未设置";
              }else{
                str = value;
              }
              return str;
            }
          },{
            title:'鉴权方式',
            align:'center',
            field:"dvbAuthorizeType",
            formatter:function(value,row,index){
              let str = "";
              if(value==2){
                str = "测试账号鉴权";
              }else if(value==1){
                str = "MAC鉴权";
              }else{
                str = "未设置";
              }
              return str;
            }
          },{
            title:'鉴权方式(新)',
            align:'center',
            field:"dvbAuthorizeTypeNew",
            formatter:function(value,row,index){
              let str = "";
              if(value==2){
                str = "测试账号鉴权";
              }else if(value==1){
                str = "MAC鉴权";
              }else{
                str = "未设置";
              }
              return str;
            }
          },{
            title:'是否禁用',
            align:'center',
            field:"updata",
            formatter:function(value,row,index){
              return value==false?'<span style="color:red">禁用</span>':'<span style="color:green">启用</span>';
            }
          }
          ,{
            title: '操作',
            align: 'center',
            width:'120px',
            formatter: function (value, row, index) {
              row = angular.toJson(row);
              return '<button  class=\'btn btn-success btn-xs\' onclick=\'angular.element(this).scope().toUpPlayerSettings(' + row + ')\'>软终端设置</button>'
              +'<br><button style="margin-top:5px" class=\'btn btn-warning btn-xs\' onclick=\'angular.element(this).scope().showForbiddenModalTow(' + row + ')\'> 禁用 / 启用 </button>';
            }
          }],
          data:data
        })
      }
    }

    $scope.upgradeInit = function (reload) {
      if (!$stateParams.belongProject) {
        $scope.$emit('notification', {type: 'danger', message: '非法请求页面'});
        $scope.errorRequest = true;
        return;
      }
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if(element.nameEn==$stateParams.belongProject){
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
        }
      });
      $http({
        method: 'GET',
        url: '/web/configProjectInfo/getAll?projectCode='+$stateParams.belongProject,
      }).then(function (response) {
        if (response.data.code == 0) {
          loadTable(response.data.data, reload)
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });

      $http({
        method: 'GET',
        url: '/web/program/getAll?projectCode='+$stateParams.belongProject,
      }).then(function (response) {
        if (response.data.code == 0) {
          loadTable2(response.data.data,reload);
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });

      $http({
        method:'GET',
        url:'/web/playerSettings/getPlayerSettings?projectCode='+$stateParams.belongProject,
      }).then(function(response){
        if(response.data.code==0){
          loadTable5(response.data.data,reload);
        }else{
          $scope.$emit('notification',{type:'danger',message:response.data.msg});
        }
      },function(err){
        $scope.$emit('notification',{type:'danger',message:'网络错误，与服务器通信错误'});
      });
    };

    $scope.toUploadPage = function (data) {
      $state.go('main.upgradeConfigProjectInfo', {upgradeId: data._id,belongProject:$stateParams.belongProject})
    };

    $scope.toUploadProgram = function (data) {
      $state.go('main.upgradeProgram',{programID:data._id,belongProject:$stateParams.belongProject})
    };

    $scope.toUpgradeAllProgram = function(data){
      if(data){
        $state.go('main.upgradeAllProgram', {programId:data._id,belongProject:$stateParams.belongProject})
      }else{
        $state.go('main.upgradeAllProgram',{belongProject:$stateParams.belongProject})
      }
    }

    $scope.toUpPlayerSettings = function(data){
      $state.go('main.upgradePlayerSettings',{playerSettingsID:data._id,belongProject:$stateParams.belongProject})
    };
    $scope.toUpPlayer = function(data){
      $state.go('main.upgradePlayerSettings2',{playerSettingsID:data._id,belongProject:$stateParams.belongProject})
    };

    $scope.showForbiddenModal = function (data) {
      $scope.plug={}
      $scope.plug.id = data._id;
      $scope.plug.newForbidden = '0';
      $scope.plug.forbidden = data.forbidden=='1'?'1':'0';
      $timeout(function () {
        $('#forbiddenModal').modal('show')
      }, 10);
    };

    $scope.doUpdateForbidden = function () {
      $http({
        method: 'POST',
        url: '/web/configProjectInfo/changeForbidden',
        data: {
          id: $scope.plug.id,
          forbidden:$scope.plug.newForbidden,
          projectCode:$stateParams.belongProject,
        }
      }).then(function (response) {
        $timeout(function () {
          $('#forbiddenModal').modal('hide')
        }, 10);

        if (response.data.code == 0) {
          $scope.upgradeInit(true);
          $scope.$emit('notification', {type: 'success', message: '修改成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

    $scope.showForbiddenModalTow = function(data){
      $scope.playerSettings = {};
      $scope.playerSettings.id = data._id;
      $scope.playerSettings.newUpdata ='true';
      $scope.playerSettings.updata = data.updata;
      $timeout(function(){
        $('#forbiddenModalTow').modal('show')
      },10);
    };

    $scope.usePlayerSettings = function(){
      let data = {
        playerSettingsID: $scope.playerSettings.id,
        updata: $scope.playerSettings.newUpdata
      };
      $http({
        method: 'POST',
        url: '/web/playerSettings/usePlayerSettings',
        data: data,
      }).then(function(response){
        $timeout(function(response){
          $('#forbiddenModalTow').modal('hide')
        },10);
        if(response.data.code==0){
          $scope.upgradeInit(true);
          if(data.updata=="true"){
            $scope.$emit('notification',{type: 'success',message:'启用成功'});
          }else{
            $scope.$emit('notification',{type: 'success',message:'禁用成功'});
          }
        }else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      },function(){
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

  }
])
;