'use strict';

angular.module('controllers').controller('upgradeLayoutPageCtr', ['$sce', '$scope', 'Upload', '$http', '$window', '$timeout', '$state', '$stateParams', '$compile', 'uploadFileService',
  function ($sce, $scope, Upload, $http, $window, $timeout, $state, $stateParams, $compile, uploadFileService) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");

    $scope.otherPages = [];
    $scope.cardTypes = [
      {
        nameCn: "普通卡片",
        nameEn: "card"
      }, {
        nameCn: "时间卡片",
        nameEn: "clockView"
      }, {
        nameCn: "电视号卡片",
        nameEn: "caTvNumView"
      }, {
        nameCn: "直播窗口",
        nameEn: "dvbView"
      }, {
        nameCn: "视频窗口",
        nameEn: "videoView"
      }, {
        nameCn: "广告轮播图",
        nameEn: "bannerView"
      }, {
        nameCn: "节目列表",
        nameEn: "programListView"
      }, {
        nameCn: "不可选中",
        nameEn: "unfocused"
      },
    ];
    $scope.clickTypes = [
      {
        nameCn: "无点击事件",
        nameEn: "blank"
      },
      {
        nameCn: "打开应用",
        nameEn: "openApp"
      },
      {
        nameCn: "打开其他页",
        nameEn: "openOtherPage"
      },
      {
        nameCn: "执行特殊指令",
        nameEn: "exeCommand"
      },
      {
        nameCn: "打开点播",
        nameEn: "openPointWeb"
      },
      {
        nameCn: "打开首页",
        nameEn: "openHomePage"
      }
    ];
    $scope.titleTypes = [
      {
        key: "文字样式1",
        value: "oval2"
      },
      {
        key: "文字样式2",
        value: "oval"
      },
      {
        key: "文字样式(下划线)",
        value: "textStyle"
      },
      {
        key: "图文样式",
        value: "textPicStyle"
      }
    ];
    
    $scope.exeCommandList = [
      {
        nameCn: "其他指令",
        nameEn: 'otherExeCommand'
      },
      {
        nameCn: "打开直播页面",
        nameEn: "page_dvb"
        
      },
      {
        nameCn: "视频窗口卡牌全屏播放",
        nameEn: "page_video_full_screen"
      },
      {
        nameCn: "打开我的应用页面",
        nameEn: "page_apps"
      },
      
      {
        nameCn: "打开智能家居页面",
        nameEn: "page_smart_home"
      },
      {
        nameCn: "回到瀑布流页面的顶部",
        nameEn: "function_back_to_top"
      },
      {
        nameCn: "根据标题搜索片子(腾讯视频)",
        nameEn: "page_search_by_title"
      },
      {
        nameCn: "打开我的页面(设置)",
        nameEn: "page_setting"
      },
      {
        nameCn: "打开二维码页面",
        nameEn: "page_qr_code"
      },
      {
        nameCn: "打开WIFI页面",
        nameEn: "page_wifi"
      },
      {
        nameCn: "打开添加APP页面",
        nameEn: "custom_app"
      }
    ];
    var pageParam = {}
    var adjustParam = 0;
    $scope.init = function () {
      var ui = document.getElementsByClassName("gridster");
      ui[0].style.display = "none";
      $scope.newLayout = {
        name: '',
        nameEng:'',
        belongProject: '',
        belongActivity: '',
        layoutType: 'absolute',
        pageParam: pageParam,
        bgPic: {},
        items: [],
        minUsedVer: ''
      }


      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          $scope.activitys = response.data.layoutActivity;
          $timeout(function () {
            var oDiv = document.getElementById("fixPara"),
              H = 0,
              Y = oDiv
            while (Y) {
              H += Y.offsetTop;
              Y = Y.offsetParent;
            }
            window.onscroll = function () {
              var s = document.body.scrollTop || document.documentElement.scrollTop
              if (s > H) {
                oDiv.style = "position:fixed;top:50px;"
              } else {
                oDiv.style = ""
              }
            }

            if ($stateParams.itemId) {//布局页面Id一定存在
              $http({
                method: 'Post',
                url: '/layoutPage/findLayoutPage',
                data: { data: { _id: $stateParams.itemId } },
              }).then(function (response) {
                if (response.data.code == 0) {
                  console.log(response.data.onePageHeight);
                  $("#onePageHeight").height(Number(response.data.onePageHeight));
                  $scope.newLayout = response.data.data;
                  if($scope.newLayout.titleIcon&&$scope.newLayout.titleIcon.attr){
                    $scope.newLayout.titleIcon.attr = angular.fromJson($scope.newLayout.titleIcon.attr);
                  }
                  if($scope.newLayout.titleBgPic&&$scope.newLayout.titleBgPic.attr){
                    $scope.newLayout.titleBgPic.attr = angular.fromJson($scope.newLayout.titleBgPic.attr);
                  }
                  if($scope.newLayout.pageAttr){
                    $scope.newLayout.pageAttr = angular.fromJson($scope.newLayout.pageAttr);
                  }
                  if($scope.newLayout.nameAttr){
                    $scope.newLayout.nameAttr = angular.fromJson($scope.newLayout.nameAttr);
                    let findFlag = false;
                    if($scope.newLayout.nameAttr.exeCommandAttr){
                      $scope.newLayout.nameAttr.exeCommandAttrSelect =$scope.newLayout.nameAttr.exeCommandAttr;
                      for (let index = 0; index < $scope.exeCommandList.length; index++) {
                        const element_exeCommandList = $scope.exeCommandList[index].nameEn;
                        if(element_exeCommandList==$scope.newLayout.nameAttr.exeCommandAttrSelect){
                          findFlag = true;
                          break;
                        }
                        
                      }
                    }
                    if(!findFlag){
                      $scope.newLayout.nameAttr.exeCommandAttrSelect = 'otherExeCommand';
                    }
                  }
                  for (let index = 0; index < $scope.belongs.length; index++) {
                    const element = $scope.belongs[index];
                    if (element.nameEn == $scope.newLayout.belongProject) {
                      $scope.curProjectNameEn = element.nameEn;
                      $scope.curProjectName = element.nameCn;
                      $scope.curProjectInfo = element;
                      break;
                    }
                  }
                  $http({
                    method: 'GET',
                    url: '/config/getWebConfig',
                  }).then(function (response) {
                    if (response.data.code === 0) {
                      $scope.domain = response.data.domain + "/" + $scope.curProjectNameEn;
                      $scope.uploadToken = response.data.uploadToken;
                      $scope.fileSaveWay = response.data.fileSaveWay;
                    }
                  }, function (err) {
                    $scope.$emit('notification', { type: 'danger', message: '网络错误，与服务器通信错误' });
                  })
                  $http({
                    method: 'GET',
                    url: '/layoutPage/getAllLayoutPage?belongProject=' + $scope.newLayout.belongProject + '&belongActivity=otherPage'
                  }).then(function (response) {
                    if (response.data.code == 0) {
                      $scope.otherPages = response.data.data;
                    } else {
                      $scope.$emit('notification', { type: 'danger', message: response.data.msg });
                    }
                  }, function (err) {
                    $scope.$emit('notification', { type: 'danger', message: '网络错误，与服务器通信错误' });
                  });
                  pageParam = $scope.newLayout.pageParam;
                  let pageWidth = pageParam.max_cols * (pageParam.margin_w * 2 + pageParam.dimension_w) - pageParam.margin_w * 2;
                  adjustParam = (pageWidth / 1920).toFixed(2);
                  var ui = document.getElementsByClassName("gridster");
                  ui[0].style.display = "block";
                  $scope.gridster = $(".gridster > ul").gridster({
                    widget_margins: [pageParam.margin_w, pageParam.margin_h],
                    widget_base_dimensions: [pageParam.dimension_w, pageParam.dimension_h],
                    resize: { enabled: true },
                    max_cols: pageParam.max_cols,
                    draggable: {
                      handle: 'header'
                    },
                  }).data('gridster');
                  if ($scope.newLayout.items && $scope.newLayout.items.length != 0) {
                    for (let index = 0; index < $scope.newLayout.items.length; index++) {
                      (function (index) {
                        setTimeout(function () {
                          const element_item = $scope.newLayout.items[index];
                          let imgHTML = '';
                          let hideHTML = '';
                          let titleHTML = '';
                          let iconHTML = '';
                          let cardTypeHtml = '';
                          let settingHtml = '';
                          let subPageEntranceHtml = '';
                          let addHTML = '';
                          if (element_item.layoutType == "page") {
                            if (element_item.page.layoutType == "viewpager") {
                              settingHtml = '<font class="functionButton" style="position: absolute;background:#FF4500;color: white;top: 50%;transform: translate(0%, -50%);left:100px;width:120px;height:80px;text-align:center;font-size: 14px;line-height: 80px;"onclick="angular.element(this).scope().showViewpagerSetWindow(this)">设置参数</font>'
                              subPageEntranceHtml = '<font class="functionButton" style="position: absolute;background:#4169E1;color: white;top: 50%;transform: translate(0%, -50%);right:100px;width:120px;height:80px;text-align:center;font-size: 14px;line-height: 80px;" onclick="angular.element(this).scope().goToSubPage(this)">管理子页面</font>'
                            } else {
                              addHTML = '<div class="add" onclick="angular.element(this).scope().showAddWindow(this)"/>';
                            }
                            hideHTML = '<div class=\'hide\' >' + JSON.stringify(element_item) + "</div>";
                            let contentHTML = '<li class="' + element_item.layoutType + '"><header></header><div class=\'delete\' onclick=\'angular.element(this).scope().delItem(this)\'>×</div>'
                              + addHTML
                              + hideHTML
                              + titleHTML
                              + iconHTML
                              + imgHTML
                              + settingHtml
                              + subPageEntranceHtml
                              + '</li>';
                            let widget = [contentHTML, element_item.size_x, element_item.size_y, element_item.col, element_item.row];
                            $scope.gridster.add_widget.apply($scope.gridster, widget);
                            // $scope.gridster.disable_resize();
                            // $scope.gridster.disable();
                          } else if (element_item.layoutType == "card") {
                            let element_card = element_item.card;
                            cardTypeHtml = getCardTypeHtml(element_card);
                            imgHTML = getBgPicHTML(element_card);
                            iconHTML = getIconHTML(element_card);
                            titleHTML = getTitleHTML(element_card);
                            hideHTML = '<div class=\'hide\' >' + JSON.stringify(element_item) + "</div>";
                            let contentHTML = '<li class="' + element_item.layoutType + '"><header></header><div class=\'delete\' onclick=\'angular.element(this).scope().delItem(this)\'>×</div><div class=\'add\' onclick=\'angular.element(this).scope().showAddWindow(this)\'/>'
                              + hideHTML
                              + titleHTML
                              + cardTypeHtml
                              + iconHTML
                              + imgHTML

                              + '</li>';
                            let widget = [contentHTML, element_item.size_x, element_item.size_y, element_item.col, element_item.row];
                            $scope.gridster.add_widget.apply($scope.gridster, widget);
                          }
                        }, (index + 1) * 50);
                      })(index)
                    }
                  }
                }
              }, function (err) {
                $scope.$emit('notification', { type: 'danger', message: '网络错误，与服务器通信错误' });
              })
            }
          }, 0);
        }
      }, function (err) {
        $scope.$emit('notification', { type: 'danger', message: '网络错误，与服务器通信错误' });
      })
    };

    $scope.addItemNew = function (col, row, layoutType, subLayoutType) {
      if (layoutType == 'page' && subLayoutType == 'viewpager') {
        let array = $(".gridster > ul > li.page > .hide");
        if (array.length != 0) {
          for (let index = 0; index < array.length; index++) {
            const jsonObj = JSON.parse(array[index].innerHTML);
            if (jsonObj.page.layoutType == "viewpager") {
              return
            }
          }
        }
      }
      if (layoutType == 'page') {
        //在数据库创建page
        let dataPage = {
          belongPage: $stateParams.itemId,
          belongProject: $scope.newLayout.belongProject,
          layoutType: subLayoutType,
          viewpagerParam: '{"titlePosition":"top","subPageTopHeight":"0"}'
        }
        if (subLayoutType == 'horizontallist') {
          dataPage.nameAttr = '{"size":"40px","color":"#ffffffff"}';
          dataPage.horizontalListAttr = {
            proportionWh: 2,
            isShowTitle: false
          }
        }
        $http({
          method: 'POST',
          url: '/layoutPage/uploadLayoutPage',
          data: {
            size_x: col,
            size_y: row,
            data: dataPage
          }
        }).then(function (result) {
          if (result.data.code == 0) {
            $scope.newItem = {
              layoutType: layoutType,
              page: result.data.data,
            }
            let settingHtml = '<font class="functionButton" style="position: absolute;background:#FF4500;color: white;top: 50%;transform: translate(0%, -50%);left:100px;width:120px;height:80px;text-align:center;font-size: 14px;line-height: 80px;"onclick="angular.element(this).scope().showViewpagerSetWindow(this)">设置参数</font>'
            let subPageEntranceHtml = '<font class="functionButton" style="position: absolute;background:#4169E1;color: white;top: 50%;transform: translate(0%, -50%);right:100px;width:120px;height:80px;text-align:center;font-size: 14px;line-height: 80px;" onclick="angular.element(this).scope().goToSubPage(this)">管理子页面</font>'
            let hideHTML = '<div class=\'hide\' >' + JSON.stringify($scope.newItem) + "</div>";
            //let addHTML = '<div class="add" onclick="angular.element(this).scope().showAddWindow(this)"/>'
            let contentHTML = '<li class="' + layoutType + '"><header></header><div class=\'delete\' onclick=\'angular.element(this).scope().delItem(this)\'>×</div>'
              + hideHTML
              + settingHtml
              + subPageEntranceHtml
              + '</li>'
            $scope.gridster.add_widget.apply($scope.gridster, [contentHTML, col, row])
          } else {
            $scope.$emit('notification', { type: 'danger', message: result.data.msg });
          }
        }, function (err) {
          $scope.$emit('notification', { type: 'danger', message: '网络错误,提交失败' });
        });

      } else {
        $scope.newItem = {
          layoutType: layoutType,
        }
        $scope.gridster.add_widget.apply($scope.gridster, ['<li class="' + layoutType + '"><header></header><div class=\'delete\' onclick=\'angular.element(this).scope().delItem(this)\'>×</div><div class=\'add\' onclick=\'angular.element(this).scope().showAddWindow(this)\'/>' + '<div class=\'hide\' >' + JSON.stringify($scope.newItem) + "</div>" + '</li>', col, row])
      }


    }
    $scope.showViewpagerSetWindow = function (item) {
      if ($(item).siblings('.hide').length != 0) {
        $scope.curCardNode = item;
        $scope.newItem = JSON.parse($(item).siblings('.hide')[0].innerHTML);
        if($scope.newItem.page.viewpagerParam){
          $scope.newItem.page.viewpagerParam = angular.fromJson($scope.newItem.page.viewpagerParam);
        }
        sendMsgPageView("");
        $scope.$apply();
        $('#pageWindow').modal('show');
      }
    }

    $scope.goToSubPage = function (item) {
      if ($(item).siblings('.hide').length != 0) {
        $state.go('main.layoutPage', { itemId: JSON.parse($(item).siblings('.hide')[0].innerHTML).page._id, belongProject: $scope.newLayout.belongProject })
      }
    }
    $scope.delItem = function (item) {
      $scope.gridster.remove_widget($(item).parent());
    }

    $scope.showAddWindow = function (item) {
      if ($(item).siblings('.hide').length != 0) {
        $scope.curCardNode = item;
        $scope.newItem = JSON.parse($(item).siblings('.hide')[0].innerHTML.replaceAll("amp;",""));
        console.log($scope.newItem);
        if ($scope.newItem.layoutType == "card") {
          $scope.$apply();
          $('#addWindow').modal('show');
          $scope.refreshCardsTable();
        } else if ($scope.newItem.layoutType == "page") {//needfinish
          // if($scope.newItem.page.layoutType=="viewpager"){
          //   $state.go('main.layoutPage',{itemId:$scope.newItem.page._id})
          // }else{
          //   $scope.showAddListWindow($scope.newItem.page);
          // }
        }
      }
    }

    $scope.showAddListWindow = function () {
      document.getElementById('tip_msg_two').innerHTML = '';
      for (let index = 0; index < $scope.newItem.page.items.length; index++) {
        const element = $scope.newItem.page.items[index];
        let item = element.card;
        var picHTML = '';
        var titleHTML = '';
        var iconHTML = '';
        var cardTypeHtml = '';
        var delHTML = '<div class=\'delete\' onclick=\'angular.element(this).scope().delItemList(this)\'>×</div>';
        if (item) {
          picHTML = getBgPicHTML(item);
          iconHTML = getIconHTML(item);
          titleHTML = getTitleHTML(item);
          cardTypeHtml = getCardTypeHtml(item);
        }
        element.innerHTML = $sce.trustAsHtml(delHTML
          + cardTypeHtml
          + picHTML
          + titleHTML
          + iconHTML
        );
      }
      $('#addListWindow').modal('show');
      $scope.refreshCardsTableList();
    }

    $scope.showCreateCardWindow = function (clearCurCardNode, card) {
      if (clearCurCardNode) {
        $scope.curCardNode = null;
      }
      $scope.uploadFiles.bgPic = null;
      $scope.uploadFiles.bgPicEn = null;
      $scope.uploadFiles.icon = null;
      $scope.uploadFiles.files = null;
      $scope.uploadFiles.videos = null;
      $scope.uploadFiles.banners = null;

      let cardInput = {
        cardAttr: $scope.curProjectInfo.cardAttr?angular.fromJson($scope.curProjectInfo.cardAttr):{
          corner: "5px",
          frameColor: "#ffffff",
          bgColor: "#00000000",
          zoomIndex: "1",
          shadow: {
            isShow: "false",
            color: "#99000000",
            height: "70px",
            position: "bottom",
            margin: "0px 0px 0px 0px"
          }
        },
        dvbViewAttr: {
          serviceId: "-1",
          fitType: 2
        },
        caTvNumAttr: {
          size: "40px",
          color: "#ffffffff",
          position: "left|bottom",
          margin: "0px 0px 10px 150px"
        },
        clockAttr: {
          size: "40px",
          color: "#adaeaf",
          position: "center_vertical|right",
          margin: "0px 0px 0px 0px",
          format: "MM-dd HH:mm"
        },
        bannerAttr: {
          attr: {
            loopTime: "5s",
            isShowDot: "true",
            orientation: "horizontal",
            isFullShow: "true",
            fullShowType: "fitCenter",
            clickAction: [{
              clickType: "",
              content: "",
            }
            ]
          },
          file: []
        },
        videoAttr: {
          attr: {
            isLoop: "false",
            isCache: "true",
            isMuteSmallWinSound:"false",
            isStopPlay: "false",
            fitType: 2
          },
          file: []
        },
        title: {
          name: '',
          nameEng: "",
          attr:$scope.curProjectInfo.titleAttr?angular.fromJson($scope.curProjectInfo.titleAttr):{
            id: "",
            ifNotFocusCanScroll: "true",
            size: "40px",
            color: "#ffffffff",
            width: "",
            height: "",
            position: "left|bottom",
            margin: "0px 0px 10px 15px"
          }
        },
        icon: {
          picUrl: '',
          attr: $scope.curProjectInfo.iconAttr?angular.fromJson($scope.curProjectInfo.iconAttr):{
            width: "110px",
            height: "110px",
            position: "center_horizontal|top",
            margin: "40px 0px 0px 0px",
          }
        },
        bgPic: {
          picUrl: '',
          picEnUrl:'',
        }
      }

      $scope.cardInput = cardInput;

      document.getElementById('tip_msg').innerHTML = '';
      if (card) {
        $scope.newCard = card;
        let findFlag = false;
        if($scope.newCard.exeCommandAttr){
          $scope.newCard.exeCommandAttrSelect = $scope.newCard.exeCommandAttr;
          for (let index = 0; index < $scope.exeCommandList.length; index++) {
            const element_exeCommandList = $scope.exeCommandList[index].nameEn;
            if(element_exeCommandList==$scope.newCard.exeCommandAttrSelect){
              findFlag = true;
              break;
            }
            
          }
        }
        if(!findFlag){
          $scope.newCard.exeCommandAttrSelect = 'otherExeCommand';
        }
        cardAttr = angular.fromJson(card.cardAttr);
        dvbViewAttr = angular.fromJson(card.dvbViewAttr);
        caTvNumAttr = angular.fromJson(card.caTvNumAttr);
        clockAttr = angular.fromJson(card.clockAttr);
        if(card.bannerAttr){
          bannerAttr = angular.fromJson(card.bannerAttr.attr);
        }
        if(card.videoAttr){
          videoAttr = angular.fromJson(card.videoAttr.attr);
        }
        if(card.title){
          title = angular.fromJson(card.title.attr);
        }
        if(card.icon){
          icon = angular.fromJson(card.icon.attr);
        }
        $scope.cardInput.cardAttr = cardAttr;
        $scope.cardInput.dvbViewAttr = dvbViewAttr;
        $scope.cardInput.caTvNumAttr = caTvNumAttr;
        $scope.cardInput.clockAttr = clockAttr;
        $scope.cardInput.bannerAttr.attr = bannerAttr;
        $scope.cardInput.videoAttr.attr = videoAttr;
        $scope.cardInput.title.attr = title;
        $scope.cardInput.icon.attr = icon;

      } else {
        $scope.newCard = {
          belongPage: $stateParams.itemId,
          name: '',
          cardTag: "none",
          dataType: 'fromCard',
          cardType: 'card',
          clickType: 'blank',
          openAppAttr: {},
          minUsedVer: '0',
          bannerAttr: {
            files: []
          },
          videoAttr: {
            files: []
          },
          title: {
            name: '',
            nameEng: "",
          },
          icon: {
            picUrl: '',
          },
          bgPic: {
            picUrl: '',
            picEnUrl:'',
          },
          exeCommandAttrSelect: 'otherExeCommand'
        }
      }
      $('#createWindow').modal('show');
    }
    $scope.initFiles = function() {
      console.log(1111);
        if (!$scope.newCard.videoAttr.files||$scope.newCard.videoAttr.files.length==0) {
            $scope.newCard.videoAttr.files = [{}]; // 初始化为一个空对象
        }
    };
  
    $scope.createPage = function () {
      if (/[']/.test($scope.newItem.page.viewpagerParam)
      ) {
        $scope.$emit('notification', { type: 'danger', message: '内容包含英文的单引号，不允许提交!' });
        return;
      }
      if (!isSize($scope.newItem.page.viewpagerParam.titleSpacing)) {
        sendMsgPageView("标题的间距属性格式错误，应为：0-9999px");
        return;
      }
      if (!isSize($scope.newItem.page.viewpagerParam.titlePaddingLeft)) {
        sendMsgPageView("标题左内边距属性格式错误，应为：0-9999px");
        return;
      }
      if (!isSize($scope.newItem.page.viewpagerParam.titlePaddingRright)) {
        sendMsgPageView("标题右内边距属性格式错误，应为：0-9999px");
        return;
      }
      if (!isSize($scope.newItem.page.viewpagerParam.titleLeft)) {
        sendMsgPageView("标题左边距属性格式错误，应为：0-9999px");
        return;
      }
      if (!isSize($scope.newItem.page.viewpagerParam.titleRight)) {
        sendMsgPageView("标题右边距属性格式错误，应为：0-9999px");
        return;
      }
      if (!isSize($scope.newItem.page.viewpagerParam.titleTop)) {
        sendMsgPageView("标题上边距属性格式错误，应为：0-9999px");
        return;
      }
      if (!isSize($scope.newItem.page.viewpagerParam.titleBottom)) {
        sendMsgPageView("标题下边距属性格式错误，应为：0-9999px");
        return;
      }
      if (!isNumber($scope.newItem.page.viewpagerParam.defaultTitlePosition)) {
        sendMsgPageView("页面默认选中哪个标题属性格式错误，应为：0-99");
        return;
      }
      if (!isColor($scope.newItem.page.viewpagerParam.titleBgColor)) {
        sendMsgPageView("标题背景颜色属性格式错误，应为：#000|#000000|#00000000");
        return;
      }
      if (!isSize($scope.newItem.page.viewpagerParam.viewPageTop)) {
        sendMsgPageView("子布局上边距属性格式错误，应为：0-9999px");
        return;
      }
      if (!isJson($scope.newItem.page.viewpagerParam)) {
        $scope.newItem.page.viewpagerParam = angular.toJson($scope.newItem.page.viewpagerParam);
      }
      // if (!isJson($scope.newItem.page.viewpagerParam)) {
      //   $scope.$emit('notification', { type: 'danger', message: '参数格式不对（要求JSON字符串）' });
      //   return;
      // }
      $http({
        method: 'POST',
        url: '/layoutPage/uploadLayoutPage',
        data: { data: $scope.newItem.page }
      }).then(function (result) {
        if (result.data.code == 0) {
          $scope.$emit('notification', { type: 'success', message: '提交成功' });
          console.log("createPage!!!!!!")
          $timeout(function () {
            drawAddItemPage();
          }, 200)
        } else {
          $scope.$emit('notification', { type: 'danger', message: result.data.msg });
        }
      }, function (err) {
        $scope.$emit('notification', { type: 'danger', message: '网络错误,提交失败!' });
      });
    }
    $scope.uploadFiles = {};

    $scope.createCard = function () {

      if ($scope.newCard.cardType == "clockView") {
        if ($scope.cardInput.clockAttr.size == "" || $scope.cardInput.clockAttr.color == "" || $scope.cardInput.clockAttr.position == ""
          || $scope.cardInput.clockAttr.margin == "" || $scope.cardInput.clockAttr.format == "") {
          sendMsg("时间卡片属性不能为空，请补充完整！");
          return;
        }
        if (!isSize($scope.cardInput.clockAttr.size)) {
          sendMsg("时间卡片size属性格式错误，应为：0-9999px");
          return;
        }
        if (!isColor($scope.cardInput.clockAttr.color)) {
          sendMsg("时间卡片color属性格式错误，应为：#000|#000000|#00000000");
          return;
        }
        if (!isPosition($scope.cardInput.clockAttr.position)) {
          sendMsg("时间卡片position值错误，应为：left|right|top|bottom|center|center_horizontal|center_vertical");
          return;
        }
        if (!isMargin($scope.cardInput.clockAttr.margin)) {
          sendMsg("时间卡片margin属性格式错误,应为：0-9999px 0-9999px 0-9999px 0-9999px");
          return;
        }
        if (isContainQuotationMarks($scope.cardInput.clockAttr.format)) {
          sendMsg("时间卡片formats属性包含英文单引号或双引号，不允许提交！");
          return;
        }
      }
      if ($scope.newCard.cardType == "caTvNumView") {
        if ($scope.cardInput.caTvNumAttr.size == "" || $scope.cardInput.caTvNumAttr.color == "" || $scope.cardInput.caTvNumAttr.position == "" || $scope.cardInput.caTvNumAttr.margin == "") {
          sendMsg("电视号卡片属性不能为空，请补充完整！");
          return;
        }
        if (!isSize($scope.cardInput.caTvNumAttr.size)) {
          sendMsg("电视号卡片size属性格式错误，应为：0-9999px");
          return;
        }
        if (!isColor($scope.cardInput.caTvNumAttr.color)) {
          sendMsg("电视号卡片color属性格式错误，应为：#000|#000000|#00000000");
          return;
        }
        if (!isPosition($scope.cardInput.caTvNumAttr.position)) {
          sendMsg("电视号卡片position属性格式错误，应为：left|right|top|bottom|center|center_horizontal|center_vertical");
          return;
        }
        if (!isMargin($scope.cardInput.caTvNumAttr.margin)) {
          sendMsg("电视号卡片margin属性格式错误,应为：0-9999px 0-9999px 0-9999px 0-9999px");
          return;
        }
      }
      if ($scope.newCard.cardType == "dvbView") {
        if ($scope.cardInput.dvbViewAttr.serviceId == "") {
          sendMsg("直播窗口serviceId不能为空，请补充完整！");
          return;
        }
        if (isContainQuotationMarks($scope.cardInput.dvbViewAttr.serviceId)) {
          sendMsg("直播窗口serviceId属性包含英文单引号或双引号，不允许提交！");
          return;
        }
        if (!isServiceId($scope.cardInput.dvbViewAttr.serviceId)) {
          sendMsg("直播窗口serverId不能为中文,请重新输入！");
          return;
        }
      }
      if ($scope.newCard.cardType == "videoView") {
      }
      if ($scope.newCard.cardType == "bannerView") {
        if ($scope.cardInput.bannerAttr.attr.loopTime == "" || $scope.cardInput.bannerAttr.attr.orientation == "" || $scope.cardInput.bannerAttr.attr.fullShowType == "") {
          sendMsg("广告轮播图属性不能为空，请补充完整！")
          return;
        }
        if (!isLoopTime($scope.cardInput.bannerAttr.attr.loopTime)) {
          sendMsg("广告轮播图轮播时间间隔属性格式错误，应为：0-9999s");
          return;
        }
        if($scope.cardInput.bannerAttr.attr.clickAction!=undefined){
          if ($scope.cardInput.bannerAttr.attr.clickAction.length > 0) {
            for (var i = 0; i < $scope.cardInput.bannerAttr.attr.clickAction.length; i++) {
              if ($scope.cardInput.bannerAttr.attr.clickAction[i].clickType && !$scope.cardInput.bannerAttr.attr.clickAction[i].content) {
                sendMsg("content不能为空！");
                return;
              }
            }
          }
        }
      }
      if ($scope.newCard.isShowCardAttr) {
        if ($scope.cardInput.cardAttr.corner == "" || $scope.cardInput.cardAttr.frameColor == "" || $scope.cardInput.cardAttr.bgColor == "" || $scope.cardInput.cardAttr.shadow.color == ""
          || $scope.cardInput.cardAttr.shadow.height == "" || $scope.cardInput.cardAttr.shadow.position == "" || $scope.cardInput.cardAttr.shadow.margin == "") {
          sendMsg("卡片属性不能为空，请补充完整！");
          return;
        }
        if (!isSize($scope.cardInput.cardAttr.corner)) {
          sendMsg("卡片圆角半径属性格式错误，应为：0-9999px");
          return;
        }
        if (!isColor($scope.cardInput.cardAttr.frameColor)) {
          sendMsg("卡片选中框颜色属性格式错误，应为：#000|#000000|#00000000");
          return;
        }
        if (!isColor($scope.cardInput.cardAttr.bgColor)) {
          sendMsg("卡片选中背景颜色属性格式错误，应为：#000|#000000|#00000000");
          return;
        }
        if (!isColor($scope.cardInput.cardAttr.shadow.color)) {
          sendMsg("shadow的颜色属性格式错误，应为：#000|#000000|#00000000");
          return;
        }
        if (!isSize($scope.cardInput.cardAttr.shadow.height)) {
          sendMsg("shadow的height属性格式错误，应为：0-9999px");
          return;
        }
        if (!isPosition($scope.cardInput.cardAttr.shadow.position)) {
          sendMsg("shadow的position属性格式错误,应为：left|right|top|bottom|center|center_horizontal|center_vertical");
          return;
        }
        if (!isMargin($scope.cardInput.cardAttr.shadow.margin)) {
          sendMsg("shadow的margin属性格式错误,应为：0-9999px 0-9999px 0-9999px 0-9999px");
          return;
        }
      }
      if ($scope.newCard.isShowTitle) {
        if ($scope.cardInput.title.attr.size == "" || $scope.cardInput.title.attr.color == "" || $scope.cardInput.title.attr.position == ""
          || $scope.cardInput.title.attr.margin == "") {
          sendMsg("标题属性不能为空，请补充完整！");
          return;
        }
        if ($scope.cardInput.title.attr.id) {
          if (isContainQuotationMarks($scope.cardInput.title.attr.id)) {
            sendMsg("标题id属性包含英文单引号或双引号，不允许提交！");
            return;
          }
        }
        if (!isSize($scope.cardInput.title.attr.size)) {
          sendMsg("标题size属性格式错误，应为：0-9999px");
          return;
        }
        if (!isColor($scope.cardInput.title.attr.color)) {
          sendMsg("标题color属性格式错误，应为：#000|#000000|#00000000");
          return;
        }
        if ($scope.cardInput.title.attr.width) {
          if (!isSize($scope.cardInput.title.attr.width)) {
            sendMsg("标题width属性格式错误，应为：0-9999px");
            return;
          }
        }
        if ($scope.cardInput.title.attr.height) {
          if (!isSize($scope.cardInput.title.attr.height)) {
            sendMsg("标题height属性格式错误，应为：0-9999px");
            return;
          }
        }
        if (!isPosition($scope.cardInput.title.attr.position)) {
          sendMsg("标题position属性格式错误,应为：left|right|top|bottom|center|center_horizontal|center_vertical");
          return;
        }
        if (!isMargin($scope.cardInput.title.attr.margin)) {
          sendMsg("标题margin属性格式错误,应为：0-9999px 0-9999px 0-9999px 0-9999px");
          return;
        }
      }
      if ($scope.newCard.isShowIcon) {
        if ($scope.cardInput.icon.attr.width == "" || $scope.cardInput.icon.attr.height == "" || $scope.cardInput.icon.attr.position == ""
          || $scope.cardInput.icon.attr.margin == "") {
          sendMsg("图标属性不能为空，请补充完整!");
          return;
        }
        if (!isSize($scope.cardInput.icon.attr.width)) {
          sendMsg("图标width属性格式错误，应为：0-9999px");
          return;
        }
        if (!isSize($scope.cardInput.icon.attr.height)) {
          sendMsg("图标height属性格式错误,应为：0-9999px");
          return;
        }
        if (!isPosition($scope.cardInput.icon.attr.position)) {
          sendMsg("图标position属性格式错误，应为：left|right|top|bottom|center|center_horizontal|center_vertical");
          return;
        }
        if (!isMargin($scope.cardInput.icon.attr.margin)) {
          sendMsg("图标margin属性格式错误,应为：0-9999px 0-9999px 0-9999px 0-9999px");
          return;
        }
      }
      
      $scope.newCard.dvbViewAttr = angular.toJson($scope.cardInput.dvbViewAttr);
      $scope.newCard.caTvNumAttr = angular.toJson($scope.cardInput.caTvNumAttr);
      $scope.newCard.clockAttr = angular.toJson($scope.cardInput.clockAttr);
      $scope.newCard.cardAttr = angular.toJson($scope.cardInput.cardAttr);
      $scope.newCard.bannerAttr.attr = angular.toJson($scope.cardInput.bannerAttr.attr);
      $scope.newCard.videoAttr.attr = angular.toJson($scope.cardInput.videoAttr.attr);
      $scope.newCard.title.attr = angular.toJson($scope.cardInput.title.attr);
      $scope.newCard.icon.attr = angular.toJson($scope.cardInput.icon.attr);

      if (/[']/.test($scope.newCard.minUsedVer)
        || /[']/.test($scope.newCard.name)
        || ($scope.newCard.title && /[']/.test($scope.newCard.title.name))
        || ($scope.newCard.title && /[']/.test($scope.newCard.title.nameEng))
        || ($scope.newCard.title && /[']/.test($scope.newCard.title.attr))
        || ($scope.newCard.icon && /[']/.test($scope.newCard.icon.attr))
      ) {
        document.getElementById('tip_msg').innerHTML = '<p style="color:red">内容包含英文的单引号，不允许提交!</p>'
        return;
      }
      if ($scope.newCard.cardType == "dvbView" && !isJson($scope.newCard.dvbViewAttr)) {
        document.getElementById('tip_msg').innerHTML = '<p style="color:red">直播窗口属性格式不对（要求JSON字符串）</p>'
        return;
      }
      if ($scope.newCard.cardType == "videoView" && !isJson($scope.newCard.videoAttr.attr)) {
        document.getElementById('tip_msg').innerHTML = '<p style="color:red">视频窗口属性格式不对（要求JSON字符串）</p>'
        return;
      }
      if ($scope.newCard.cardType == "bannerView" && !isJson($scope.newCard.bannerAttr.attr)) {
        document.getElementById('tip_msg').innerHTML = '<p style="color:red">广告轮播图属性格式不对（要求JSON字符串）</p>'
        return;
      }
      if ($scope.newCard.cardType == "caTvNumView" && !isJson($scope.newCard.caTvNumAttr)) {
        document.getElementById('tip_msg').innerHTML = '<p style="color:red">电视号卡片属性格式不对（要求JSON字符串）</p>'
        return;
      }
      if ($scope.newCard.cardType == "clockView" && !isJson($scope.newCard.clockAttr)) {
        document.getElementById('tip_msg').innerHTML = '<p style="color:red">时间卡片属性格式不对（要求JSON字符串）</p>'
        return;
      }
      if (isNaN($scope.newCard.minUsedVer)) {
        document.getElementById('tip_msg').innerHTML = '<p style="color:red">“能使用该卡片的最小软件版本”的内容必须是数值</p>'
        return;
      }
      if ($scope.newCard.clickType == "openApp") {
        if (!$scope.newCard.openAppAttr || !$scope.newCard.openAppAttr.packageName) {
          document.getElementById('tip_msg').innerHTML = '<p style="color:red">请填写应用包名</p>'
          return;
        } else if (/[']/.test($scope.newCard.openAppAttr.packageName)) {
          document.getElementById('tip_msg').innerHTML = '<p style="color:red">应用包名包含英文的单引号，不允许提交!</p>'
          return;
        }
      }
      if ($scope.newCard.clickType == "exeCommand") {
        if ($scope.newCard.exeCommandAttrSelect&&$scope.newCard.exeCommandAttrSelect!='otherExeCommand') {
          $scope.newCard.exeCommandAttr = $scope.newCard.exeCommandAttrSelect;
        }
        if (!$scope.newCard.exeCommandAttr) {
          document.getElementById('tip_msg').innerHTML = '<p style="color:red">请填写指令内容</p>'
          return;
        } else if (/[']/.test($scope.newCard.exeCommandAttr)) {
          document.getElementById('tip_msg').innerHTML = '<p style="color:red">指令内容包含英文的单引号，不允许提交!</p>'
          return;
        }
      }
      let files = [];
      if ($scope.newCard.isShowCardAttr) {
        if (!isJson($scope.newCard.cardAttr)) {
          document.getElementById('tip_msg').innerHTML = '<p style="color:red">卡片属性格式不对（要求JSON字符串）</p>'
          return;
        }
      }
      if ($scope.newCard.isShowTitle) {
        if (!isJson($scope.newCard.title.attr)) {
          document.getElementById('tip_msg').innerHTML = '<p style="color:red">标题属性格式不对（要求JSON字符串）</p>'
          return;
        }
      }
      if ($scope.newCard.isShowIcon) {
        if (!isJson($scope.newCard.icon.attr)) {
          document.getElementById('tip_msg').innerHTML = '<p style="color:red">图标属性格式不对（要求JSON字符串）</p>'
          return;
        }
        if ($scope.uploadFiles.icon) {
         
          if(/[']/.test($scope.uploadFiles.icon.name)){
            document.getElementById('tip_msg').innerHTML = '<p style="color:red">图标图片名不能包含英文单引号</p>'
            return;
          }
        
          let nickName = "autolayout_icon" + $scope.getTime() + "_" + getFileType($scope.uploadFiles.icon.name);
          $scope.newCard.icon.picUrl = $scope.domain + "/" + nickName;
          $scope.uploadFiles.icon.nickName = nickName;
          files.push($scope.uploadFiles.icon);
        }
      }

      if ($scope.newCard.clickType != 'openPointWeb') {
        $scope.newCard.files = [];
      } else {
        if ($scope.uploadFiles.files && $scope.uploadFiles.files.length != 0) {
          let tmpFiles = [];
          for (let index = 0; index < $scope.uploadFiles.files.length; index++) {
            const element = $scope.uploadFiles.files[index];
            if (isChinese(element.name)) {
              document.getElementById('tip_msg').innerHTML = '<p style="color:red">文件名不能包含中文</p>';
              return;
            }
            if(/[']/.test(element.name)){
              document.getElementById('tip_msg').innerHTML = '<p style="color:red">文件名不能包含英文单引号</p>'
              return;
            }
            
            let nickName = "autolayout_file" + $scope.getTime() + "_" + element.name;
            element.nickName = nickName;
            files.push(element);
            tmpFiles.push({
              fileName: element.name,
              url: $scope.domain + "/" + nickName
            })
          }
          $scope.newCard.files = tmpFiles;
        }
      }
      if($scope.newCard.videoAttr&&$scope.newCard.videoAttr.files&&$scope.newCard.videoAttr.files.length>0){
        $scope.newCard.videoAttr.files = $scope.newCard.videoAttr.files.filter(function(file) {
          return file.fileName && file.fileName.trim() !== ''; // 确保fileName存在且不为空字符串
      });
      }
      if ($scope.newCard.cardType != 'videoView') {
        if ($scope.newCard.videoAttr) {
          $scope.newCard.videoAttr.files = [];
        }
      } else {
        if ($scope.uploadFiles.videos) {
          
          let tmpFiles = [];
          const element = $scope.uploadFiles.videos;
          // if (isChinese(element.name)) {
          //   document.getElementById('tip_msg').innerHTML = '<p style="color:red">文件名不能包含中文</p>'
          //   return;
          // }
          if(/[']/.test(element.name)){
            document.getElementById('tip_msg').innerHTML = '<p style="color:red">文件名不能包含英文单引号</p>'
            return;
          }
          let nickName = "autolayout_video" + $scope.getTime() + "_" + getFileType(element.name);
          element.nickName = nickName;
          files.push(element);
          tmpFiles.push({
            fileName: element.name,
            url: $scope.domain + "/" + nickName
          })
          $scope.newCard.videoAttr.files = tmpFiles;
        }
      }

      if ($scope.newCard.cardType != 'bannerView') {
        if ($scope.newCard.bannerAttr) {
          $scope.newCard.bannerAttr.files = [];
        }
      } else {
        if ($scope.uploadFiles.banners && $scope.uploadFiles.banners.length != 0) {
          let tmpFiles = [];
          for (let index = 0; index < $scope.uploadFiles.banners.length; index++) {
            const element = $scope.uploadFiles.banners[index];
            if(/[']/.test(element.name)){
              document.getElementById('tip_msg').innerHTML = '<p style="color:red">文件名不能包含英文单引号</p>'
              return;
            }
            let nickName = "autolayout_banner" + $scope.getTime() + "_"+index + getFileType(element.name);
            element.nickName = nickName;
            files.push(element);
            tmpFiles.push({
              fileName: element.name,
              url: $scope.domain + "/" + nickName
            })
          }
          $scope.newCard.bannerAttr.files = tmpFiles;
        }
      }

      if ($scope.newCard.isShowBgPic) {
        if ($scope.uploadFiles.bgPic) {
          // if (isChinese($scope.uploadFiles.bgPic.name)) {
          //   document.getElementById('tip_msg').innerHTML = '<p style="color:red">背景图片名不能包含中文</p>'
          //   return;
          // }
          if(/[']/.test($scope.uploadFiles.bgPic.name)){
            document.getElementById('tip_msg').innerHTML = '<p style="color:red">背景图片名不能包含英文单引号</p>'
            return;
          }
          let nickName = "autolayout_bgPic" + $scope.getTime() + "_" + getFileType($scope.uploadFiles.bgPic.name);
          $scope.newCard.bgPic.picUrl = $scope.domain + "/" + nickName;
          $scope.uploadFiles.bgPic.nickName = nickName;
          files.push($scope.uploadFiles.bgPic);
        }
        if ($scope.uploadFiles.bgPicEn) {
          // if (isChinese($scope.uploadFiles.bgPicEn.name)) {
          //   document.getElementById('tip_msg').innerHTML = '<p style="color:red">英文背景图片名不能包含中文</p>'
          //   return;
          // }
          if(/[']/.test($scope.uploadFiles.bgPicEn.name)){
            document.getElementById('tip_msg').innerHTML = '<p style="color:red">英文背景图片名不能包含英文单引号</p>'
            return;
          }
          let nickName = "autolayout_bgPicEn" + $scope.getTime() + "_" + getFileType($scope.uploadFiles.bgPicEn.name);
          $scope.newCard.bgPic.picEnUrl = $scope.domain + "/" + nickName;
          $scope.uploadFiles.bgPicEn.nickName = nickName;
          files.push($scope.uploadFiles.bgPicEn);
        }
      }
      let finishUploadCallBack = function (data, uploadResData) {
        data.belongProject = $scope.newLayout.belongProject;
        if (uploadResData
          && data.videoAttr
          && data.videoAttr.files
          && data.videoAttr.files.length > 0) {
          for (let i = 0; i < uploadResData.length; i++) {
            let ele_uploadResData = uploadResData[i];
            for (let j = 0; j < data.videoAttr.files.length; j++) {
              let ele_files = data.videoAttr.files[j];
              if (ele_files.url.indexOf(ele_uploadResData.newName) != -1) {
                ele_files.md5 = ele_uploadResData.md5;
              }
            }
          }
        }
        $http({
          method: 'POST',
          url: '/layoutPage/uploadLayoutPageCard',
          data: { data: data }
        }).then(function (response) {
          if (response.data.code == 0) {
            document.getElementById('tip_msg').innerHTML = '<p style="color:green">提交成功</p>'
            $timeout(function () {
              $scope.refreshCardsTable(true);
              $scope.refreshCardsTableList(true);
              $scope.drawAddItem(response.data.data);
              $('#createWindow').modal('hide');
            }, 200)

          } else {
            $scope.$emit('notification', { type: 'danger', message: response.data.msg });
          }
        }, function (err) {
          $scope.$emit('notification', { type: 'danger', message: '网络错误,提交失败' });
        });
      }
      document.getElementById('tip_msg').innerHTML = '<p style="color:green">正在提交……</p>'
      uploadFileService.uploadFile($scope, $scope.curProjectNameEn, files, $scope.newCard, finishUploadCallBack);
    }

    $scope.drawAddItemList = function (item) {
      var picHTML = '';
      var titleHTML = '';
      var iconHTML = '';
      var cardTypeHtml = '';
      var delHTML = '<div class=\'delete\' onclick=\'angular.element(this).scope().delItemList(this)\'>×</div>';
      picHTML = getBgPicHTML(item);
      iconHTML = getIconHTML(item);
      titleHTML = getTitleHTML(item);
      cardTypeHtml = getCardTypeHtml(item);
      item.innerHTML = $sce.trustAsHtml(delHTML
        + cardTypeHtml
        + picHTML
        + titleHTML
        + iconHTML);
      $scope.newItem.page.items.push({
        layoutType: "card",
        card: item,
        innerHTML: item.innerHTML
      });
      $scope.$apply();
    }
    $scope.delItemList = function (item) {
      $scope.newItem.page.items.splice($(item).parent().parent().children(".index")[0].innerText, 1);
      $scope.$apply();
    }
    $scope.drawAddItem = function (item) {
      if (!$scope.curCardNode || $scope.newItem.layoutType == 'page') return;
      if ($($scope.curCardNode).siblings('.hide').length != 0) {
        $($scope.curCardNode).siblings('.hide')[0].remove();
      }
      if ($($scope.curCardNode).siblings('.bgPic').length != 0) {
        $($scope.curCardNode).siblings('.bgPic')[0].remove();
      }
      if ($($scope.curCardNode).siblings('.title').length != 0) {
        $($scope.curCardNode).siblings('.title')[0].remove();
      }
      if ($($scope.curCardNode).siblings('.icon').length != 0) {
        $($scope.curCardNode).siblings('.icon')[0].remove();
      }
      var picHTML = '';
      var titleHTML = '';
      var iconHTML = '';
      var cardTypeHtml = '';
      if ($scope.newItem.layoutType == "card") {
        cardTypeHtml = getCardTypeHtml(item);
        picHTML = getBgPicHTML(item);
        iconHTML = getIconHTML(item);
        titleHTML = getTitleHTML(item);
        $scope.newItem.card = item;
      }
      $($scope.curCardNode).parent()[0].innerHTML = $($scope.curCardNode).parent()[0].innerHTML + '<div class=\'hide\' >' + JSON.stringify($scope.newItem) + "</div>"
        + cardTypeHtml
        + picHTML
        + titleHTML
        + iconHTML;
      $timeout(function () {
        $('#addWindow').modal('hide');
      }, 200)
    }
    let drawAddItemPage = function (item) {
      if (!$scope.curCardNode) return;
      if ($($scope.curCardNode).siblings('.hide').length != 0) {
        $($scope.curCardNode).siblings('.hide')[0].remove();
      }
      var picHTML = '';
      var titleHTML = '';
      var iconHTML = '';
      if (!$($scope.curCardNode).parent()[0]) return;
      $($scope.curCardNode).parent()[0].innerHTML = $($scope.curCardNode).parent()[0].innerHTML + '<div class=\'hide\' >' + JSON.stringify($scope.newItem) + "</div>"
        + picHTML
        + titleHTML
        + iconHTML;
      $timeout(function () {
        $('#pageWindow').modal('hide');
      }, 200)
    }

    let drawAddItemListALL = function (item) {
      if (!$scope.curCardNode) return;
      if ($($scope.curCardNode).siblings('.hide').length != 0) {
        $($scope.curCardNode).siblings('.hide')[0].remove();
      }
      var picHTML = '';
      var titleHTML = '';
      var iconHTML = '';
      if (!$($scope.curCardNode).parent()[0]) return;
      $($scope.curCardNode).parent()[0].innerHTML = $($scope.curCardNode).parent()[0].innerHTML + '<div class=\'hide\' >' + JSON.stringify($scope.newItem) + "</div>"
        + picHTML
        + titleHTML
        + iconHTML;
      $timeout(function () {
        $('#addListWindow').modal('hide');
      }, 200)
    }
    var getBgPicHTML = function (element_card) {
      if (element_card && element_card.isShowBgPic && element_card.bgPic && element_card.bgPic.picUrl) {
        let picHTML = '<img class="bgPic" style="height:100%;width:100%;background-size:100% 100%;background-repeat: no-repeat;background-position: center;" src="' + element_card.bgPic.picUrl + '?time=' + $scope.getTime() + '" />'
        return picHTML;
      }
      return '';
    }
    var getIconHTML = function (element_card) {
      if (element_card && element_card.isShowIcon && element_card.icon && element_card.icon.picUrl) {
        let style = "";
        if (element_card.icon.attr) {
          try {
            let json = JSON.parse(element_card.icon.attr);
            let width = json.width.replace(/px/ig, "")
            let height = json.height.replace(/px/ig, "")
            style = style + "position:absolute;"
            style = style + "width:" + (width * adjustParam).toFixed(2) + "px;";
            style = style + "height:" + (height * adjustParam).toFixed(2) + "px;";
            if (json.margin) {
              let margins = json.margin.replace(/px/ig, "").split(/\s+/);
              let top = (margins[0] * adjustParam).toFixed(2) + "px";
              let right = (margins[1] * adjustParam).toFixed(2) + "px";
              let bottom = (margins[2] * adjustParam).toFixed(2) + "px";
              let left = (margins[3] * adjustParam).toFixed(2) + "px";
              let margin = top + " " + right + " " + bottom + " " + left;
              style = style + "margin:" + margin + ";";
            }
            if (json.position) {
              let positions = json.position.toLocaleUpperCase().split("|");
              for (let index = 0; index < positions.length; index++) {
                const element = positions[index];
                if (element == "LEFT")
                  style = style + "left:0;";
                if (element == "RIGHT")
                  style = style + "right:0;";
                if (element == "TOP")
                  style = style + "top:0;";
                if (element == "BOTTOM")
                  style = style + "bottom:0;";
                if (element == "CENTER")
                  style = style + "top: 50%;left: 50%;transform: translate(-50%, -50%);";
                if (element == "CENTER_VERTICAL")
                  style = style + "top: 50%;transform: translate(0%, -50%);";
                if (element == "CENTER_HORIZONTAL")
                  style = style + "left: 50%;transform: translate(-50%, 0%);";
              }
            }
          } catch (error) {
            console.log(error)
          }
        }
        let iconHTML = '<img class="icon" style="' + style + '" src="' + element_card.icon.picUrl + '?time=' + $scope.getTime() + '" alt=""/>';
        return iconHTML;
      }
      return '';
    }
    var getCardTypeHtml = function (element_card) {
      let picUrl;
      if (element_card && element_card.cardType == "videoView") {
        picUrl = "../../../assets/images/icon_video.png";
      } else if (element_card && element_card.cardType == "bannerView") {
        picUrl = "../../../assets/images/icon_banner.png";
      }
      let iconHTML = '';
      if (picUrl) {
        iconHTML = '<img class="icon" style="width:50px;height:50px;position:absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);" src="' + picUrl + '" alt=""/>';
      }
      return iconHTML;
    }
    var getTitleHTML = function (element_card) {
      if (element_card && element_card.isShowTitle && element_card.title && element_card.title.name && element_card.title.name != 'null') {
        let showTitleStr = element_card.title.name;
        let style = "text-align:left;word-break:keep-all;white-space:nowrap;letter-spacing: 1px;line-height: 163%;";
        if(element_card.title.name.indexOf("\\n")!=-1){
          showTitleStr = showTitleStr.replace(/\\n/g, "<br>");
        }
        if(element_card.title.name.indexOf(" ")!=-1){
          showTitleStr = showTitleStr.replace(/ /g, "&nbsp;");
        }
          
        if (element_card.title.attr) {
          try {
            let json = JSON.parse(element_card.title.attr);
            let textSize = json.size.replace(/px/ig, "");
            textSize = (textSize * adjustParam).toFixed(2);
            style = style + "font-size:" + textSize + "px !important;";
           
            if (json.color) {
              if (json.color.length == 9) {
                let a = (parseInt(json.color.substr(1, 2), 16) / 255).toFixed(2);
                let red = (parseInt(json.color.substr(3, 2), 16)).toFixed(0);
                let green = (parseInt(json.color.substr(5, 2), 16)).toFixed(0);
                let blue = (parseInt(json.color.substr(7, 2), 16)).toFixed(0);
                style = style + "color:rgba(" + red + "," + green + "," + blue + "," + a + ");";
              } else {
                style = style + "color:" + json.color + ";";
              }
            }

            if (json.margin) {
              let margins = json.margin.replace(/px/ig, "").split(/\s+/);
              let top = (margins[0] * adjustParam).toFixed(2) + "px";
              let right = (margins[1] * adjustParam).toFixed(2) + "px";
              let bottom = (margins[2] * adjustParam).toFixed(2) + "px";
              let left = (margins[3] * adjustParam).toFixed(2) + "px";
              let margin = top + " " + right + " " + bottom + " " + left;
              style = style + "margin:" + margin + ";";
            }
            if (json.position) {
              
              let positionUp = json.position.toLocaleUpperCase();
              style = style + transformOriginFunc(positionUp);
              let positions = json.position.toLocaleUpperCase().split("|");
              for (let index = 0; index < positions.length; index++) {
                const element = positions[index];
                if (element == "LEFT")
                  style = style + "left:0;";
                if (element == "RIGHT")
                  style = style + "right:0;";
                if (element == "TOP")
                  style = style + "top:0;";
                if (element == "BOTTOM")
                  style = style + "bottom:0;";
                if (element == "CENTER")
                  style = style + "top: 50%;left: 50%;";
                if (element == "CENTER_VERTICAL")
                  style = style + "top: 50%;";
                if (element == "CENTER_HORIZONTAL")
                  style = style + "left: 50%;";
              }
              
              if(positionUp.indexOf("CENTER_VERTICAL")!=-1){
                style = style + "transform: translate(0%, -50%)"
              }else if(positionUp.indexOf("CENTER_HORIZONTAL")!=-1){
                style = style + "transform: translate(-50%, 0%)"
              }else if(positionUp.indexOf("CENTER")!=-1){
                style = style + "transform: translate(-50%, -50%)"
              }
              if(style.indexOf("transform:")!=-1){
                if(textSize<12){
                  let transform = textSize/12;
                  style = style + " scale("+transform+");"
                }else{
                  style = style + ";";
                }
              }else{
                if(textSize<12){
                  let transform = textSize/12;
                  style = style + "transform: scale("+transform+");"
                }
              }
            }
          } catch (error) {
            console.log(error)
          }
        }
        let titleHTML = '<div class="title" style="' + style + '" >' + showTitleStr + '</div>';
        return titleHTML;
      }
      return '';
    }
    let transformOriginFunc = function(position){
      let style = "transform-origin:"

      if(position.indexOf("CENTER_HORIZONTAL")==-1
      &&position.indexOf("CENTER_VERTICAL")==-1
      &&position.indexOf("CENTER")!=-1){
        style = style+" 50% 50%";
      }else{
        if(position.indexOf("LEFT")!=-1){
          style = style+" 0%";
        }else if(position.indexOf("CENTER_HORIZONTAL")!=-1){
          style = style+" 50%";
        }else if(position.indexOf("RIGHT")!=-1){
          style = style+" 100%";
        }else{
          style = style+" 0%";
        }
  
        if(position.indexOf("TOP")!=-1){
          style = style+" 0%";
        }else if(position.indexOf("CENTER_VERTICAL")!=-1){
          style = style+" 50%";
        }else if(position.indexOf("BOTTOM")!=-1){
          style = style+" 100%";
        }else{
          style = style+" 0%";
        }
      }
      style = style+";";
      return style;
    }
    function existAndNotString(value) {
      return value&&!(typeof value === 'string');
    }
    $scope.createLayoutOne = function () {
      if (/[']/.test($scope.newLayout.minUsedVer)
        || /[']/.test($scope.newLayout.name)
        || /[']/.test($scope.newLayout.nameEng)
      ) {
        $scope.$emit('notification', { type: 'danger', message: '内容包含英文的单引号，不允许提交!' });
        return;
      }
      if (existAndNotString($scope.newLayout.nameAttr)) {
        if ($scope.newLayout.nameAttr.clickType == "exeCommand") {
          if ($scope.newLayout.nameAttr.exeCommandAttrSelect&&$scope.newLayout.nameAttr.exeCommandAttrSelect!='otherExeCommand') {
            $scope.newLayout.nameAttr.exeCommandAttr = $scope.newLayout.nameAttr.exeCommandAttrSelect;
          }
          if (!$scope.newLayout.nameAttr.exeCommandAttr) {
            document.getElementById('tip_msg').innerHTML = '<p style="color:red">请填写指令内容</p>'
            return;
          } else if (/[']/.test($scope.newLayout.nameAttr.exeCommandAttr)) {
            document.getElementById('tip_msg').innerHTML = '<p style="color:red">指令内容包含英文的单引号，不允许提交!</p>'
            return;
          }
        }
        $scope.newLayout.nameAttr = angular.toJson($scope.newLayout.nameAttr);
      }
      if($scope.newLayout.pageAttr){
        if (!isSize($scope.newLayout.pageAttr.pagePaddingTop)) {
          $scope.$emit('notification', { type: 'danger', message: '页面上边距属性格式错误，应为：0-9999px' });
          return;
        }
        if (!isSize($scope.newLayout.pageAttr.pagePaddingLR)) {
          $scope.$emit('notification', { type: 'danger', message: '页面左右边距属性格式错误，应为：0-9999px' });
          return;
        }
        if (!isNumber($scope.newLayout.pageAttr.autoInDvbTime)) {
          $scope.$emit('notification', { type: 'danger', message: '无操作多少秒后自动进入直播属性格式错误，应为：0-99' });
          return;
        }
      }
      if (existAndNotString($scope.newLayout.pageAttr)) {
        $scope.newLayout.pageAttr = angular.toJson($scope.newLayout.pageAttr);
      }
      if ($scope.newLayout.titleIcon&&existAndNotString($scope.newLayout.titleIcon.attr)) {
        $scope.newLayout.titleIcon.attr = angular.toJson($scope.newLayout.titleIcon.attr);
      }
      if ($scope.newLayout.titleBgPic&&existAndNotString($scope.newLayout.titleBgPic.attr)) {
        $scope.newLayout.titleBgPic.attr = angular.toJson($scope.newLayout.titleBgPic.attr);
      }
      var items = [];
      var cardsHtml = $scope.gridster.serialize();
      cardsHtml = $scope.gridster.sort_by_row_and_col_asc(cardsHtml)
      for (let index = 0; index < cardsHtml.length; index++) {
        const element = cardsHtml[index].innerText;
        let results = element.match('<div class="hide">([^<]+)</div>')
        if (results) {
          let item = {
            col: cardsHtml[index].col,
            row: cardsHtml[index].row,
            size_x: cardsHtml[index].size_x,
            size_y: cardsHtml[index].size_y,
            layoutType: JSON.parse(results[results.length - 1]).layoutType,
            card: JSON.parse(results[results.length - 1]).card,
            page: JSON.parse(results[results.length - 1]).page,
          }
          items.push(item);
        } else {
          let item = {
            col: cardsHtml[index].col,
            row: cardsHtml[index].row,
            size_x: cardsHtml[index].size_x,
            size_y: cardsHtml[index].size_y,
          }
          items.push(item);
        }
      }
      $scope.newLayout.items = items;
      let finishUploadCallBack = function () {
        $http({
          method: 'POST',
          url: '/layoutPage/uploadLayoutPage',
          data: { data: $scope.newLayout }
        }).then(function (result) {
          if (result.data.code == 0) {
            $scope.$emit('notification', { type: 'success', message: '成功,2秒后返回上一级页面' });
            $timeout(function () {
              $scope.toBack();
            }, 2000)

          } else {
            $scope.$emit('notification', { type: 'danger', message: result.data.msg });
          }
        }, function (err) {
          $scope.$emit('notification', { type: 'danger', message: '网络错误,提交失败' });
        });
      }
      
      let files = [];
      if ($scope.uploadFiles.layoutBg) {
        if(/[']/.test($scope.uploadFiles.layoutBg.name)){
          $scope.$emit('notification', { type: 'danger', message: '背景图片名包含英文的单引号，不允许提交!' });
          return;
        }
        $scope.newLayout.bgPic = {};
        let nickName = "autolayout_page_bgPic" + $scope.getTime() + "_" + getFileType($scope.uploadFiles.layoutBg.name);
        $scope.newLayout.bgPic.picUrl = $scope.domain + "/" + nickName;
        $scope.uploadFiles.layoutBg.nickName = nickName;
        files.push($scope.uploadFiles.layoutBg);
      }
      if ($scope.uploadFiles.titleIcon) {
        if(/[']/.test($scope.uploadFiles.titleIcon.name)){
          $scope.$emit('notification', { type: 'danger', message: '背景图片名包含英文的单引号，不允许提交!' });
          return;
        }
        if(!$scope.newLayout.titleIcon){
          $scope.newLayout.titleIcon = {}
        }
        let nickName = "autolayout_page_titleIcon" + $scope.getTime() + "_" + getFileType($scope.uploadFiles.titleIcon.name);
        $scope.newLayout.titleIcon.picUrl = $scope.domain + "/" + nickName;
        $scope.uploadFiles.titleIcon.nickName = nickName;
        files.push($scope.uploadFiles.titleIcon);
      }
      if ($scope.uploadFiles.titleIconSelect) {
        if(/[']/.test($scope.uploadFiles.titleIconSelect.name)){
          $scope.$emit('notification', { type: 'danger', message: '背景图片名包含英文的单引号，不允许提交!' });
          return;
        }
        if(!$scope.newLayout.titleBgPic){
          $scope.newLayout.titleBgPic = {}
        }
        let nickName = "autolayout_page_titleIconSelect" + $scope.getTime() + "_" + getFileType($scope.uploadFiles.titleIconSelect.name);
        $scope.newLayout.titleIcon.picSelectUrl = $scope.domain + "/" + nickName;
        $scope.uploadFiles.titleIconSelect.nickName = nickName;
        files.push($scope.uploadFiles.titleIconSelect);
      }
      if ($scope.uploadFiles.titleBgPic) {
        if(/[']/.test($scope.uploadFiles.titleBgPic.name)){
          $scope.$emit('notification', { type: 'danger', message: '背景图片名包含英文的单引号，不允许提交!' });
          return;
        }
        if(!$scope.newLayout.titleBgPic){
          $scope.newLayout.titleBgPic = {}
        }
        let nickName = "autolayout_page_titleBgPic" + $scope.getTime() + "_" + getFileType($scope.uploadFiles.titleBgPic.name);
        $scope.newLayout.titleBgPic.picUrl = $scope.domain + "/" + nickName;
        $scope.uploadFiles.titleBgPic.nickName = nickName;
        files.push($scope.uploadFiles.titleBgPic);
      }
      if ($scope.uploadFiles.titleBgPicSelect) {
        if(/[']/.test($scope.uploadFiles.titleBgPicSelect.name)){
          $scope.$emit('notification', { type: 'danger', message: '背景图片名包含英文的单引号，不允许提交!' });
          return;
        }
        if(!$scope.newLayout.titleBgPic){
          $scope.newLayout.titleBgPic = {}
        }
        let nickName = "autolayout_page_titleBgPicSelect" + $scope.getTime() + "_" + getFileType($scope.uploadFiles.titleBgPicSelect.name);
        $scope.newLayout.titleBgPic.picSelectUrl = $scope.domain + "/" + nickName;
        $scope.uploadFiles.titleBgPicSelect.nickName = nickName;
        files.push($scope.uploadFiles.titleBgPicSelect);
      }
      uploadFileService.uploadFile($scope, $scope.curProjectNameEn, files, null, finishUploadCallBack);
    }
    $scope.toBack = function () {
      if ($scope.newLayout.belongPage) {
        $state.go('main.layoutPage', { itemId: $scope.newLayout.belongPage, belongProject: $scope.newLayout.belongProject })
      } else {
        $state.go('main.layoutPageGroup', { belongProject: $scope.newLayout.belongProject })
      }
    }


    $scope.refreshCardsTable = function (reload) {
      $http({
        method: 'GET',
        url: '/layoutPage/getAllLayoutPageCard?belongPage=' + $stateParams.itemId,
      }).then(function (response) {
        if (response.data.code == 0) {
          loadTable(response.data.data, reload)
        } else {
          console.log(response);
          //$scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        console.log(err);
        //$scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };
    var loadTable = function (data, reload) {
      if (reload) {
        $('#table2').bootstrapTable('load', data);
      } else {
        $('#table2').bootstrapTable({
          pagination: true,
          pageSize: 3, //每页显示10条
          cache: false,
          search: true,
          showColumns: 'true',
          columns: [{
            title: '名称',
            align: 'center',
            field: 'name',
          }, {
            title: '标题',
            align: 'center',
            formatter: function (value, row, index) {
              let s = "无";
              if (row.title && row.title.name && row.title.name != 'null') {
                s = row.title.name;
              }
              return s;
            },
          }, {
            title: '背景图片',
            align: 'center',
            formatter: function (value, row, index) {
              let s = "无";
              if (row.bgPic && row.bgPic != 'null') {
                s = row.bgPic && row.bgPic.picUrl ? '<a class = "view"  href="javascript:void(0)"><img style="max-width:70px;height:30px;"  src="' + row.bgPic.picUrl + '?time=' + $scope.getTime() + '" /></a>' : '无';
              }
              return s;
            },
          }, {
            title: '图标',
            align: 'center',
            formatter: function (value, row, index) {
              let s = "无";
              if (row.icon && row.icon.picUrl) {
                s = '<a class = "view"  href="javascript:void(0)"><img style="max-width:70px;height:30px;"  src="' + row.icon.picUrl + '?time=' + $scope.getTime() + '" /></a>';
              }
              return s;
            },
          }, {
            title: '卡片类型',
            align: 'center',
            field: 'cardType',
            formatter: function (value, row, index) {
              for (let index = 0; index < $scope.cardTypes.length; index++) {
                const element = $scope.cardTypes[index];
                if (row.cardType == element.nameEn) {
                  return element.nameCn;
                }
              }
              return row.cardType
            },
          }, {
            title: '操作',
            align: 'center',
            width: '120px',
            formatter: function (value, row, index) {
              row = angular.toJson(row);
              return '<button  class=\'btn btn-warning btn-xs\' onclick=\'angular.element(this).scope().drawAddItem(' + row + ')\'>添加</button>'
                + '&nbsp;&nbsp;<button  class=\'btn btn-danger btn-xs\' onclick=\'angular.element(this).scope().delCard(' + row + ')\'>删除</button>';
            }
          }
          ],
          data: data
        }
        );
      }
    }

    $scope.refreshCardsTableList = function (reload) {
      $http({
        method: 'GET',
        url: '/layoutPage/getAllLayoutPageCard?belongPage=' + $stateParams.itemId + '&cardType=card',
      }).then(function (response) {
        if (response.data.code == 0) {
          loadTableList(response.data.data, reload)
        } else {
          console.log(response);
          //$scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        console.log(err);
        //$scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };
    var loadTableList = function (data, reload) {
      if (reload) {
        $('#table3').bootstrapTable('load', data);
      } else {
        $('#table3').bootstrapTable({
          pagination: true,
          pageSize: 3, //每页显示10条
          cache: false,
          search: true,
          showColumns: 'true',
          columns: [{
            title: '名称',
            align: 'center',
            field: 'name',
          }, {
            title: '标题',
            align: 'center',
            formatter: function (value, row, index) {
              let s = "无";
              if (row.title && row.title.name && row.title.name != 'null') {
                s = row.title.name;
              }
              return s;
            },
          }, {
            title: '背景图片',
            align: 'center',
            formatter: function (value, row, index) {
              let s = "无";
              if (row.bgPic && row.bgPic != 'null') {
                s = row.bgPic && row.bgPic.picUrl ? '<a class = "view"  href="javascript:void(0)"><img style="max-width:70px;height:30px;"  src="' + row.bgPic.picUrl + '?time=' + $scope.getTime() + '" /></a>' : '无';
              }
              return s;
            },
          }, {
            title: '图标',
            align: 'center',
            formatter: function (value, row, index) {
              let s = "无";
              if (row.icon && row.icon.picUrl) {
                s = '<a class = "view"  href="javascript:void(0)"><img style="max-width:70px;height:30px;"  src="' + row.icon.picUrl + '?time=' + $scope.getTime() + '" /></a>';
              }
              return s;
            },
          }, {
            title: '卡片类型',
            align: 'center',
            field: 'cardType',
            formatter: function (value, row, index) {
              for (let index = 0; index < $scope.cardTypes.length; index++) {
                const element = $scope.cardTypes[index];
                if (row.cardType == element.nameEn) {
                  return element.nameCn;
                }
              }
              return row.cardType
            },
          }, {
            title: '操作',
            align: 'center',
            width: '120px',
            formatter: function (value, row, index) {
              row = angular.toJson(row);
              return '<button  class=\'btn btn-warning btn-xs\' onclick=\'angular.element(this).scope().drawAddItemList(' + row + ')\'>添加</button>'
                + '&nbsp;&nbsp;<button  class=\'btn btn-danger btn-xs\' onclick=\'angular.element(this).scope().delCard(' + row + ')\'>删除</button>';
            }
          }
          ],
          data: data
        }
        );
      }
    }


    $scope.delCard = function (card) {
      $http({
        method: 'POST',
        url: '/layoutPage/deleteLayoutPageCard',
        data: { data: card }
      }).then(function (response) {
        if (response.data.code == 0) {
          //$scope.$emit('notification', {type: 'success', message: '删除成功'});
          $scope.refreshCardsTable(true);
          $scope.refreshCardsTableList(true);
        } else {
          console.err(response.data.msg);
          $scope.$emit('notification', { type: 'danger', message: response.data.msg });
        }
      }, function (err) {
        $scope.$emit('notification', { type: 'danger', message: '网络错误，与服务器通信错误' });
      });
    }

    $scope.uploadHorizontalList = function () {
      if (isNaN($scope.newItem.page.horizontalListAttr.proportionWh)) {
        document.getElementById('tip_msg_two').innerHTML = '<p style="color:red">“宽高比”的内容必须是数值</p>'
        return;
      }
      if (!isJson($scope.newItem.page.nameAttr)) {
        document.getElementById('tip_msg_two').innerHTML = '<p style="color:red">标题属性格式不对（要求JSON字符串）</p>'
        return;
      }
      if (/[']/.test($scope.newItem.page.name)
      ) {
        document.getElementById('tip_msg_two').innerHTML = '<p style="color:red">内容包含英文的单引号，不允许提交!</p>'
        return;
      }

      $http({
        method: 'POST',
        url: '/layoutPage/uploadLayoutPage',
        data: { data: $scope.newItem.page }
      }).then(function (result) {
        if (result.data.code == 0) {
          //$scope.drawAddItem(response.data.data);
          document.getElementById('tip_msg_two').innerHTML = '<p style="color:green">提交成功</p>'
          $timeout(function () {
            drawAddItemListALL();
          }, 200)
        } else {
          document.getElementById('tip_msg_two').innerHTML = '<p style="color:red">' + result.data.msg + '</p>'
        }
      }, function (err) {
        document.getElementById('tip_msg_two').innerHTML = '<p style="color:red">网络错误,提交失败!</p>'
      });
    }
    let isJson = function (str) {

      if (!str) return false;
      if (/[']/.test(str)) return false;
      if (str == 1) return false;
      if (typeof str == 'string') {
        try {
          JSON.parse(str);
          return true;
        } catch (e) {
          return false;
        }
      }
    }
    $scope.getTime = function (file) {
      return Date.now();
    }
    var isChinese = function (temp) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(temp)) {
        return true;
      }
      return false;
    }

    //添加轮播图点击事件
    $scope.addClickActions = function () {
      $scope.cardInput.bannerAttr.attr.clickAction.push("");
    }

    //删除轮播图点击事件
    $scope.delClickActions = function (index) {
      $scope.cardInput.bannerAttr.attr.clickAction.splice(index, 1);
    }

    //正则判断size属性
    var isSize = function (temp) {
      if(!temp)return true;
      if (/^(-?\d{1,4})px$/.test(temp)) {
        return true;
      } else {
        return false;
      }
    }
    var isNumber = function (temp) {
      if(temp==undefined||temp=="")return true;
      if ((/^\d{1,10}$/.test(temp))) {
        return true;
      }
      return false;
    }
    //正则判断color属性
    var isColor = function (temp) {
      if(!temp)return true;
      if (/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{8})$/.test(temp)) {
        return true;
      } else {
        return false;
      }
    }

    //正则判断position属性
    var isPosition = function (temp) {
      if (/^(left|right|top|bottom|center|center_horizontal|center_vertical){1}(([|]left)|([|]right)|([|]top)|([|]bottom)|([|]center)|([|]center_horizontal)|([|]center_vertical)){0,1}$/.test(temp)) {
        return true;
      } else {
        return false;
      }
    }
    //正则判断margin属性
    var isMargin = function (temp) {
      if (/^([0-9]{1,4}px [0-9]{1,4}px [0-9]{1,4}px [0-9]{1,4}px)$/.test(temp)) {
        return true;
      } else {
        return false;
      }
    }


    //正则判断serviceId
    var isServiceId = function (temp) {
      if (!/.*[\u4e00-\u9fa5]+.*$/.test(temp)) {
        return true;
      }
      return false;
    }

    //正则判断loopTime属性
    var isLoopTime = function (temp) {
      if (/^([0-9]{1,4})s$/.test(temp)) {
        return true;
      } else {
        return false;
      }
    }

    //正则判断中文标题只为中文
    var isChineseOnly = function (temp) {
      if (/^[\u4e00-\u9fa5]{0,100}$/.test(temp)) {
        return true;
      }
      return false;
    }

    //正则判断英文标题只为英文
    var isEnglishOnly = function (temp) {
      if (/^[A-Z a-z]{0,100}$/.test(temp)) {
        return true;
      }
      return false;
    }

    var isContainQuotationMarks = function (temp) {
      if (/.*('|").*/.test(temp)) {
        return true;
      } else {
        return false;
      }
    }
    
    var sendMsgPageView = function (temp) {
      return document.getElementById('tip_msg_viewpager').innerHTML = '<p style="color:red">' + temp + '</p>';
    }
    var sendMsg = function (temp) {
      return document.getElementById('tip_msg').innerHTML = '<p style="color:red">' + temp + '</p>';
    }

    var getFileType = function(name){
      return name.substring(name.lastIndexOf("."));
    }

  }]);