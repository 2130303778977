/**
 * Created by Administrator on 2017/5/24.
 */
angular.module('controllers')
  .controller('mainCtr', ['$scope', '$http', '$state','$location',
    function ($scope, $http, $state,$location) {
      'use strict';
      
      $scope.Arr = ['日', '一', '二', '三', '四', '五', '六'];
      $scope.dt = new Date();
      $scope.weekIndex = $scope.dt.getDay();
      $scope.week = '星期' + $scope.Arr[$scope.weekIndex];
      $http({
        method: 'GET',
        url: '/config/getWebConfig',
      }).then(function (response) {
          if (response.data.code === 0) {
              $scope.domain = response.data.domain;
              $scope.uploadToken = response.data.uploadToken;
              $scope.fileSaveWay = response.data.fileSaveWay;
          }
      }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })
      $http({
        method: 'GET',
        url: '/web/user/getUser',
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.user = response.data.user;
          $scope.user.toChangePwd = response.data.toChangePwd;
          //console.log($scope.user);
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
      $scope.getUrl = function(){
        return $location.url();
      }
      $scope.doLoginOut = function(){
        $http({
          method: 'POST',
          url: '/web/user/loginOut',
        }).then(function (response) {
          console.log(response)
          if (response.data.code == 0) {
            $state.go('login');
          } else {
            $scope.$emit('notification', {type: 'danger', message: response.data.msg});
          }
        }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误,登出失败'});
        });
      }


      $(window).on('resize', function () {
        if ($(window).width() > 768){
          $('#sidebar-collapse').collapse('show')

        }
      });
      $(window).on('resize', function () {
        if ($(window).width() <= 767){
          $('#sidebar-collapse').collapse('hide')
        }
      });

    }
  ]);