/**
 * Created by Administrator on 2017/7/12.
 */

'use strict';
angular.module('controllers').controller('upgradeConfigProjectInfoCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state', '$stateParams',
  function ($scope, Upload, $http, $window, $timeout, $state, $stateParams) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");
    
    var subscription = null;
    //粗略检测是否非法进入此页面
    $scope.checkState = function () {
      if (!$stateParams.upgradeId||!$stateParams.belongProject) {
        $scope.$emit('notification', {type: 'danger', message: '非法请求页面'});
        $scope.errorRequest = true;
        return;
      }
      
      $http({
        method: 'Post',
        url: '/web/configProjectInfo/findOne',
        data:{data:{_id:$stateParams.upgradeId}},
      }).then(function (response) {
          if (response.data.code === 0) {
            $scope.upgrade = response.data.data;
            if($scope.upgrade.wifiSSid){
              $scope.lengthSSid = 20-mbStringLength($scope.upgrade.wifiSSid);
            }
          }
      }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if(element.nameEn==$stateParams.belongProject){
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
          $http({
            method: 'GET',
            url: '/config/getWebConfig',
          }).then(function (response) {
              if (response.data.code === 0) {
                  $scope.domain = response.data.domain+"/"+$scope.curProjectNameEn;
                  $scope.uploadToken = response.data.uploadToken;
                  $scope.fileSaveWay = response.data.fileSaveWay;
              }
          }, function (err) {
              $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
          })
        }
      });
    };
    
    $scope.uploadFile = function () {
      if($scope.upgrade.wifiPwd){
        if($scope.upgrade.wifiPwd.length>16){
          return $scope.$emit('notification', {type: 'danger', message: 'wifi密码长度不能大于16位'});
        }else if($scope.upgrade.wifiPwd.length<8){
          return $scope.$emit('notification', {type: 'danger', message: 'wifi密码长度不能小于8位'});
        }
      }
      if($scope.upgrade.wifiLoginPwd){
        if($scope.upgrade.wifiLoginPwd.length>16){
          return $scope.$emit('notification', {type: 'danger', message: 'wifi登陆密码长度不能大于16位'});
        }else if($scope.upgrade.wifiLoginPwd.length<8){
          return $scope.$emit('notification', {type: 'danger', message: 'wifi登陆密码长度不能小于8位'});
        }
      }
      var regexIp = new RegExp('^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$');
      if($scope.upgrade.networkType=="1"){
        if(!$scope.upgrade.pppoeConfig){
          $scope.$emit('notification', {type: 'danger', message: '请填写PPPOE的信息'});
          return;
        }
        if(!$scope.upgrade.pppoeConfig.account){
          $scope.$emit('notification', {type: 'danger', message: '请填写PPPOE账号'});
          return;
        }
        if(!$scope.upgrade.pppoeConfig.pwd){
          $scope.$emit('notification', {type: 'danger', message: '请填写PPPOE密码'});
          return;
        }
        
      }
      if($scope.upgrade.networkType=="2"){
        if(!$scope.upgrade.staticIpConfig){
          $scope.$emit('notification', {type: 'danger', message: '请填写静态IP的信息'});
          return;
        }
        if(!regexIp.test($scope.upgrade.staticIpConfig.ipAddr)){
          $scope.$emit('notification', {type: 'danger', message: '静态IP的IP地址格式错误'});
          return;
        }
        if(!regexIp.test($scope.upgrade.staticIpConfig.gatewayIp)){
          $scope.$emit('notification', {type: 'danger', message: '静态IP的网关格式错误'});
          return;
        }
        if(!regexIp.test($scope.upgrade.staticIpConfig.subnetMast)){
          $scope.$emit('notification', {type: 'danger', message: '静态IP的子网掩码格式错误'});
          return;
        }
      }
      
      if($scope.upgrade.dns1){
        if(!regexIp.test($scope.upgrade.dns1)){
          $scope.$emit('notification', {type: 'danger', message: 'DNS1的格式错误'});
          return;
        }
      }
      if($scope.upgrade.dns2){
        if(!regexIp.test($scope.upgrade.dns2)){
          $scope.$emit('notification', {type: 'danger', message: 'DNS2的格式错误'});
          return;
        }
      }
      if($scope.upgrade.gatewayIp){
        if(!regexIp.test($scope.upgrade.gatewayIp)){
          $scope.$emit('notification', {type: 'danger', message: '局域网IP的格式错误'});
          return;
        }
      }

      
      
      finishUploadCallBack($scope.upgrade);
    };
    
    function finishUploadCallBack(data) {
      console.log(data);
      $http({
        method: 'POST',
        url: '/web/configProjectInfo/updateConfig',
        data: data
      }).then(function (response) {
        if (response.data.code === 0) {
            $scope.$emit('notification', {type: 'success', message: '成功，2秒后返回上一级页面'});
            $timeout(function () {
              $state.go('main.configProjectInfo',{belongProject:$stateParams.belongProject})
            }, 2000)
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })
    }

    $scope.backUrl = function(){
      $state.go('main.configProjectInfo',{belongProject:$stateParams.belongProject})
    }
    var isChinese = function(temp){
      if(/.*[\u4e00-\u9fa5]+.*$/.test(temp)) {
            return true;
          } 
          return false;
    }
    $scope.getTime = function(){
      return Date.now();
    }
    $scope.addBlackItem = function(){
      $scope.upgrade.blackList.push("");
    }
    $scope.delBlackItem = function(index){
      $scope.upgrade.blackList.splice(index,1);
    }
    $scope.addWhiteItem = function(){
      $scope.upgrade.whiteList.push("");
    }
    $scope.addAdb = function(){
      if($scope.upgrade.adbList==undefined){
        $scope.upgrade.adbList = [];
      }
      $scope.upgrade.adbList.push("");
    }
    $scope.delAdb = function(index){
      $scope.upgrade.adbList.splice(index,1);
    }
    $scope.delWhiteItem = function(index){
      $scope.upgrade.whiteList.splice(index,1);
    }
    $scope.lengthSSid = 20;
    $scope.checkWifiSSid = function(){
      $scope.lengthSSid = 20-mbStringLength( $scope.upgrade.wifiSSid);
    }
    function mbStringLength(s) {
      var totalLength = 0;
      var i;
      var charCode;
      for (i = 0; i < s.length; i++) {
        charCode = s.charCodeAt(i);
        if (charCode < 0x007f) {
          totalLength = totalLength + 1;
        } else if ((0x0080 <= charCode) && (charCode <= 0x07ff)) {
          totalLength += 2;
        } else if ((0x0800 <= charCode) && (charCode <= 0xffff)) {
          totalLength += 3;
        }
      }
      return totalLength;
    }
  }
]);