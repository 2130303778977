/**
 * Created by Administrator on 2017/7/12.
 */

'use strict';
angular.module('controllers').controller('setConfigCtr', ['$scope', 'Upload', '$http', '$window','$timeout','$state','$stateParams',
  function ($scope, Upload, $http, $window,$timeout,$state,$stateParams) {

    
    $scope.checkState = function () {
      $http({
        method: 'GET',
        url: '/web/webConfig/getConfig',
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.config = response.data.data;
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });

    };
    
    $scope.updateConfig = function(){
     
      var regex = new RegExp('^[0-9]+$');
      if(!regex.test($scope.config.pwdLockNum)){
        $scope.$emit('notification', {type: 'danger', message: '（输错密码次数）必须为整数'});
        return;
      }
      if(!regex.test($scope.config.pwdLockTime)){
        $scope.$emit('notification', {type: 'danger', message: '（账号锁死时间）必须为整数'});
        return;
      }
      if(!regex.test($scope.config.pwdChangeTime)){
        $scope.$emit('notification', {type: 'danger', message: '（强制修改密码时）必须为整数'});
        return;
      }
      var regexIp = new RegExp('^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}_[0-9]{1,3}$');
      if($scope.config.allowIpOne!='0'&&!regexIp.test($scope.config.allowIpOne)){
        $scope.$emit('notification', {type: 'danger', message: 'IP段(1)的格式错误'});
        return;
      }
      if($scope.config.allowIpTwo!='0'&&!regexIp.test($scope.config.allowIpTwo)){
        $scope.$emit('notification', {type: 'danger', message: 'IP段(2)的格式错误'});
        return;
      }
      if($scope.config.allowIpThr!='0'&&!regexIp.test($scope.config.allowIpThr)){
        $scope.$emit('notification', {type: 'danger', message: 'IP段(3)的格式错误'});
        return;
      }
      $http({
        method: 'POST',
        url: '/web/webConfig/updateConfig',
        data: $scope.config
      }).then(function (result) {
        if (result.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '保存成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: result.data.msg});
        }

      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
      });
  };
    

  $scope.replaceFileIp = function(){
    $http({
      method: 'POST',
      url: '/layoutPage/replaceFileIp',
      data: {project:"haiNanProject"}
    }).then(function (result) {
      if (result.data.code == 0) {
        $scope.$emit('notification', {type: 'success', message: '替换成功'});
      } else {
        $scope.$emit('notification', {type: 'danger', message: result.data.msg});
      }

    }, function (err) {
      $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
    });
  }

}
])
;