/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('layoutProjectCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state','$stateParams','uploadFileService',
  function ($scope, Upload, $http, $window, $timeout, $state,$stateParams,uploadFileService) {
    pageNumber = 1;
    $scope.projectPower = "all";
    var loadTable = function (data, reload) {
        if (reload) {
          $('#table2').bootstrapTable('load', data);
        } else {
          $('#table2').bootstrapTable({
              pagination: true,
              pageSize: 10, //每页显示10条
              pageNumber:pageNumber,
              cache: false,
              search: true,
              showColumns: 'true',
              showExport: true,//显示导出按钮
              exportDataType: 'all',//导出类型
              columns: [{
                title: '项目名',
                align: 'center',
                field: 'nameCn',
              }, {
                title: 'code',
                align: 'center',
                field: 'nameEn',
              },{
                title: '操作',
                align: 'center',
                width:'400px',
                formatter: function (value, row, index) {
                  row = angular.toJson(row);
                  return '<button  class=\'btn btn-success btn-xs\' style="width:85px;" onclick=\'angular.element(this).scope().toUpdate(' + row + ')\'>配置列表</button>'
                    +(($scope.user.projectGatewayManage==1||$scope.user.userManage==1)?'<button style="width:85px;margin-left:20px" class=\'btn btn-warning btn-xs\' onclick=\'angular.element(this).scope().toBindUuid(' + row + ')\'>项目网关管理</button>'
                    :'<button style="width:85px;margin-left:20px" class=\'btn btn-default btn-xs\' onclick=\'angular.element(this).scope().showNoRight()\'>项目网关管理</button>')
                    +($scope.user.userManage!=1?'':'<button style="width:85px;margin-left:20px" class=\'btn btn-danger btn-xs\' onclick=\'angular.element(this).scope().showDeleteModal(' + row + ')\'>删除</button>');
                }
              }
            ],
              data: data
            }
          );
        }
      }
    ;
    $scope.toBindUuid = function(data){
      pageNumber = $("#table2").bootstrapTable('getOptions').pageNumber;
      sessionStorage.setItem("pageNumber",pageNumber);
      $state.go('main.layoutGateway',{projectNameEn:data.nameEn})
    }
    $scope.showNoRight = function(){
      $scope.$emit('notification', {type: 'danger', message: '该账号没有项目网关管理权限，请联系管理员开通！'});
    }
    
    $scope.doUpdate = function(){
      $http({
        method: 'POST',
        url: '/layout/uploadLayout',
        data: $scope.upgrade
      }).then(function (result) {
        $timeout(function () {
          $('#changeModal').modal('hide')
        }, 10);

        if (result.data.code == 0) {
          $scope.init(true);
          $scope.$emit('notification', {type: 'success', message: '修改成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: result.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误,提交失败'});
      });
    }
    $scope.toUpdate = function(data){
      pageNumber = $("#table2").bootstrapTable('getOptions').pageNumber;
      sessionStorage.setItem("pageNumber",pageNumber);
      $state.go('main.projectContent',{belongProject:data.nameEn});
    }
    $scope.createProject = function(){
      $state.go('main.upgradeLayoutProject')
    }
    $scope.putProject = function(){
      sendMsgPutProject('red','');
      $('#progress')[0].style.width = '0%';
      $scope.upgrade = {
      }
      $timeout(function () {
        $('#putProjectModal').modal('show')
      }, 10);
    }
    $scope.putProjectSubmit = function(){
      if(!$scope.upgrade.file){
        if(!$scope.upgrade.fileName){
          sendMsgPutProject('red','请选择上传的文件');
          return;
        }
      }else{
        if(isChinese($scope.upgrade.file.name)){
          sendMsgPutProject('red','文件名不能包含中文');
          return;
        }
        var check = new RegExp(".zip$", 'i').test($scope.upgrade.file.name);
          if (!check) {
            sendMsgPutProject('red','上传文件不是zip格式');
            return
        }
        nickName = "projectData.zip";
        $scope.upgrade.file.nickName = nickName;
      }
      uploadFileService.cancelUpload($scope);
      uploadFileService.uploadFileLocal($scope,null,[$scope.upgrade.file],null,function finishUploadCallBack(data,uploadResData){
        $http({
          method: 'POST',
          url: '/layoutPage/putProject',
          data: data
        }).then(function (response) {
          if (response.data.code === 0) {
            $timeout(function () {
              $('#putProjectModal').modal('hide')
            }, 10);
            $scope.init(true);
            $scope.$emit('notification', {type: 'success', message: '导入成功'});
          } else {
            $('#progress')[0].style.width = '0%';
            sendMsgPutProject('red',response.data.msg);
          }
        }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
        })
      });
    }
    var isChinese = function(temp){
      if(/.*[\u4e00-\u9fa5]+.*$/.test(temp)) {
        return true;
      }
      return false;
    }
    $scope.pullProject = function(){
      for (let index = 0; index < $scope.belongs.length; index++) {
        const element_belongs = $scope.belongs[index];
        element_belongs.isChoice = false;
      }
      sendMsgPullProject("green","");
      $timeout(function () {
        $('#pullProjectModal').modal('show')
      }, 10);
    }

    $scope.pullProjectSubmit = function(){
      let pullProject = {
        belongProject:[]
      }
      if($scope.projectPower == "notAll"){
        for (let index = 0; index < $scope.belongs.length; index++) {
          const element_belongs = $scope.belongs[index];
          if(element_belongs.isChoice){
            pullProject.belongProject.push(element_belongs.nameEn);
          }
        }
        if(pullProject.belongProject.length==0){
          return sendMsgPullProject("red","请选择需要导出的项目");
        }
      }else{
        for (let index = 0; index < $scope.belongs.length; index++) {
          const element_belongs = $scope.belongs[index];
          pullProject.belongProject.push(element_belongs.nameEn);
        }
      }
      
      if(document.getElementById('tip_msg').innerHTML.indexOf('正在压缩中……')!=-1
      ||document.getElementById('tip_msg').innerHTML.indexOf('color:red')!=-1)return;
      sendMsgPullProject("green","正在压缩中……");

      $http({
        method: 'POST',
        url: '/layoutPage/pullProject',
        data: pullProject,
        timeout: 1000*60*60*24
      }).then(function (response) {
        console.log(response);
        if (response.data.code == 0) {
          $timeout(function () {
            $('#pullProjectModal').modal('hide')
          }, 10);
          window.location.href = changeDownloadUrl(response.data.url);
          $scope.$emit('notification', {type: 'success', message: '开始下载'});
        } else {
          sendMsgPullProject("red",response.data.msg);
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    }
    var sendMsgPullProject = function (color,temp) {
      return document.getElementById('tip_msg').innerHTML = '<p style="color:'+color+'">' + temp + '</p>';
    }
    var sendMsgPutProject = function (color,temp) {
      return document.getElementById('tip_msg2').innerHTML = '<p style="color:'+color+'">' + temp + '</p>';
    }

    var changeDownloadUrl = function(downloadUrl){
      let result;
      try {
        console.log("downloadUrl1:"+downloadUrl);
        let regServerUrl = new RegExp("^(http[s]{0,1}?:\/\/)([0-9a-z.]+)(:[0-9]+)?\/", "g");
        let regServerUrl2 = new RegExp("^(http[s]{0,1}?:\/\/)([0-9a-z.]+)(:[0-9]+)?\/", "g");
        let curUrl = window.location.href
        let curServerIp = regServerUrl.exec(curUrl)[0];
        let oldServerIp = regServerUrl2.exec(downloadUrl)[0];
        result = downloadUrl.replace(oldServerIp,curServerIp);
        console.log("downloadUrl2:"+result);
      } catch (error) {
        console.log(error);
      }
      return result;
    }

    //上传配置文件
    $scope.uploadFiles = function (file) {
      if (!file[0]) {
        return;
      }
      Upload.upload({
        url: '/web/gateway/uploadFile', method: 'POST', data: {file: file}
      }).then(function (resp) {
        if (resp.data.code != 0) {
          $scope.$emit('notification', {type: 'danger', message: resp.data.msg});
        } else {
          $scope.deviceInit(true);
          $scope.$emit('notification', {type: 'success', message: '成功导入' + resp.data.count + '条数据'});
        }
      }, function (resp) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };
    
    $scope.init = function (reload) {
      $http({
        method: 'GET',
        url: '/web/user/getUser',
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.user = response.data.user;
          $scope.user.toChangePwd = response.data.toChangePwd;
          //console.log($scope.user);
          $http({
            method: 'GET',
            url: '/config/getLayoutConfig',
          }).then(function (response) {
              if (response.data.code === 0) {
                  $scope.belongs = response.data.layoutProject;
                  $scope.activitys = response.data.layoutActivity;
                  if($scope.belongs&&$scope.belongs.length>0
                    &&$scope.user.belongProject&&$scope.user.belongProject.length>0){
                    let listTmp = [];
                    for (let index = 0; index < $scope.user.belongProject.length; index++) {
                      const element_user_belongProject = $scope.user.belongProject[index];
                      for (let j = 0; j < $scope.belongs.length; j++) {
                        const element = $scope.belongs[j];
                        if(element_user_belongProject.nameEn==element.nameEn){
                          listTmp.push(element);
                        }
                      }
                    }
                    $scope.belongs = listTmp;
                  }
                  if($stateParams.pageNumber){
                    pageNumber = $stateParams.pageNumber;
                  }
                  loadTable($scope.belongs, reload,pageNumber)
                  
              } else {
                $scope.$emit('notification', {type: 'danger', message: response.data.msg});
              }
          }, function (err) {
              $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
          })
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };
    

    $scope.showDeleteModal = function (data) {
      $scope.toDelProjectData =data;
      $timeout(function () {
        $('#closePageWin').modal('show')
      }, 10);
    };

    $scope.toDelete = function(){
      $http({
        method: 'POST',
        url: '/layoutPage/delLayoutProject',
        data:{data : $scope.toDelProjectData}
      }).then(function (response) {
        $timeout(function () {
          $('#closePageWin').modal('hide')
        }, 10);
        if (response.data.code == 0) {
          $scope.$emit('notification', {type: 'success', message: '删除成功'});
          $scope.init(true);
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    }
  }

  
])
