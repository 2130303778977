/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('hotelDeviceManageCtr', ['$scope', 'Upload', '$http', '$window', '$timeout','$state','$stateParams',
  function ($scope, Upload, $http, $window, $timeout,$state,$stateParams) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");
    $scope.flootList = []
    $scope.plug = {
      name: '',
      id: '',
      install_cmd:''
    };
   

    $scope.plugInit = function (reload) {
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if(element.nameEn==$stateParams.belongProject){
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
        }
      });
      $http({
        method: 'GET',
        url: '/web/hotel/getHotelFloorList?belongProject='+$stateParams.belongProject,
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.hotelFloorList = response.data.data;
          $http({
            method: 'GET',
            url: '/web/hotel/getHotelRoomList?belongProject='+$stateParams.belongProject,
          }).then(function (response) {
            if (response.data.code == 0) {
              $scope.hotelRoomList = response.data.data;
              $scope.showRoom();
            } else {
              $scope.$emit('notification', {type: 'danger', message: response.data.msg});
            }
          }, function (err) {
            $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
          });
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
      
    };

    $scope.updatePlug = function (data) {
      $state.go('main.upgradeConfigPlug', {upgradeId:data._id,belongProject:$stateParams.belongProject})
    };
    $scope.searchTxt = "";
    $scope.doSearch = function () {
      $scope.showRoom(1,$scope.searchTxt);
    };
    $scope.onclickRoom = function(room) {
      console.log(room);
      if(room.smartDevNum==0){
        return $scope.$emit('notification', {type: 'danger', message: "房间("+room.roomNum+")还未添加智能设备"});
      }
      $state.go('main.hotelDeviceCtrlManage', {roomId:room._id,belongProject:$stateParams.belongProject})
    }
    $scope.showRoom = function (type,roomName) {
      $scope.flootList = [];
      for (let index = 0; index < $scope.hotelFloorList.length; index++) {
        const element_floor = $scope.hotelFloorList[index];
        let roomList =[];
        let item_floor = {
          floorName:element_floor.floorName,
          roomList:roomList
        };
        for (let m = 0; m < $scope.hotelRoomList.length; m++) {
          const element_room = $scope.hotelRoomList[m];
          if(element_room.floorName!=element_floor.floorName)continue;
          if(type==2&&element_room.isPower==false)continue
          if(type==3&&element_room.isPower==true)continue
          let roomItem = {
            roomNum:element_room.roomNum,
            isPower:element_room.isPower,
            smartDevNum:element_room.smartDevNum,
            _id:element_room._id
          };
          if(roomName){
            if(String(roomItem.roomNum).indexOf(roomName)!=-1){
              roomList.push(roomItem);
            }
          }else{
            roomList.push(roomItem);
          }
        }
        $scope.flootList.push(item_floor);
      }
    }
    $scope.delPlugModal = function (data) {
      $scope.plug.name = data.name;
      $scope.plug.id = data._id;
      $timeout(function () {
        $('#delModal').modal('show')
      }, 10);
    };

    $scope.showCmdModel = function(cmd){
      $scope.plug.install_cmd = cmd;
      $timeout(function () {
        $('#cmdModal').modal('show')
      }, 10);
    };

    $scope.showForbiddenModal = function (data) {
      $scope.plug.id = data._id;
      $scope.plug.showName = "插件名称："+data.name;
      $scope.plug.newForbidden = '0';
      $scope.plug.forbidden = data.forbidden=='1'?'1':'0';

      $timeout(function () {
        $('#forbiddenModal').modal('show')
      }, 10);
    };
    $scope.doUpdateForbidden = function () {
      $http({
        method: 'POST',
        url: '/web/configPlug/changeForbidden',
        data: {
          id: $scope.plug.id,
          forbidden:$scope.plug.newForbidden,
          projectCode:$stateParams.belongProject,
        }
      }).then(function (response) {
        $timeout(function () {
          $('#forbiddenModal').modal('hide')
        }, 10);

        if (response.data.code == 0) {
          $scope.plugInit(true);
          $scope.$emit('notification', {type: 'success', message: '修改成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

    $scope.doDelPlug = function () {
      $http({
        method: 'DELETE',
        url: '/web/configPlug/delPlug',
        params: {
          id: $scope.plug.id
        }
      }).then(function (response) {
        $timeout(function () {
          $('#delModal').modal('hide')
        }, 10);

        if (response.data.code == 0) {
          $scope.plugInit(true);
          $scope.$emit('notification', {type: 'success', message: '删除成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

  }
]);