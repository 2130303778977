/**
 * Created by Administrator on 2017/7/12.
 */

'use strict';
angular.module('controllers').controller('upgradeConfigAdNoticeCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state', '$stateParams','uploadFileService',
  function ($scope, Upload, $http, $window, $timeout, $state, $stateParams,uploadFileService) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");
    //粗略检测是否非法进入此页面
    $scope.checkState = function () {
      if (!$stateParams.upgradeId||!$stateParams.belongProject) {
        $scope.$emit('notification', {type: 'danger', message: '非法请求页面'});
        $scope.errorRequest = true;
        return;
      }
      $scope.myTimes=[];
      for (let index = 0; index < 25; index++) {
        let element = {
          name:"",
          value:index,
        };
        if(index>=10){
          element.name=index+":00"
        }else{
          element.name="0"+index+":00"
        }
        $scope.myTimes.push(element);
      }
      $http({
        method: 'Post',
        url: '/web/configAd/findOneAdNotice',
        data:{data:{_id:$stateParams.upgradeId}},
      }).then(function (response) {
          if (response.data.code === 0) {
            $scope.upgrade = response.data.data;
            
            $scope.upgrade.attr = angular.fromJson($scope.upgrade.attr);
            if($scope.upgrade.title&&$scope.upgrade.title.attr){
              $scope.upgrade.title.attr = angular.fromJson($scope.upgrade.title.attr);
            }
            
            if($scope.upgrade.validTime==1){
              $scope.upgrade.validTimeAttr.start = new Date($scope.upgrade.validTimeAttr.start);  
              $scope.upgrade.validTimeAttr.end = new Date($scope.upgrade.validTimeAttr.end);  
            }
          }
      }, function (err) {
          $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if(element.nameEn==$stateParams.belongProject){
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
          $http({
            method: 'GET',
            url: '/config/getWebConfig',
          }).then(function (response) {
              if (response.data.code === 0) {
                  $scope.domain = response.data.domain+"/"+$scope.curProjectNameEn;
                  $scope.uploadToken = response.data.uploadToken;
                  $scope.fileSaveWay = response.data.fileSaveWay;
              }
          }, function (err) {
              $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
          })
        }
      });
    };
    function timeAdd0(str) {
      str = str+'';
      if(str.length<=1){
          str='0'+str;
      }
      console.log(str);
      return str
    }
    $scope.uploadFile = function () {
      if (/[']/.test($scope.upgrade.title.name)
      ) {
        $scope.$emit('notification', {type: 'danger', message: '文字内容包含英文的单引号，不允许提交!'});
        return;
      }
      if (!isMargin($scope.upgrade.attr.padding)) {
        $scope.$emit('notification', {type: 'danger', message: "图片边距格式错误,应为：0-9999px 0-9999px 0-9999px 0-9999px"});
        return;
      }
      
      if (!isMargin($scope.upgrade.title.attr.padding)) {
        $scope.$emit('notification', {type: 'danger', message: "文字边距格式错误,应为：0-9999px 0-9999px 0-9999px 0-9999px"});
        return;
      }
      if (!isColor($scope.upgrade.title.attr.color)) {
        $scope.$emit('notification', {type: 'danger', message: "文字颜色格式错误，应为：#000|#000000|#00000000"});
        return;
      }
      if (!isSize($scope.upgrade.title.attr.size)) {
        $scope.$emit('notification', {type: 'danger', message: "文字大小格式错误，应为：0-9999px"});
        return;
      }
      if(!$scope.upgrade.file){
        if(!$scope.upgrade.fileName){
          $scope.$emit('notification', {type: 'danger', message: '请上传图片'});
          return;
        }
      }else{
        let nickName = "upgradefile" + $scope.getTime() + "_" + getFileType($scope.upgrade.file.name);
        $scope.upgrade.fileName = nickName;
        $scope.upgrade.url = $scope.domain+"/"+nickName;
        $scope.upgrade.file.nickName = nickName;
      }
      

      var data = {
        projectCode: $scope.upgrade.projectCode,
        type: $scope.upgrade.type,
        md5: $scope.upgrade.md5,
        fileName: $scope.upgrade.fileName,
        url: $scope.upgrade.url,
        remark: $scope.upgrade.remark,
        attr:angular.toJson($scope.upgrade.attr),
        uuid_type:$scope.upgrade.uuid_type,
        uuid_start:$scope.upgrade.uuid_start,
        uuid_end:$scope.upgrade.uuid_end,
        uuid_list:$scope.upgrade.uuid_list,
        forbidden:$scope.upgrade.forbidden,
        validTime:$scope.upgrade.validTime,
        validTimeAttr:$scope.upgrade.validTimeAttr,
        repeatAttr:$scope.upgrade.repeatAttr,
        timeInDays:$scope.upgrade.timeInDays,
        title:{
          name:$scope.upgrade.title.name,
          attr:angular.toJson($scope.upgrade.title.attr),
        }
      };
      uploadFileService.cancelUpload($scope);
      uploadFileService.uploadFile($scope,$scope.upgrade.projectCode,[$scope.upgrade.file],data,finishUploadCallBack);
    };
    
    function finishUploadCallBack(data,uploadResData){
      console.log(data);
      console.log(uploadResData);
      if(uploadResData&&uploadResData.length==1&&uploadResData[0].md5){
        data.md5 = uploadResData[0].md5;
      }
      $http({
        method: 'POST',
        url: '/web/configAd/newUpgradeFileAdNotice',
        data: data
      }).then(function (response) {
        if (response.data.code === 0) {
            $scope.$emit('notification', {type: 'success', message: '成功，2秒后返回列表'});
            $timeout(function () {
              $state.go('main.configAd',{belongProject:$stateParams.belongProject})
            }, 2000)
        } else {
          $('#progress')[0].style.width = '0%';
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      })
    }
    //正则判断margin属性
    var isMargin = function (temp) {
      if (/^([0-9]{1,4}px [0-9]{1,4}px [0-9]{1,4}px [0-9]{1,4}px)$/.test(temp)) {
        return true;
      } else {
        return false;
      }
    }
    //正则判断size属性
    var isSize = function (temp) {
      if (/^([0-9]{1,4})px$/.test(temp)) {
        return true;
      } else {
        return false;
      }
    }
    var isColor = function (temp) {
      if (/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{8})$/.test(temp)) {
        return true;
      } else {
        return false;
      }
    }
    $scope.addTimeInDay = function(){
      $scope.upgrade.timeInDays.push({
        start:0,
        end:24
      });
    }
    $scope.delTimeInDay = function(index){
      $scope.upgrade.timeInDays.splice(index,1);
    }
    $scope.cancelUpload = function () {
      uploadFileService.cancelUpload($scope);
    }
    $scope.addUuid = function(){
      if(!$scope.upgrade.uuid_list){
        $scope.upgrade.uuid_list = [];
      }
      $scope.upgrade.uuid_list.push("");
    }
    $scope.delUuid = function(index){
      $scope.upgrade.uuid_list.splice(index,1);
    }

    $scope.backUrl = function(){
      $state.go('main.configAd',{belongProject:$stateParams.belongProject})
    }
    var isChinese = function(temp){
      if(/.*[\u4e00-\u9fa5]+.*$/.test(temp)) {
            return true;
          } 
          return false;
    }
    $scope.getTime = function(){
      return Date.now();
    }
    var getFileType = function(name){
      return name.substring(name.lastIndexOf("."));
    }
  }
]);