/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('serverInfoCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state','$interval',
  function ($scope, Upload, $http, $window, $timeout, $state,$interval) {
    function myFunction() {
      $http({
        method: 'GET',
        url: '/web/serverInfo/getServerInfo',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.serverInfo = response.data.data;
        }
      });
    }
   // 每30秒调用一次 myFunction 函数
   var intervalPromise;
    $scope.init = function (reload) {
      myFunction();
      loadTable();
      intervalPromise = $interval(function(){
        myFunction();
      }, 30000);
    };
    $scope.$on('$destroy',function(){
      console.log("destroy");
      $interval.cancel(intervalPromise)
    })

    var loadTable = function (reload) {
      if(reload){
        $('#table2').bootstrapTable('refresh');
        return;
      }
      $('#table2').bootstrapTable({
          method : 'get',
          url : "/web/serverInfo/getAll",//请求路径
          striped : true, //是否显示行间隔色
          pagination : true,//是否分页
          sidePagination : 'server',//server:服务器端分页|client：前端分页
          pageSize : 10,//单页记录数
          pageList : [ 5, 10, 20, 30 ],//可选择单页记录数
          showRefresh : true,//刷新按钮
          cache: false,
          search: true,
          showColumns: 'true',
          showExport: true,//显示导出按钮
          exportDataType: 'all',//导出类型
          queryParams : function(params) {//上传服务器的参数
            var temp = {//如果是在服务器端实现分页，limit、offset这两个参数是必须的
              limit : params.limit, // 每页显示数量
              //offset : params.offset, // SQL语句起始索引
              page : (params.offset / params.limit) + 1, //当前页码 
              searchName : $('.search input').val(),
              order : params.order, 
              sort : params.sort,
            };
            return temp;
          },
          columns: [{
            title: '内存RAM使用情况',
            align: 'center',
            formatter: function (value, row, index) {
              return "总内存:"+row.ramInfo.total
              +"<br/>可用内存:"+row.ramInfo.available
              +"<br/>已使用内存:"+row.ramInfo.used
              +"<br/>内存使用率:"+row.ramInfo.percent
              ;
            }
          }, {
            title: 'CPU使用情况',
            align: 'left',
            formatter: function (value, row, index) {
              return "CPU使用率:"+row.cpuInfo.cpuPercent
              ;
            }
          }, {
            title: '网速情况',
            align: 'left',
            formatter: function (value, row, index) {
              //console.log(row.netInfo);
              var result="";
              for (let index = 0; index < row.netInfo.length; index++) {
                result = result+row.netInfo[index].name+",上传速度:"+row.netInfo[index].upSpeed+",下载速度:"+row.netInfo[index].downSpeed+"<br/>"
                
              }
              return result;
              ;
            }
          }, {
            title: '存储ROM使用情况',
            align: 'left',
            formatter: function (value, row, index) {
              console.log(row.romInfo);
              if(!row.romInfo)return "";
              var result="";
              for (let index = 0; index < row.romInfo.length; index++) {
                result = result+ "存储位置:"+row.romInfo[index].name
                +"总存储:"+row.romInfo[index].total
              +"可用存储:"+row.romInfo[index].available
              +"已使用存储:"+row.romInfo[index].used
              +"存储使用率:"+row.romInfo[index].percent+"<br/>"
              ;
              }
              return result;
              ;
            }
          }, {
            title: '操作时间',
            align: 'center',
            sortable: true,
            field: 'updateTime',
            formatter: function (value, row, index) {
              return moment(value, 'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm')
            }
          }]
        });
    }
  }
])
;