'use strict';

angular.module('controllers').controller('upgradePlayerSettingsCtr', ['$scope', 'Upload', '$http', '$window', '$timeout', '$state','$location','$stateParams',
  function ($scope, Upload, $http, $window, $timeout, $state,$location,$stateParams) {

    $scope.pageNumber = sessionStorage.getItem("pageNumber");

    let playerSettings="";
  
    //初始化
    $scope.upgradeInit=function(){
      if (!$stateParams.belongProject) {
        $scope.$emit('notification', {type: 'danger', message: '非法请求页面'});
        $scope.errorRequest = true;
        return;
      }
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if(element.nameEn==$stateParams.belongProject){
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
        }
      });

      let data = {
        playerSettingsID:$stateParams.playerSettingsID
      }
      $http({
        method:'POST',
        url:'/web/playerSettings/findPlayerSettingsById',
        data:data
      }).then(function(response){
        if(response.data.code==0){
          playerSettings=response.data.data[0];
          $scope.playerSettings=playerSettings;
          if($scope.playerSettings.livePlayer==undefined){
            $scope.playerSettings.livePlayer = -1;
          }
          if($scope.playerSettings.frameCutting==undefined){
            $scope.playerSettings.frameCutting = -1;
          }
          if($scope.playerSettings.onDemandPlayer==undefined){
            $scope.playerSettings.onDemandPlayer = -1
          }
          if($scope.playerSettings.codec==undefined){
            $scope.playerSettings.codec= -1;
          }
          if($scope.playerSettings.playingMode==undefined){
            $scope.playerSettings.playingMode = -1;
          }
          if($scope.playerSettings.bufferTime==undefined){
            $scope.playerSettings.bufferTime = -1;
          }
          if($scope.playerSettings.dvbAuthorizeType==undefined){
            $scope.playerSettings.dvbAuthorizeType = -1;
          }
          if($scope.playerSettings.dvbAuthorizeTypeNew==undefined){
            $scope.playerSettings.dvbAuthorizeTypeNew = -1;
          }
          if($scope.playerSettings.paySwitch==undefined){
            $scope.playerSettings.paySwitch = -1;
          }
          if($scope.playerSettings.payType==undefined){
            $scope.playerSettings.payType = -1;
          }
          if($scope.playerSettings.playingYsSwitch==undefined){
            $scope.playerSettings.playingYsSwitch = -1;
          }
          if($scope.playerSettings.welcomePageOnlyOnceSwitch==undefined){
            $scope.playerSettings.welcomePageOnlyOnceSwitch = -1;
          }
          if($scope.playerSettings.homeBackWelcomeSwitch==undefined){
            $scope.playerSettings.homeBackWelcomeSwitch = -1;
          }
          if($scope.playerSettings.dvbTimeshiftSwitch==undefined){
            $scope.playerSettings.dvbTimeshiftSwitch= -1;
          }
          if($scope.playerSettings.dvbBackViewSwitch==undefined){
            $scope.playerSettings.dvbBackViewSwitch = -1;
          }
          if($scope.playerSettings.dvbInfoBarSwitch==undefined){
            $scope.playerSettings.dvbInfoBarSwitch = -1;
          }
          if($scope.playerSettings.videoInfoBarSwitch==undefined){
            $scope.playerSettings.videoInfoBarSwitch = -1;
          }
          if($scope.playerSettings.homePageTopMenuSwitch==undefined){
            $scope.playerSettings.homePageTopMenuSwitch = -1;
          }
          if($scope.playerSettings.saveChnlSwitch==undefined){
            $scope.playerSettings.saveChnlSwitch = -1;
          }
          if($scope.playerSettings.bootVolumeSwitch==undefined){
            $scope.playerSettings.bootVolumeSwitch = -1;
          }
          if($scope.playerSettings.dnsSwitch==undefined){
            $scope.playerSettings.dnsSwitch = -1;
          }
          if($scope.playerSettings.dnsList==undefined){
            $scope.playerSettings.dnsList = [];
          }
        }else{
          $scope.$emit('notification',{type:'danger',message:response.data.msg});
        }
      },function(err){
          $scope.$emit('notification',{type:'danger',message:'网络错误，初始化失败'});
      });
    };
    //提交设置
    $scope.submit=function(){
      let playerSettings={
        playerSettingsID:$stateParams.playerSettingsID,
        livePlayer:$scope.playerSettings.livePlayer,
        frameCutting:$scope.playerSettings.frameCutting,
        onDemandPlayer:$scope.playerSettings.onDemandPlayer,
        codec:$scope.playerSettings.codec,
        playingMode:$scope.playerSettings.playingMode,
        bufferTime:$scope.playerSettings.bufferTime,
        dvbAuthorizeType:$scope.playerSettings.dvbAuthorizeType,
        dvbAuthorizeTypeNew:$scope.playerSettings.dvbAuthorizeTypeNew,
        paySwitch:$scope.playerSettings.paySwitch,
        payType:$scope.playerSettings.payType,
        playingYsSwitch:$scope.playerSettings.playingYsSwitch,
        welcomePageOnlyOnceSwitch:$scope.playerSettings.welcomePageOnlyOnceSwitch,
        homeBackWelcomeSwitch:$scope.playerSettings.homeBackWelcomeSwitch,
        dvbTimeshiftSwitch:$scope.playerSettings.dvbTimeshiftSwitch,
        dvbBackViewSwitch:$scope.playerSettings.dvbBackViewSwitch,
        dvbInfoBarSwitch:$scope.playerSettings.dvbInfoBarSwitch,
        videoInfoBarSwitch:$scope.playerSettings.videoInfoBarSwitch,
        homePageTopMenuSwitch:$scope.playerSettings.homePageTopMenuSwitch,
        saveChnlSwitch:$scope.playerSettings.saveChnlSwitch,
        bootVolumeSwitch:$scope.playerSettings.bootVolumeSwitch,
        dnsSwitch:$scope.playerSettings.dnsSwitch,
        dnsList:$scope.playerSettings.dnsList,
      }
      $http({
        method:'POST',
        url:'/web/playerSettings/upgradePlayerSettings',
        data:playerSettings,
      }).then(function(response){
        if(response.data.code==0){
          $scope.$emit('notification',{type:'success',message:'设置成功，2秒后跳回项目信息管理'});
          $timeout(function(){
            $state.go("main.configProjectInfo",{belongProject:$stateParams.belongProject})
          },2000)
        }else{
          $scope.$emit('notification',{type:'danger',message:response.data.msg})
        }
      })
    }

    $scope.addDns = function(){
      $scope.playerSettings.dnsList.push({
        domain:"",
        ip:""
      });
    }
    $scope.delDns = function(index){
      $scope.playerSettings.dnsList.splice(index,1);
    }
  }
]);