/**
 * I'm the King of the World!
 */
angular.module('resource', [
  'ui.router',
  'ngFileUpload',
  'controllers',
  'services',
  'directives',
  'views'
])
  .constant('Global', {})
  .config(['$stateProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider',
    function ($stateProvider, $urlRouterProvider, $locationProvider, $httpProvider) {
      'use strict';

      // 修改默认请求头
      $httpProvider.defaults.headers.common = {'content-type': 'application/json;charset=utf-8'};

      // 拦截器，在请求头自动加上flag
      $httpProvider.interceptors.push('FlagInterceptor');

      // 开启 HTML5 模式
      $locationProvider.html5Mode(true);

      // 将所有未匹配路由转至根目录
      $urlRouterProvider.otherwise(function ($injector) {
        $injector.get('$state').go('login');
      });

      // 路由
      $stateProvider
      //登录
      .state('login', {
        url: '^/main/login',
        controller: 'loginCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/login.view.html');
        }]
      })

      //一级页面，头部与菜单栏部分
      .state('main', {
        url: '^/main',
        controller: 'mainCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/main.view.html');
        }],
      })

      .state('main.layoutGateway', {
        url: '^/main/layoutGateway/{projectNameEn}',
        controller: 'layoutGatewayCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/layoutGateway.view.html');
        }],
      })

      .state('main.layoutCaCard', {
        url: '^/main/layoutCaCard/{projectNameEn}',
        controller: 'layoutCaCardCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/layoutCaCard.view.html');
        }],
      })

      .state('main.layoutProject', {
        url: '^/main/layoutProject/{pageNumber}',
        controller: 'layoutProjectCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/layoutProject.view.html');
        }],
      })

      .state('main.projectContent', {
        url: '^/main/projectContent/{belongProject}',
        controller: 'projectContentCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/projectContent.view.html');
        }],
      })

      .state('main.hotelManage', {
        url: '^/main/hotelManage/{belongProject}',
        controller: 'hotelManageCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/hotelManage.view.html');
        }],
      })

      .state('main.hotelRoomManage', {
        url: '^/main/hotelRoomManage/{belongProject}',
        controller: 'hotelRoomManageCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/hotelRoomManage.view.html');
        }],
      })

      .state('main.hotelFloorManage', {
        url: '^/main/hotelFloorManage/{belongProject}',
        controller: 'hotelFloorManageCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/hotelFloorManage.view.html');
        }],
      })

      .state('main.hotelDeviceManage', {
        url: '^/main/hotelDeviceManage/{belongProject}',
        controller: 'hotelDeviceManageCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/hotelDeviceManage.view.html');
        }],
      })

      .state('main.hotelDeviceCtrlManage', {
        url: '^/main/hotelDeviceCtrlManage/{belongProject}/{roomId}',
        controller: 'hotelDeviceCtrlManageCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/hotelDeviceCtrlManage.view.html');
        }],
      })

      .state('main.addHotelRoom', {
        url: '^/main/addHotelRoom/{belongProject}',
        controller: 'addHotelRoomCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/addHotelRoom.view.html');
        }],
      })

      .state('main.layoutPage', {
        url: '^/main/layoutPage/{itemId}/{belongProject}',
        controller: 'layoutPageCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/layoutPage.view.html');
        }],
      })
      .state('main.layoutPageGroup', {
        url: '^/main/layoutPageGroup/{belongProject}',
        controller: 'layoutPageGroupCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/layoutPageGroup.view.html');
        }],
      })
      
      .state('main.upgradeLayoutPage', {
        url: '^/main/upgradeLayoutPage/{itemId}',
        controller: 'upgradeLayoutPageCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/upgradeLayoutPage.view.html');
        }],
      })

      .state('main.upgradeLayoutProject', {
        url: '^/main/upgradeLayoutProject',
        controller: 'upgradeLayoutProjectCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/upgradeLayoutProject.view.html');
        }],
      })

      .state('main.upgradeLayoutProjectMode', {
        url: '^/main/upgradeLayoutProjectMode',
        controller: 'upgradeLayoutProjectModeCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/upgradeLayoutProjectMode.view.html');
        }],
      })

      .state('main.upgradeLayoutProjectCardAttr', {
        url: '^/main/upgradeLayoutProjectCardAttr/:projectId/:belongProject',
        controller: 'upgradeLayoutProjectCardAttrCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/upgradeLayoutProjectCardAttr.view.html');
        }],
      })
      //审核流程
      .state('main.approvalProcess', {
        url: '^/main/approvalProcess',
        controller: 'approvalProcessCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/approvalProcess.view.html');
        }],
      })
      //审核流程详情
      .state('main.approvalProcessDetail', {
        url: '^/main/approvalProcessDetail/:dataId/:belongProject',
        controller: 'approvalProcessDetailCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/approvalProcessDetail.view.html');
        }],
      })
      //审核申请
      .state('main.upgradeApprovalProcess', {
        url: '^/main/upgradeApprovalProcess/:belongProject',
        controller: 'upgradeApprovalProcessCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/upgradeApprovalProcess.view.html');
        }],
      })

      //项目审核管理
      .state('main.configApproval', {
        url: '^/main/configApproval',
        controller: 'configApprovalCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/configApproval.view.html');
        }],
      })
      //项目审核配置
      .state('main.upgradeConfigApproval', {
        url: '^/main/upgradeConfigApproval/:dataId',
        controller: 'upgradeConfigApprovalCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/upgradeConfigApproval.view.html');
        }],
      })

      //项目信息管理
      .state('main.configProjectInfo', {
        url: '^/main/configProjectInfo/{belongProject}',
        controller: 'configProjectInfoCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/configProjectInfo.view.html');
        }],
      })

      //待处理审核
      .state('main.handleApproval', {
        url: '^/main/handleApproval',
        controller: 'handleApprovalCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/handleApproval.view.html');
        }],
      })
      //处理审核
      .state('main.upgradeHandleApproval', {
        url: '^/main/upgradeHandleApproval/:dataId',
        controller: 'upgradeHandleApprovalCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/upgradeHandleApproval.view.html');
        }],
      })

      //节目配置
      .state('main.upgradeAllProgram', {
        url: '^/main/upgradeAllProgram/:programId/:belongProject',
        controller: 'upgradeAllProgramCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/upgradeAllProgram.view.html');
        }],
      })
      //新建节目
      .state('main.upgradeVideo', {
        url: '^/main/upgradeVideo/:programId/:belongProject/:upgradeId',
        controller: 'upgradeVideoCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/upgradeVideo.view.html');
        }],
      })
      //节目分组
      .state('main.programGroup', {
        url: '^/main/programGroup/:programId/:belongProject',
        controller: 'programGroupCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/programGroup.view.html');
        }],
      })

      //播放器设置
      .state('main.upgradePlayerSettings', {
        url: '^/main/upgradePlayerSettings/:playerSettingsID/:belongProject',
        controller: 'upgradePlayerSettingsCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/upgradePlayer.view.html');
        }],
      })
      //广告管理
      .state('main.configAd', {
        url: '^/main/configAd/{belongProject}',
        controller: 'configAdCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/configAd.view.html');
        }],
      })
      //广告管理-时间设置
      .state('main.configAdTime', {
        url: '^/main/configAdTime/{configId}/{belongProject}',
        controller: 'configAdTimeCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/configAdTime.view.html');
        }],
      })
      //广告管理-页面广告-时间设置
      .state('main.configAdTimeCard', {
        url: '^/main/configAdTimeCard/{configId}/{belongProject}',
        controller: 'configAdTimeCardCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/configAdTimeCard.view.html');
        }],
      })

      //Wifi固件升级
      .state('main.configWifi', {
        url: '^/main/configWifi/{belongProject}',
        controller: 'configWifiCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/configWifi.view.html');
        }],
      })

      //Android整包升级
      .state('main.configAndroid', {
        url: '^/main/configAndroid/{belongProject}',
        controller: 'configAndroidCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/configAndroid.view.html');
        }],
      })

      //Android差分升级
      .state('main.configDiffpkg', {
        url: '^/main/configDiffpkg/{belongProject}',
        controller: 'configDiffpkgCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/configDiffpkg.view.html');
        }],
      })

      //插件管理
      .state('main.configPlug', {
        url: '^/main/configPlug/{belongProject}',
        controller: 'configPlugCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/configPlug.view.html');
        }],
      })

      //上传项目信息配置
      .state('main.upgradeConfigProjectInfo', {
        url: '^/main/upgradeConfigProjectInfo/:upgradeId/:belongProject',
        controller: 'upgradeConfigProjectInfoCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/upgradeConfigProjectInfo.view.html');
        }],
      })

      //上传广告配置
      .state('main.upgradeConfigAd', {
        url: '^/main/upgradeConfigAd/:upgradeId/:belongProject',
        controller: 'upgradeConfigAdCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/upgradeConfigAd.view.html');
        }],
      })
      //上传通知广告配置
      .state('main.upgradeConfigAdNotice', {
        url: '^/main/upgradeConfigAdNotice/:upgradeId/:belongProject',
        controller: 'upgradeConfigAdNoticeCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/upgradeConfigAdNotice.view.html');
        }],
      })
      //上传鉴权遮罩广告配置
      .state('main.upgradeConfigAdShadow', {
        url: '^/main/upgradeConfigAdShadow/:upgradeId/:belongProject',
        controller: 'upgradeConfigAdShadowCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/upgradeConfigAdShadow.view.html');
        }],
      })

      //上传广告配置-时间设置
      .state('main.upgradeConfigAdTime', {
        url: '^/main/upgradeConfigAdTime/:upgradeId/:belongConfigId/:belongProject',
        controller: 'upgradeConfigAdTimeCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/upgradeConfigAdTime.view.html');
        }],
      })
       //上传页面广告配置-时间设置
       .state('main.upgradeLayoutPageCardAdTime', {
        url: '^/main/upgradeLayoutPageCardAdTime/:upgradeId/:belongConfigId/:belongProject',
        controller: 'upgradeLayoutPageCardAdTimeCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/upgradeLayoutPageCardAdTime.view.html');
        }],
      })
      //上传Android整包
      .state('main.upgradeConfigAndroid', {
        url: '^/main/upgradeConfigAndroid/:upgradeId/:belongProject',
        controller: 'upgradeConfigAndroidCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/upgradeConfigAndroid.view.html');
        }],
      })
      //上传Wifi固件
      .state('main.upgradeConfigWifi', {
        url: '^/main/upgradeConfigWifi/:upgradeId/:belongProject',
        controller: 'upgradeConfigWifiCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/upgradeConfigWifi.view.html');
        }],
      })

      //插件上传
      .state('main.upgradeConfigPlug', {
        url: '^/main/upgradeConfigPlug/:upgradeId/:belongProject',
        controller: 'upgradeConfigPlugCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/upgradeConfigPlug.view.html');
        }],
      })
      
      //差分包上传
      .state('main.upgradeConfigDiffpkg', {
        url: '^/main/upgradeConfigDiffpkg/:upgradeId/:belongProject',
        controller: 'upgradeConfigDiffpkgCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/upgradeConfigDiffpkg.view.html');
        }],
      })

      //工程用户权限管理
      .state('main.engineerUser', {
        url: '^/main/engineerUser',
        controller: 'engineerUserCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/engineerUser.view.html');
        }],
      })

      //新增/更新工程用户权限
      .state('main.addEngineerUser', {
        url: '^/main/addEngineerUser/:userId',
        controller: 'addEngineerUserCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/addEngineerUser.view.html');
        }],
      })

      //用户权限管理
      .state('main.user', {
        url: '^/main/user',
        controller: 'userCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/user.view.html');
        }],
      })

      //新增/更新用户权限
      .state('main.addUser', {
        url: '^/main/addUser/:userId',
        controller: 'addUserCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/addUser.view.html');
        }],
      })

      //设置密码
      .state('main.setPwd', {
        url: '^/main/setPwd',
        params:{userId:null,userName:null,userPwd:null,layoutPageManage:null,userManage:null,wifiUpload:null,androidUpload:null,adUpload:null},
        controller: 'setPwdCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/setPwd.view.html');
        }],
      })

      //用户操作日志
      .state('main.operLog', {
        url: '^/main/operLog',
        controller: 'operLogCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/operLog.view.html');
        }],
      })

      //服务器运行情况
      .state('main.serverInfo', {
        url: '^/main/serverInfo',
        controller: 'serverInfoCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/serverInfo.view.html');
        }],
      })

       //统计中心
       .state('main.statistic', {
        url: '^/main/statistic',
        controller: 'statisticCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/statistic.view.html');
        }],
      })

      //文件下载统计
      .state('main.statisticFile', {
        url: '^/main/statisticFile',
        controller: 'statisticFileCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/statisticFile.view.html');
        }],
      })
      //文件下载统计详情
      .state('main.statisticFileDetail', {
        url: '^/main/statisticFileDetail/:itemId',
        controller: 'statisticFileDetailCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/statisticFileDetail.view.html');
        }],
      })

       //蓝莓网关信息统计
      .state('main.statisticGateway', {
        url: '^/main/statisticGateway',
        controller: 'statisticGatewayCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/statisticGateway.view.html');
        }],
      })

       //蓝莓网关信息统计
       .state('main.acquiredData', {
        url: '^/main/acquiredData',
        controller: 'acquiredDataCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/acquiredData.view.html');
        }],
      })

      //设置系统配置
      .state('main.setConfig', {
        url: '^/main/setConfig',
        controller: 'setConfigCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/setConfig.view.html');
        }],
      })

      .state('main.configWifiUuid', {
        url: '^/main/configWifiUuid/{belongProject}/{type}',
        controller: 'configWifiUuidCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/configWifiUuid.view.html');
        }],
      })

      .state('main.upgradeConfigWifiUuid', {
        url: '^/main/upgradeConfigWifiUuid/{itemId}/{belongProject}/{type}',
        controller: 'upgradeConfigWifiUuidCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/upgradeConfigWifiUuid.view.html');
        }],
      })
      //授权设备列表
      .state('main.deviceAuthList', {
        url: '^/main/deviceAuthList',
        controller: 'deviceAuthListCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/deviceAuthList.view.html');
        }],
      })
      //授权订单列表
      .state('main.orderNoticeList', {
        url: '^/main/orderNoticeList',
        controller: 'orderNoticeListCtr',
        templateProvider: ['$templateCache', function ($templateCache) {
          return $templateCache.get('/orderNoticeList.view.html');
        }],
      })
       
    }
  ])
;

//  .run(['checkSignIn', '$templateCache',function (checkSignIn) {
//    // 检查用户是否登录
//    checkSignIn();
//
//}]);

/**
 * 创建 Controllers, Services, Directives, Filters 模块
 */
angular.module('controllers', []);
angular.module('services', []);
angular.module('directives', []);
angular.module('views', []);