/**
 * Created by Administrator on 2017/7/11.
 */


'use strict';
angular.module('controllers').controller('hotelRoomManageCtr', ['$scope', 'Upload', '$http', '$window', '$timeout','$state','$stateParams',
  function ($scope, Upload, $http, $window, $timeout,$state,$stateParams) {
    $scope.pageNumber = sessionStorage.getItem("pageNumber");

    $scope.plug = {
      name: '',
      id: '',
    };

    var loadTable = function (data, reload) {
      if (reload) {
        $('#table2').bootstrapTable('load', data);
      } else {
        $('#table2').bootstrapTable({
          pagination: true,
          pageSize: 10, //每页显示10条
          cache: false,
          search: true,
          showColumns: 'true',
          showExport: true,//显示导出按钮
          exportDataType: 'all',//导出类型
          columns: [{
            title: '房间Id(家庭Id)',
            align: 'center',
            field: 'familyId',
            sortable: true
          },{
            title: '房间号',
            align: 'center',
            field: 'roomNum',
            sortable: true
          },{
            title: '所属楼层',
            align: 'center',
            field: 'floorName',
            sortable: true
          },{
            title: '房间描述',
            align: 'center',
            field: 'description',
            
          },{
            title: '创建时间',
            align: 'center',
            field: 'updateTime',
            sortable: true,
            formatter: function (value, row, index) {
              return moment(value, 'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm')
            }
          }, {
            title: '操作',
            align: 'center',
            width:'120px',
            formatter: function (value, row, index) {
              var jsonRow = angular.toJson(row);
              return '<button style="margin-top:5px" class=\'btn btn-success btn-xs\' onclick=\'angular.element(this).scope().changeModal(' + jsonRow + ')\'>更新修改</button>'
              +'<button style="margin-top:5px"  class=\'btn btn-danger btn-xs\' onclick=\'angular.element(this).scope().delPlugModal('+jsonRow+')\'>删除配置</button>';
            }
          }],
          data: data
        });
      }
    };

    $scope.plugInit = function (reload) {
      $http({
        method: 'GET',
        url: '/config/getLayoutConfig',
      }).then(function (response) {
        if (response.data.code === 0) {
          $scope.belongs = response.data.layoutProject;
          for (let index = 0; index < $scope.belongs.length; index++) {
            const element = $scope.belongs[index];
            if(element.nameEn==$stateParams.belongProject){
              $scope.curProjectNameEn = element.nameEn;
              $scope.curProjectName = element.nameCn;
              break;
            }
          }
        }
      });
      $http({
        method: 'GET',
        url: '/web/hotel/getHotelRoomList?belongProject='+$stateParams.belongProject,
      }).then(function (response) {
        if (response.data.code == 0) {
          loadTable(response.data.data, reload)
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
      $http({
        method: 'GET',
        url: '/web/hotel/getHotelFloorList?belongProject='+$stateParams.belongProject,
      }).then(function (response) {
        if (response.data.code == 0) {
          $scope.floor_list = response.data.data;
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

    $scope.updatePlug = function (data) {
      $state.go('main.upgradeConfigPlug', {upgradeId:data._id,belongProject:$stateParams.belongProject})
    };

    $scope.delPlugModal = function (data) {
      $scope.plug.name = "房间号（"+data.roomNum+"）";
      $scope.plug.id = data._id;
      $timeout(function () {
        $('#delModal').modal('show')
      }, 10);
    };

    $scope.changeObj ={}
    $scope.changeModal = function (data) {
      $scope.changeObj.roomNum = data.roomNum;
      $scope.changeObj.belongProject = data.belongProject;
      $scope.changeObj.name = "房间号（"+data.roomNum+"）";
      $scope.changeObj.floorName = data.floorName;
      $scope.changeObj.description = data.description;
      $timeout(function () {
        $('#changeModal').modal('show')
      }, 10);
    };

    $scope.doChange = function(){
      $http({
        method: 'POST',
        url: '/web/hotel/changeRoom',
        data: $scope.changeObj
      }).then(function (response) {
        $timeout(function () {
          $('#changeModal').modal('hide')
        }, 10);

        if (response.data.code == 0) {
          $scope.plugInit(true);
          $scope.$emit('notification', {type: 'success', message: '更新成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    }
    $scope.doDelPlug = function () {
      $http({
        method: 'DELETE',
        url: '/web/hotel/delRoom',
        params: {
          id: $scope.plug.id
        }
      }).then(function (response) {
        $timeout(function () {
          $('#delModal').modal('hide')
        }, 10);
        if (response.data.code == 0) {
          $scope.plugInit(true);
          $scope.$emit('notification', {type: 'success', message: '删除成功'});
        } else {
          $scope.$emit('notification', {type: 'danger', message: response.data.msg});
        }
      }, function (err) {
        $scope.$emit('notification', {type: 'danger', message: '网络错误，与服务器通信错误'});
      });
    };

  }
]);